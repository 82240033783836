<template>
  <a class="branding col-12" :href="brandingImgUrl" target="_blank" v-if="display">
    <img
      :class="{ 'branding-link': brandingImgUrl }"
      :src="imageUrl"
      :alt="publicationName"
      v-if="displayBrandingImg"
    />
    <h2 class="m-0 ml-2 font-weight-bold" v-if="displayPublicationName">{{ publicationName }}</h2>
  </a>
</template>

<script>
import config from 'config';
const { brandingImg, brandingImgUrl, headerContent, publicationName } = config;

export default {
  name: 'Branding',
  computed: {
    imageUrl: function () {
      if (brandingImg) {
        const img = require(`../../img/branding/${brandingImg}`);
        return img;
      }
      return null;
    },
    noIframe: function () {
      return window === window.top;
    },
    display: function () {
      return headerContent !== 'none' && this.noIframe;
    },
    displayBrandingImg() {
      return headerContent === 'brandingImg';
    },
    displayPublicationName() {
      return headerContent === 'publicationName';
    },
  },
  data() {
    return {
      brandingImgUrl,
      publicationName,
    };
  },
};
</script>
<style lang="less" scoped>
@import '~bootstrap-less-port/less/bootstrap-grid';
@grid-gutter-width: 0px;
h2 {
  color: @filter-bar-background;
}
#media-breakpoint-down(
  lg,
  {
    .branding {
      display: none;
    }
  }
);
</style>
