const SPLIT_CHAR = '↹';

const extractNodes = function (terms, term) {
  term.label = (term.highlight || term.key).split(SPLIT_CHAR).join(' / ');
  const [last, ...rest] = (term.highlight || term.key).split(SPLIT_CHAR).reverse();
  const highlightTag = new RegExp('<[^>]*>');

  if (term.children && term.children.length > 0) {
    if (highlightTag.test(last)) {
      terms.push(term);
    }
    term.children.reduce(extractNodes, terms);
  } else {
    if (highlightTag.test(last)) {
      terms.push(term);
    }
  }
  return terms;
};

export default function (terms) {
  return terms.reduce(extractNodes, []);
}
