export const initialState = {
  all: [],
  activeCart: null,
  loading: false,
  currentPage: 1,
  numPerPage: 5,
  pulseCartId: null,
  editCartId: null,
  selectedCartId: null,
  expandedCartId: null,
  createCartError: false,
  shareCart: null,
  isNewCartOpen: false,
  unselectAllStatus: false,
  exportSummary: null,
  importSummary: null,
};

export default Object.assign({}, initialState);
