import { RESET_PAGE, SET_TERM_ORDER, RESET_TERM_ORDER, SET_TERM_PAGE, RESET_TERM_PAGE } from '../mutation-types';

function initialState(filters) {
  return {
    page: 1,
    filters: filters,
    term_order: 'count:desc',
    term_page: 1,
    cart_id: null,
    favorites: false,
    ids: '',
    movement_id: null,
    silent: false,
  };
}

export default {
  [RESET_PAGE](state) {
    state.page = 1;
  },
  [SET_TERM_ORDER](state, termOrder) {
    state.term_order = termOrder;
  },
  [RESET_TERM_ORDER](state, { sortDir, sortBy }) {
    if (sortDir && sortBy) {
      state.term_order = `${sortBy}:${sortDir}`;
    } else {
      state.term_order = 'count:desc';
    }
  },
  [SET_TERM_PAGE](state, termPage) {
    state.term_page = termPage;
  },
  [RESET_TERM_PAGE](state) {
    state.term_page = 1;
  },
};
