<template>
  <b-modal
    id="exportXlsxModal"
    v-model="value"
    size="md"
    hide-footer
    hide-header
    :no-close-on-backdrop="true"
    no-stacking
    lazy
    body-class="p-0"
    modal-class="p-0 print-general-params"
  >
    <form @submit.prevent="submit" class="form-params">
      <div class="modal-header">
        <div class="row w-100">
          <div class="col-md-8">
            <h3 class="modal-title">{{ stepTitle }}</h3>
          </div>
          <div class="col-md-4">
            <div class="float-right">
              <button class="btn btn-round btn-round-white modal-close" @click="onClose" v-translate type="button">
                Annuler
              </button>
              <button
                v-if="isPreviousStep"
                class="btn btn-round btn-round-white modal-close"
                key="previous"
                @click="previousStep"
                v-translate
              >
                Précédent
              </button>
              <button
                v-if="isNextStep"
                class="btn btn-round btn-round-white modal-close"
                key="next"
                @click="nextStep"
                v-translate
              >
                Suivant
              </button>
              <button v-if="isLastStep" class="btn btn-round btn-round-white" type="submit" v-translate>Valider</button>
            </div>
          </div>
        </div>
      </div>

      <div class="alert alert-warning mb-0" role="alert" v-if="submitted && error">
        <translate>Vous ne pouvez pas exporter sans informations sélectionnées.</translate>
      </div>

      <perfect-scrollbar class="scroller scroll-always-visible" :options="scrollOptions" ref="ps">
        <div key="step-1" class="modal-body d-flex h-100" v-if="step === 1">
          <div class="form-group col-8" key="with_image">
            <div class="form-check form-check-inline pb-2">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="form.with_image"
                id="with_image"
                name="with_image"
                value="true"
              />
              <label class="form-check-label" for="with_image" v-translate>avec image (la 1ère)</label>
            </div>

            <div v-if="form.with_image">
              <div class="form-row pl-4" v-translate>Taille image</div>

              <div class="align-items-center pl-5 mb-2">
                <div class="form-check py-2">
                  <input
                    class="form-check-input"
                    name="image_size"
                    v-model="form.image_size"
                    type="radio"
                    value="m"
                    id="image_size_big"
                  />
                  <label class="form-check-label" for="image_size_big" v-translate>grande (400 x 400)</label>
                </div>
                <div class="form-check py-2">
                  <input
                    class="form-check-input"
                    name="image_size"
                    v-model="form.image_size"
                    type="radio"
                    value="s"
                    id="image_size_large"
                  />
                  <label class="form-check-label" for="image_size_large" v-translate>moyenne (200 x 200)</label>
                </div>
                <div class="form-check py-2">
                  <input
                    class="form-check-input"
                    name="image_size"
                    v-model="form.image_size"
                    type="radio"
                    value="xs"
                    id="image_size_small"
                  />
                  <label class="form-check-label" for="image_size_small" v-translate>petite (100 x 100)</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div key="step-2" class="modal-body d-flex h-100" v-if="step === 2">
          <div class="form-group">
            <div class="form-group">
              <button type="button" @click="selectAll" key="select-all" class="btn btn-sm mr-3" v-translate>
                tout sélectionner
              </button>
              <button type="button" @click="deselectAll" key="deselect-all" class="btn btn-sm" v-translate>
                tout désélectionner
              </button>
            </div>
            <div class="form-group">
              <div class="form-inline">
                <label for="search" class="pr-3">Search</label>
                <input type="text" v-model="search" name="search" class="form-control" id="search" />
              </div>
            </div>
            <div class="form-group d-flex flex-wrap">
              <div :key="group.key" class="form-check py-1 pl-4 w-100" v-for="group in filteredGroups">
                <input class="form-check-input" v-model="groups" type="checkbox" :id="group.key" :value="group" />
                <label class="form-check-label" :for="group.key">{{ group.label[lang] }}</label>
              </div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </form>
  </b-modal>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import toastr from 'mixins/toastrMixin.js';
import qs from 'qs';
import { autocompletion, vault, exportLimit } from 'config';
import FilterBuilder from 'lib/filterBuilder';
import { HTTP } from '../util';
import axios from 'axios';
const {
  popup: {
    identified_fields: { groups },
  },
} = autocompletion;
const availableGroups = Object.keys(groups)
  .map((key) => Object.assign({ key }, groups[key]))
  .filter((group) => group.pos);

const SIZES = {
  xs: 100,
  s: 200,
  m: 400,
};

export default {
  name: 'ExportXlsxModal',
  props: ['value', 'onClose'],
  mixins: [toastr],
  data() {
    return {
      step: 1,
      form: {},
      submitted: false,
      error: false,
      groups: [],
      search: '',
      titles: {
        1: this.$gettext(`Choix des paramètres pour les images`),
        2: this.$gettext(`Choix des informations à exporter`),
      },
      scrollOptions: {
        wheelSpeed: 1,
        wheelPropagation: false,
        minScrollbarLength: 20,
        suppressScrollX: true,
      },
    };
  },
  methods: {
    ...mapActions('auth', ['updatePreferences']),
    ...mapMutations('artworks', ['assign']),
    setFormParameters() {
      let formParams = Object.assign({}, this.defaultForm);
      let groupsParams = [];

      if (this.exportPreferences) {
        const { form, groups } = this.exportPreferences;
        formParams = form;

        const groupKeys = groups ? groups.split(',') : [];
        groupsParams = this.availableGroups.reduce((memo, item) => {
          if (groupKeys.includes(item.key)) memo.push(item);
          return memo;
        }, []);
      }

      this.form = { ...formParams };
      this.groups = groupsParams;
    },
    subscribe(id) {
      this.$root.fayeClient.subscribe('/' + id, (data) => {
        this.assign({ name: 'exportLink', data });
        this.$toastr('clear', '*');
        this.$root.$emit('bv::show::modal', 'downloadExportModal');
      });
    },
    selectAll() {
      this.groups = this.availableGroups;
    },
    deselectAll() {
      this.groups = [];
    },
    nextStep() {
      this.step += 1;
    },
    previousStep() {
      this.step -= 1;
    },
    async submit() {
      if (this.groups.length === 0) {
        this.submitted = true;
        this.error = true;
        return;
      }

      const params = {
        id: uuidv4(),
        groups: this.groups.map((g) => g.key).join(','),
        size: exportLimit || 1000,
        short: true,
      };

      const searchParams = this.artworkApi;
      const exportParams = Object.assign({}, searchParams, this.form, params);
      const url = `/artworks.xlsx?${qs.stringify(exportParams)}`;

      const preferences = {
        export: {
          form: this.form,
          groups: params.groups,
        },
      };

      this.$store.commit('local/UPDATE_PREFERENCES', {
        [vault]: preferences,
      });

      this.subscribe(params.id);
      const {
        data: { results },
      } = await HTTP.get(url);

      this.toastr('exportXlsx');

      if (this.isAuthenticated) {
        await this.updatePreferences(preferences);
      }
      this.onClose();
    },
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('local', ['preferences']),
    ...mapGetters('params', ['artworkApi']),
    defaultForm() {
      return {
        with_image: true,
        image_size: 'm',
      };
    },
    exportPreferences() {
      if (this.preferences) {
        return this.preferences.export;
      }
      return null;
    },
    availableGroups() {
      return availableGroups.reduce((memo, item) => {
        const notTextNoteFull = /text_note_/.test(item.key);
        const notInMosaic = !item.in_mosaic;

        if (notInMosaic || notTextNoteFull) {
          return memo;
        } else {
          memo.push(item);
        }
        return memo;
      }, []);
    },
    filteredGroups() {
      return this.availableGroups
        .filter((group) => {
          return group.label[this.lang].toLowerCase().includes(this.search.toLowerCase());
        })
        .sort((a, b) => a.pos - b.pos);
    },
    lang() {
      return this.$language.current;
    },
    stepTitle() {
      return this.titles[this.step];
    },
    isLastStep() {
      return this.step === Object.keys(this.titles).length;
    },
    isNextStep() {
      return this.step !== 2;
    },
    isPreviousStep() {
      return this.step !== 1;
    },
  },
  watch: {
    step: {
      handler(newVal, oldVal) {
        setTimeout(() => {
          this.$refs.ps.ps.update();
        }, 200);
      },
    },
    value: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.submitted = false;
          this.error = false;
          this.step = 1;
          this.setFormParameters();
          setTimeout(() => {
            this.$refs.ps.ps.update();
          }, 200);
        }
      },
    },
  },
};
</script>
