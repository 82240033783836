import * as mutations from '../mutation-types';

import Autocomplete from 'api/autocomplete';
import Artwork from 'api/artwork';
import { autocompletion } from 'config';

export default {
  async search({ commit, state, rootState, rootGetters }, options) {
    try {
      const PAGE = options.page || 1;
      const TERM_SIZE = options.size || autocompletion.popup.filters.number_of_values;
      const IDENTIFIED_FIELDS_SIZE = options.size | autocompletion.popup.identified_fields.number_of_values;

      let params = {
        term_word: options.search,
        term_order: 'count:desc',
        term_size: TERM_SIZE,
        term_from: PAGE * TERM_SIZE - TERM_SIZE,
        identified_fields_size: PAGE * IDENTIFIED_FIELDS_SIZE,
      };
      let stateParams = rootGetters['params/autocompleteApi'];

      params = Object.assign({}, stateParams, params);

      if (options.type === 'groups') {
        params.context_term = options.term;
        params.term_size = 1;
        params.term_from = 0;
      } else if (options.type === 'aggregations') {
        params.term = options.term;
        params.identified_fields_size = 0;
      }

      const data = await Autocomplete.search(params);
      let count;

      if (data.group_1.length === 0 && PAGE === 1 && params.term_word) {
        const { category_query, filters } = params;
        const check = await Artwork.fetch(
          Object.assign({ filters, category_query }, { q: `${params.term_word}*`, size: 0, term_size: 1 })
        );

        count = check.filteredCount;
      }

      commit(mutations.SET_FULLTEXT_CHECK, count);
      if (PAGE === 1) {
        commit(mutations.AUTOCOMPLETE_SEARCH, data);
      } else {
        if (options.type === 'groups') {
          commit(mutations.APPEND_AUTOCOMPLETE_GROUPS, { data: data.group_1, category: options.term });
        } else if (options.type === 'aggregations') {
          commit(mutations.APPEND_AUTOCOMPLETE_AGGREGATIONS, {
            data: data.aggregations[options.term],
            term: options.term,
          });
        }
      }
    } catch (e) {
      console.log(e);
      //console.error(e);
    }
  },
};
