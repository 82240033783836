<template>
  <div class="wrapper">
    <ExternalHeader v-if="externalMenu && externalMenu.url && externalMenu.position !== 'top'" />
    <div class="search-context" id="search-context">
      <router-view name="header"></router-view>
      <router-view v-if="!showEmpty" />
      <router-view name="empty" v-if="showEmpty && !loading"></router-view>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { facets, publicationName, shareBaseUrl, externalMenu } from 'config';
import seoMetadata from 'mixins/seoMetadata';
import ExternalHeader from './ExternalHeader.vue';

export default {
  name: 'SearchContext',
  mixins: [seoMetadata],
  components: {
    ExternalHeader,
  },
  async created() {
    const { query, params } = this.$route;
    await this.fetch(query, params);
  },
  methods: {
    ...mapActions('artworks', ['fetchArtworks', 'fetchArtworksTextNote']),
    async fetch(query = {}, params = {}) {
      await this.fetchArtworks(this.direction);
      this.$store.commit('infinite/RESET_DIRECTION');

      const { movement_id } = query;
      if (movement_id && !this.movement) {
        await this.$store.dispatch('artworks/readMovement', { id: movement_id });
      }

      if (this.textNoteFilters.length) {
        if (this.textNote) return;
        const { aggregation, term } = this.textNoteFilters[0];
        const name = `${aggregation}:${term[0]}`;
        await this.fetchArtworksTextNote({ name });
        this.$router.push({ params, query: { ...query, show_text_note: true } });
      } else {
        await this.$store.commit('artworks/assign', { name: 'textNote', data: null });
      }
    },
  },
  computed: {
    ...mapGetters('carts', ['activeCart']),
    ...mapGetters('artworks', ['textNote', 'movement', 'filteredCount', 'loading']),
    ...mapGetters('params', ['textNoteFilters', 'anyFilterApplied', 'silent', 'favorites']),
    ...mapGetters('infinite', ['direction']),
    showEmpty() {
      return this.filteredCount === 0 && this.anyFilterApplied;
    },
  },
  watch: {
    favorites(to, from) {
      if (to && from && to !== from && to.name !== 'Movements') {
        const { query, params } = this.$route;
        this.fetch(query, params);
      }
    },
    $route: {
      handler: async function (to, from) {
        const artworkToArtworks = to?.name === 'Artworks' && from?.name === 'artwork';

        if (to.name !== 'artwork' && !to.params.loaded) {
          const {
            query,
            params,
            meta: { endpoint = 'artworks' },
          } = to;
          const { show_text_note, note } = query;

          if (Object.keys(params).length === 0 && query.constructor === Object) {
            const checked = [];
            for (const [key, value] of Object.entries(facets[endpoint])) {
              if (value.checked) {
                checked.push(`checkbox:${key}:${value.term}`);
              }
            }
            if (checked.length) {
              params.filters = checked.join(',,');
            }
          }

          if (typeof show_text_note !== 'boolean' && this.silent != true && typeof note === 'undefined') {
            if (!artworkToArtworks) {
              await this.fetch(query, params);
            }
          }
        }
      },
      immediate: true,
    },
  },
  metaInfo() {
    return {
      ...this.metaConfig({
        title: publicationName,
        titleTemplate: this.metaTitleTemplate,
        description: this.metaHomeDescription,
      }),
      link: [
        {
          vmid: 'canonical',
          rel: 'canonical',
          href: `${shareBaseUrl}${this.$route.path.substring(1)}`,
        },
      ],
    };
  },
  data() {
    return {
      externalMenu: externalMenu || {},
    };
  },
};
</script>
<style lang="less">
.search-context {
  display: flex;
  flex-direction: column;
  max-height: inherit;
  flex-grow: 1;
  min-width: 0px;

  .header {
    flex: 0 1 auto;
    position: relative;
    min-width: 0px;
  }
}
.wrapper {
  display: flex;
  max-height: inherit;
  min-height: 100vh;
}
</style>
