export default {
  groups: (state) => state.groups,
  fullText: (state) => state.fullText,
  aggregations: (state) => state.aggregations,
  aggregationsItems: (state) =>
    Object.values(state.aggregations)
      .map((a) => a.items)
      .reduce((acc, val) => acc.concat(val), []),
  groupsItems: (state) =>
    Object.values(state.groups)
      .map((a) => a.items)
      .reduce((acc, val) => acc.concat(val), []),
  aggregationsSet: (state) => Object.values(state.aggregations),
  groupsSet: (state) => Object.values(state.groups),
  fullTextCheck: (state) => state.fullTextCheck,
  query: (state) => state.query,
};
