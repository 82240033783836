import * as mutations from '../mutation-types';

import Artwork from 'api/artwork';
import TextNote from 'api/textNote';

const mapOrder = function (array, order, key) {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  return array;
};

export default {
  async fetchArtworks({ commit, rootState, rootGetters }, direction = 0) {
    const { endpoint = 'artworks' } = rootState.route.meta;

    let params = rootGetters['params/artworkApi'];

    try {
      const { artworks, totalCount, filteredCount, aggregations } = await Artwork.fetch(params, endpoint);

      let data = {};
      if (artworks && typeof artworks === 'object' && artworks.length) {
        data = artworks.reduce((acc, artwork) => ({ ...acc, [artwork._id]: artwork }), {});
      }

      const artworkPages = { [rootGetters['params/page']]: data };

      if (direction === 0) {
        commit('assign', { name: 'artworkPages', data: artworkPages });
        commit('assign', { name: 'totalCount', data: totalCount });
        commit('assign', { name: 'filteredCount', data: filteredCount });
        commit(`aggregations/${mutations.AGGREGATION_COLLECTION}`, { aggregations, endpoint }, { root: true });
      } else {
        commit('append', { name: 'artworkPages', data: artworkPages });
      }
      commit('loaded');
    } catch (e) {
      //console.error(e);
    }
  },

  async fetchArtworksTextNote({ commit }, { name }) {
    try {
      const data = await TextNote.filter({ name });
      commit('assign', { name: 'textNote', data });
    } catch (e) {
      //console.error(e);
    }
  },

  async read({ commit }, { id }) {
    const parsedId = id.split('-').reverse()[0];

    try {
      const data = await Artwork.read({ id: parsedId });
      commit('assign', { name: 'artwork', data });
      commit('loaded');
    } catch (e) {
      //console.error(e);
    }
  },

  async readMovement({ commit }, { id }) {
    try {
      const data = await Artwork.read({ id }, 'movements');
      commit('assign', { name: 'movement', data });
    } catch (e) {
      //console.error(e);
    }
  },

  async fetchTextNotes({ state, commit }, { id }) {
    try {
      const data = await Artwork.read({ id }, 'movements');
      commit('updateTextNotes', { record: data, id: data._id });
    } catch (e) {
      //console.error(e);
    }
  },

  async fetchRelated({ commit }, params) {
    try {
      let related = await Artwork.fetchRelated(params);

      const elementsByRelated = mapOrder(related, params.ids, '_id');

      commit('updateElements', { elements: related });
    } catch (e) {
      //console.error(e);
    }
  },

  async buildElements({ state, commit }) {
    if (!state.artwork) return;
    const { type, elements, ensemble_id, _id } = state.artwork;
    if (type === 'separable') {
      let separableElements = state.artwork.related.map((relatedId) => {
        const element = state.elements.find((element) => element._id === relatedId);
        if (relatedId === _id) {
          return state.artwork;
        } else if (element) {
          return element;
        } else {
          return { _id: relatedId, type, ensemble_id };
        }
      });
      const elementsByRelated = mapOrder(separableElements, state.artwork.related, '_id');
      commit('assign', { name: 'elements', data: elementsByRelated });
    } else if (type === 'nonseparable') {
      const elementsByRelated = mapOrder(elements, state.artwork.related, '_id');
      commit('assign', { name: 'elements', data: elementsByRelated });
    } else {
      commit('assign', { name: 'elements', data: [] });
    }
  },

  addAccordion({ state, commit }, title) {
    commit('assign', { name: 'accordions', data: [...state.accordions, title] });
  },

  removeAccordion({ state, commit }, title) {
    commit('assign', { name: 'accordions', data: [...state.accordions.filter((a) => a != title)] });
  },
};
