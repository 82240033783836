<template>
  <ul class="pager display-all">
    <li :class="previousClassObject">
      <a :class="{ disabled: firstPage }" @click.prevent="previousPage" v-translate>précédent</a>
    </li>
    <li :class="nextClassObject">
      <a :class="{ disabled: lastPage }" @click.prevent="nextPage" v-translate>suivant</a>
    </li>
  </ul>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'MenuBarFavoritesPagniation',
  computed: {
    ...mapGetters('carts', ['all']),
    ...mapState('carts', ['currentPage', 'numPerPage']),
    pagesLength() {
      return Math.ceil(this.all.length / this.numPerPage, 0);
    },
    firstPage() {
      return this.currentPage == 1;
    },
    lastPage() {
      return this.currentPage == this.pagesLength;
    },
    previousClassObject() {
      return {
        previous: true,
        disabled: this.firstPage,
      };
    },
    nextClassObject() {
      return {
        next: true,
        disabled: this.lastPage,
      };
    },
  },
  methods: {
    ...mapMutations('carts', ['assign']),
    nextPage() {
      if (this.lastPage) return;

      this.assign({
        name: 'currentPage',
        data: this.currentPage + 1,
      });
    },
    previousPage() {
      if (this.firstPage) return;

      this.assign({
        name: 'currentPage',
        data: this.currentPage - 1,
      });
    },
  },
};
</script>
