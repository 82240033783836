import { LOAD, RESET_DIRECTION, INIT } from '../mutation-types';

export default {
  [LOAD](state, data) {
    state.direction = data.direction;
    state.start = data.start;
    state.end = data.end;
  },
  [INIT](state) {
    state.direction = 0;
    state.start = null;
    state.end = null;
  },
  [RESET_DIRECTION](state) {
    state.direction = 0;
  },
};
