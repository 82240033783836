<template>
  <li>
    <div class="term-wrapper">
      <slot name="operator"></slot>
      <div>
        <slot></slot>
      </div>
      <div class="remove-all-wrapper">
        <slot name="remove-all"></slot>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'AppliedFiltersItem',
  data() {
    return {};
  },
};
</script>
