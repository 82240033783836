export default {
  aggregations: (state) => state.aggregations,
  aggregation: (state) => state.aggregation,
  loading: (state) => state.loading,
  numericalValues: (state) =>
    state.aggregations[state.aggregation].numerical_values.reduce((acc, group) => {
      acc[group.key] = {
        min: group.min,
        max: group.max,
      };
      return acc;
    }, {}),
  checkboxes: (state) =>
    Object.entries(state.aggregations)
      .filter((o) => o[1].checkbox)
      .map((o) => {
        const [key, agg] = o;
        const term = agg.terms.filter((t) => t.key === agg.term)[0];
        if (agg.terms.length && term) {
          agg.doc_count = term.doc_count;
        } else if (agg.terms.length) {
          agg.doc_count = 0;
        } else {
          agg.doc_count = agg.total;
        }
        agg.key = key;
        return agg;
      }),
  topics: (state) =>
    Object.entries(state.aggregations)
      .filter((o) => o[1].pos !== null)
      .map((o) => {
        const [key, agg] = o;
        agg.key = key;
        return agg;
      }),
  terms: (state) => state.terms,
};
