export const smdeInitialState = {
  content: null,
  cursorStart: null,
  cursorEnd: null,
  link: null,
  selectedText: '',
};

export default Object.assign(
  {
    aggregations: [],
    totalCount: 0,
    filteredCount: 0,
    artworkId: null,
    artwork: {},
    loading: true,
    movement: null,
    textNote: null,
    elements: [],
    accordions: [],
    images: [],
    currentImage: 0,
    media: {},
    printLink: null,
    exportLink: null,
    printParmas: {},
    shareArtwork: null,
    artworkPages: {},
    isFolded: true,
  },
  { smde: smdeInitialState }
);
