export default {
  resource: (state) => state.resource,
  list: (state) => state.list,
  form: (state) => state.form,
  previewByElementId: (state) => (elementId) => state.previews.filter((preview) => preview.elementId === elementId)[0],
  validParameters: (state) => {
    const {
      type,
      references: [{ id }],
    } = state.form.note_properties;
    return type === 'cart' || id !== '';
  },
};
