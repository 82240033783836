<template>
  <div :class="classObject" v-if="translateEnable">
    <a href @click.prevent="toggleDropdown">
      {{ $language.current }}
      <i class="icon-tri_down"></i>
    </a>
    <ul class="dropdown-menu language-menu">
      <li v-for="(language, index) in $language.available" :key="index">
        <a href @click.prevent="changeLanguage(language)">{{ language }}</a>
      </li>
    </ul>
  </div>
</template>
<script>
import { translateEnable } from 'config';
export default {
  name: 'MenuBarLanguage',
  computed: {
    classObject() {
      return {
        language: true,
        dropdown: true,
        open: this.dropdownOpen,
      };
    },
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    changeLanguage(language) {
      this.$language.current = language;
      this.toggleDropdown();
    },
  },
  data() {
    return {
      translateEnable,
      dropdownOpen: false,
    };
  },
};
</script>
