<template>
  <div class="header" :class="{ 'header-dev': environment == 'development', 'header-stg': environment == 'staging' }">
    <ExternalHeader v-if="externalMenu && externalMenu.url && externalMenu.position === 'top'" />
    <Branding v-if="isBranding" />
    <div class="col-12 order-0 d-none mobile-header align-items-center justify-content-between">
      <h2 class="m-0 ml-2 font-weight-bold" v-if="!externalMenu">{{ publicationName }}</h2>
      <div>
        <a href @click.prevent.stop="toggleHeader()" class="align-self-start"><i class="fa fa-lg fa-bars" /></a>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { publicationName, type, externalMenu } from 'config';
import Branding from './Branding.vue';
import ExternalHeader from './ExternalHeader.vue';

export default {
  name: 'HeaderWrapper',
  components: {
    ExternalHeader,
    Branding,
  },
  computed: {
    isBranding: function () {
      return (
        !externalMenu ||
        (externalMenu && !externalMenu.url.length && !externalMenu.url.position) ||
        (externalMenu && externalMenu.url.length && externalMenu.position !== 'top')
      );
    },
  },
  props: {
    toggleHeader: {
      type: Function,
      default: () => {},
    },
    headerOpened: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      externalMenu: externalMenu || {},
      environment: type,
      publicationName,
    };
  },
};
</script>

<style scoped lang="less">
.mobile-header {
  a {
    line-height: 44px;
    height: 44px;
    width: 44px;
    display: block;
    text-align: center;
    color: @default-text-color;
  }
  h2 {
    color: @filter-bar-background;
  }
}
</style>

<style lang="less">
@import '~bootstrap-less-port/less/bootstrap-grid';
@grid-gutter-width: 0px;

.header-dev {
  background-image: url(../../img/header/DEV.png);
  background-repeat: repeat-x;
}

.header-stg {
  background-image: url(../../img/header/STG.png);
  background-repeat: repeat-x;
}

#media-breakpoint-down(
  lg,
  {
    .mobile-header.d-none {
      display: flex !important;
    }
  }
);
</style>
