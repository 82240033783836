<template>
  <div>
    <AppliedFiltersSummary v-if="!editing && anyFilterApplied" @click.native="enableEditMode" />
    <ul class="current-filters" v-if="Object.keys(aggregations).length && anyFilterApplied && editing">
      <AppliedFiltersItem v-if="favorites" class="single">
        <span class="term">
          {{ titles.selection }}
          <strong>{{ displayedCart.name }}</strong>
        </span>
        <template v-slot:remove-all>
          <a @click.stop.prevent="removeFavorites()" class="remove-all-filter" v-b-tooltip.right :title="titles.remove">
            <i class="fa fa-times-circle"></i>
          </a>
        </template>
      </AppliedFiltersItem>
      <AppliedFiltersItem v-if="ids" class="single">
        <span class="term">
          <span v-translate>"liste d'oeuvres"=</span>
        </span>
        <template v-slot:remove-all>
          <a @click.stop.prevent="removeIds()" class="remove-all-filter" v-b-tooltip.right :title="titles.remove">
            <i class="fa fa-times-circle"></i>
          </a>
        </template>
      </AppliedFiltersItem>
      <AppliedFiltersItem v-if="movement" class="single">
        <span class="term">
          {{ movement.label }}
        </span>
        <template v-slot:remove-all>
          <a @click.stop.prevent="removeMovement()" class="remove-all-filter" v-b-tooltip.right :title="titles.remove">
            <i class="fa fa-times-circle"></i>
          </a>
        </template>
      </AppliedFiltersItem>

      <AppliedFiltersItem
        :key="filterIndex"
        v-for="(filter, filterIndex) in appliedFilters"
        :class="{ single: filter.term.length == 1 }"
      >
        <template v-slot:operator>
          <FilterToggleOperator :filter="filter" :index="filterIndex" />
        </template>

        <span v-if="filter.type === 'category_query'">
          "<translate>Mots dans la rubrique</translate> {{ filter.label[lang] }}"=
        </span>
        <span v-else-if="filter.type === 'query'"> "<translate>mot</translate>"= </span>
        <span v-else>"{{ filter.label[lang] }}"=</span>
        <span
          class="term"
          :key="term"
          @click.prevent.stop="editTerm(filter, term, index)"
          v-for="(term, index) in filter.term"
        >
          <strong :class="{ 'line-through': hoverFilter === term || hoverFilter === filter || hoverRemoveAll }">
            {{ termLabel(filter, term) }}
          </strong>
          <RemoveFilterButton
            v-if="filter.term.length > 1"
            class="remove-filter"
            icon="fa fa-times-circle"
            :tooltip="titles.remove"
            :index="index"
            :filter="filter"
            @mouseover.native="hoverFilter = term"
            @mouseleave.native="hoverFilter = null"
          />
          <strong class="operator-or" v-if="index !== filter.term.length - 1">+</strong>
        </span>
        <template v-slot:remove-all>
          <RemoveFilterButton
            v-if="filter.term.length === 1 || filter.term.length > 1"
            class="remove-all-filter"
            :tooltip="titles.remove"
            icon="fa fa-times-circle"
            :filter="filter"
            :index="filterIndex"
            :removeAll="true"
            @mouseover.native="hoverFilter = filter"
            @mouseleave.native="hoverFilter = null"
          />
        </template>
      </AppliedFiltersItem>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RemoveFilterButton from './RemoveFilterButton.vue';
import FilterToggleOperator from './FilterToggleOperator.vue';
import AppliedFiltersSummary from './AppliedFiltersSummary.vue';
import AppliedFiltersItem from './AppliedFiltersItem.vue';
import keyToLabel from 'lib/keyToLabel';
import FilterBuilder from 'lib/filterBuilder';

export default {
  name: 'applied-filters',
  components: {
    RemoveFilterButton,
    FilterToggleOperator,
    AppliedFiltersSummary,
    AppliedFiltersItem,
  },
  props: ['editing', 'hoverRemoveAll'],
  computed: {
    ...mapGetters('artworks', ['movement']),
    ...mapGetters('aggregations', ['aggregations']),
    ...mapGetters('params', ['appliedFilters', 'anyFilterApplied']),
    ...mapGetters('params', ['favorites', 'ids', 'locationFilters']),
    ...mapGetters('carts', ['displayedCart']),
    filtersTotal() {
      return Object.keys(this.appliedFilters).length;
    },
    lang() {
      return this.$language.current;
    },
    titles() {
      return {
        remove: this.$gettext('Effacer'),
        selection: this.$gettext('"Dossier de sélection"='),
      };
    },
  },
  methods: {
    termLabel(filter, term) {
      let prefix = '';
      let termLabel = keyToLabel(term);
      if (filter.type === 'before') {
        prefix = this.$gettext('avant');
        return `${prefix} ${termLabel}`;
      } else if (filter.type === 'after') {
        prefix = this.$gettext('après');
        return `${prefix} ${termLabel}`;
      } else if (filter.type === 'range') {
        prefix = this.$gettext('entre');
        return `${prefix} ${termLabel[0]} et ${termLabel[1]}`;
      } else {
        return termLabel;
      }
    },
    removeIds() {
      const query = this.$route.query;

      this.$router.push({
        query: { ...query, ids: undefined },
      });
    },
    removeMovement() {
      const query = this.$route.query;

      this.$router.push({
        query: { ...query, movement_id: null },
      });

      this.$store.commit('artworks/assign', { name: 'movement', data: null });
    },
    removeFavorites() {
      const query = this.$route.query;

      this.$router.push({
        query: { ...query, favorites: null },
      });
    },
    editTerm(filter, term, index) {
      const label = keyToLabel(term);

      const toRemove = this.locationFilters.find((f) => f.aggregation === filter.aggregation);

      if (typeof index === 'number') {
        toRemove.term.splice(index, 1);
      } else {
        const filterIndex = this.locationFilters.indexOf(toRemove);
        this.locationFilters.splice(filterIndex, 1);
      }

      const query = this.$route.query;

      this.$router.push({
        query: { ...query, page: 1 },
        params: { filters: FilterBuilder.toURLParam(this.locationFilters) },
      });

      this.$store.commit('autocomplete/UPDATE_QUERY', label);
    },
    opratorText(filter) {
      return filter.operator === 'and' ? this.$gettext('et') : this.$gettext('sauf');
    },
    enableEditMode() {
      this.$emit('toggleEditMode');
    },
  },
  data() {
    return {
      hoverFilter: null,
    };
  },
};
</script>
<style lang="less" scoped>
.term {
  .line-through {
    text-decoration: line-through;
  }
}
</style>
