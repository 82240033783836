import { HTTP, NO_CACHE_HEADER } from '../util';
import appUrl from 'lib/appUrl.js';

export default {
  async login(credentials) {
    const { data } = await HTTP.post('login', credentials);
    return data;
  },
  async register(credentials) {
    const { data } = await HTTP.post(`register`, { ...credentials, callback_url: appUrl });
    return data;
  },
  async me() {
    const { data } = await HTTP.get('/me', NO_CACHE_HEADER);
    return data;
  },
  async setActiveCart(payload) {
    const { data } = await HTTP.put('/me/active_cart', payload, NO_CACHE_HEADER);
    return data;
  },
  async update(params) {
    const { data } = await HTTP.put('/me', params, NO_CACHE_HEADER);
    return data;
  },
  async requestAccess(message) {
    const { data } = await HTTP.post('/request_access', { message }, NO_CACHE_HEADER);
    return data;
  },
};
