<template>
  <img v-lazy="src" :alt="alt" />
</template>

<script>
export default {
  name: 'artwork-image',
  props: ['image', 'version'],
  computed: {
    alt() {
      if (!this.image) {
        return '';
      }
      return `${this.image.photographer} ${this.image.copyright}`;
    },
    src() {
      if (!this.image.url_template) {
        return;
      }

      return this.image.url_template.supplant({
        size: this.version,
        file_name: this.image.file_name,
      });
    },
  },
};
</script>
