import { sessionStorageIsSupported, localStorageIsSupported } from '../../util/storage';

export default {
  token:
    sessionStorageIsSupported() && localStorageIsSupported()
      ? sessionStorage.getItem('token') || localStorage.getItem('token')
      : null,
  isAuthenticated: false,
  currentUser: {},
  errors: [],
  loading: false,
};
