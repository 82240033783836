<template>
  <div class="filters-summary">
    <span class="corner corner-top-left"></span>
    <TooltipEllipses :value="summaryFilters" ref="summaryText" />
    <span class="corner corner-bottom-right"></span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TooltipEllipses from './TooltipEllipses.vue';
import keyToLabel from 'lib/keyToLabel';
import { publicationName } from 'config';
import seoMetadata from 'mixins/seoMetadata';

export default {
  name: 'AppliedFiltersSummary',
  mixins: [seoMetadata],
  components: {
    TooltipEllipses,
  },
  updated() {
    if (this.$refs.summaryText) {
      this.$refs.summaryText.$forceUpdate();
    }
  },
  computed: {
    ...mapGetters('params', ['appliedFilters']),
    ...mapGetters('params', ['favorites', 'ids']),
    ...mapGetters('carts', ['displayedCart']),
    ...mapGetters('artworks', ['movement', 'filteredCount']),
    ...mapGetters('aggregations', ['aggregations']),
    lang() {
      return this.$language.current;
    },
    summaryFilters() {
      let summary = [];
      if (this.favorites) {
        summary.push(` / ${this.displayedCart.name}`);
      }

      if (this.ids) {
        summary.push(` / liste d'oeuvres`);
      }

      if (this.movement) {
        summary.push(` / ${this.movement.label}`);
      }

      const appliedFiltersSummary = this.appliedFilters
        .map((f) => {
          const terms = f.term
            .map((t) => {
              return this.termLabel(f, t);
            })
            .join(' + ');
          const operator = f.operator === 'and' ? ' / ' : ' #/ ';
          const rangePrefix = f.type === 'range' || f.years_combined ? `${f.label[this.lang]} = ` : '';
          return `${operator}${rangePrefix}${terms}`;
        })
        .join('');

      summary.push(appliedFiltersSummary);

      return summary.join('');
    },
  },
  methods: {
    termLabel(filter, term) {
      let prefix = '';
      let termLabel = keyToLabel(term);
      if (filter.type === 'before') {
        prefix = this.$gettext('avant');
        return `${prefix} ${termLabel}`;
      } else if (filter.type === 'after') {
        prefix = this.$gettext('après');
        return `${prefix} ${termLabel}`;
      } else if (filter.type === 'range') {
        prefix = this.$gettext('entre');
        return `${prefix} ${termLabel[0]} et ${termLabel[1]}`;
      } else {
        return termLabel;
      }
    },
  },
  metaInfo() {
    return {
      ...this.metaConfig({
        title: this.summaryFilters,
        titleTemplate: this.metaTitleTemplate,
        description: `${this.filteredCount} œuvres correspondant à la recherche "${this.summaryFilters}" dans la collection ${publicationName}`,
      }),
    };
  },
};
</script>
<style lang="less">
.filters-summary {
  padding: 4px 10px 3px 10px;
  position: relative;
  background-color: white;
  div {
    .BlackFont(20px);
  }

  .corner {
    width: 20px;
    height: 20px;
    position: absolute;
    border: none;
  }

  .corner-top-left {
    top: 0;
    left: 0;
    border-left-width: 3px;
    border-left-color: @golden-sand;
    border-left-style: solid;
    border-top-width: 3px;
    border-top-color: @golden-sand;
    border-top-style: solid;
  }

  .corner-bottom-right {
    bottom: 0;
    right: 0;
    border-right-width: 3px;
    border-right-color: @alto;
    border-right-style: solid;
  }
}
</style>
