import * as mutations from '../mutation-types';
import TextNote from 'api/textNote';

export default {
  async fetch({ commit }) {
    const textNotes = await TextNote.query();
    commit(mutations.TEXT_NOTES_SET_LIST, textNotes);
  },

  async read({ commit, rootState }, id) {
    const parsedId = id.split('-').reverse()[0];

    const highlight = rootState.params.filters
      .filter((f) => f.type === 'query' || (f.type === 'category_query' && /text_note/.test(f.aggregation)))
      .map((f) => f.term.join('||'))
      .join('||');

    const textNote = await TextNote.get(parsedId, highlight);
    commit('assign', { name: 'textNote', data: textNote });
  },

  setNewTextNote({ commit, rootState }, { resource, type, artworkEl }) {
    commit(mutations.TEXT_NOTES_SET_NEW, { resource, type, artworkEl, user: rootState.auth.currentUser });
  },

  setEditTextNote({ commit }, { textNote, resource }) {
    commit(mutations.TEXT_NOTES_SET_EDIT, { textNote, resource });
  },

  setProperties({ commit }, properties) {
    commit(mutations.TEXT_NOTES_SET_PROPERTIES, properties);
  },

  setContent({ commit }, content) {
    commit(mutations.TEXT_NOTES_SET_CONTENT, content);
  },

  async save({ commit }, { form, artwork }) {
    let textNote;
    const parsed = parseInt(form.id);
    if (typeof parsed === 'number' && !isNaN(parsed)) {
      textNote = await TextNote.update({ note: form, artwork_id: artwork.id });
      commit('assign', { name: 'textNote', data: textNote.note });
      if (textNote.type === 'cart') {
        commit(`carts/${mutations.CARTS_UPDATE_TEXT_NOTE}`, { textNote: textNote.note }, { root: true });
      } else {
        commit('artworks/updateExtract', { id: textNote.note.id, note: textNote.shortcut }, { root: true });
      }
    } else {
      textNote = await TextNote.save({ note: form, artwork_id: artwork.id });
      commit('assign', { name: 'textNote', data: textNote.note });
      commit(
        'artworks/createExtract',
        {
          type: textNote.type,
          note: textNote.shortcut || textNote.note,
          isElement: textNote.is_element,
          references: textNote.note.note_properties.references,
        },
        { root: true }
      );
      if (textNote.type === 'cart') {
        commit(`carts/${mutations.CARTS_UPDATE_TEXT_NOTE}`, { textNote: textNote.note }, { root: true });
      }
    }
    commit(mutations.TEXT_NOTES_UPDATE_ELEMENT, textNote.note);
  },

  async publish({ commit }, note) {
    const textNote = await TextNote.publish(note);
    commit(mutations.TEXT_NOTES_UPDATE_STATUS, textNote);
    commit('assign', { name: 'textNote', data: textNote });
    commit('artworks/updateExtract', { id: textNote.id, note: { status: textNote.status } }, { root: true });
  },

  async cancelPublish({ commit }, note) {
    const textNote = await TextNote.cancelPublish(note);
    commit(mutations.TEXT_NOTES_UPDATE_STATUS, textNote);
    commit('assign', { name: 'textNote', data: textNote });
    commit('artworks/updateExtract', { id: textNote.id, note: { status: textNote.status } }, { root: true });
  },

  async cancelUnpublish({ commit }, note) {
    const textNote = await TextNote.cancelUnpublish(note);
    commit(mutations.TEXT_NOTES_UPDATE_STATUS, textNote);
    commit('assign', { name: 'textNote', data: textNote });
    commit('artworks/updateExtract', { id: textNote.id, note: { status: textNote.status } }, { root: true });
  },

  async unpublish({ commit }, note) {
    const textNote = await TextNote.unpublish(note);
    commit(mutations.TEXT_NOTES_UPDATE_STATUS, textNote);
    commit('assign', { name: 'textNote', data: textNote });
    commit('artworks/updateExtract', { id: textNote.id, note: { status: textNote.status } }, { root: true });
  },

  async delete({ commit }, { note, artworkId }) {
    const textNote = await TextNote.delete({ id: note.id, artworkId });
    const refId = textNote.note.note_properties.references[0].id;
    commit('assign', { name: 'textNote', data: null });
    commit(
      'artworks/removeExtract',
      { shortcut: textNote.shortcut, textNoteId: textNote.note.id, artworkId: refId },
      { root: true }
    );
  },
};
