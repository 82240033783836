<template>
  <form class="form-horizontal form-params" name="noteParams" @submit.prevent="submit" novalidate>
    <div class="modal-header">
      <div class="row w-100">
        <div class="col-md-8">
          <h3 class="modal-title" v-translate>Paramètres de la notice rédactionnelle</h3>
          <div v-show="publicationStatus">{{ publicationStatus }}</div>
        </div>
        <div class="col-md-4 d-flex justify-content-end">
          <a href class="btn btn-round btn-round-white modal-close" @click.stop.prevent="close()" v-translate
            >Annuler</a
          >
          <button class="btn btn-round btn-round-white" type="submit" v-translate>Valider</button>
        </div>
      </div>
    </div>

    <perfect-scrollbar class="scroller scroll-always-visible" :options="scrollOptions" ref="ps">
      <div class="modal-body">
        <div class="alert alert-warning" role="alert" v-show="submitted && errors.length">
          <span v-translate>Formulaire non valide; merci de remplir les champs obligatoires marqués *</span>
        </div>

        <div class="form-group row">
          <label for="type" class="col-sm-2 col-form-label" v-translate>Type de notice</label>
          <div class="col-sm-10">
            <select v-if="isCartType" v-model="form.type" class="form-control" id="type">
              <option value="cart" v-translate>Dossier de sélection</option>
            </select>
            <select v-else-if="isMovementType" v-model="form.type" class="form-control" id="type">
              <option value="movement" v-translate>Mouvement</option>
            </select>
            <select v-model="form.type" @change="buildReferences()" v-else class="form-control" id="type">
              <option :key="index" v-for="(option, index) in types" :value="option.type">
                {{ $gettext(option.val) }}
              </option>
            </select>
          </div>
        </div>
        <hr />
        <div class="form-group row" v-if="isCartType && isCartReference">
          <div class="row w-100">
            <label for="references" class="col-sm-2 col-form-label" v-translate>Cart id</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                v-model="form.references[0].id"
                disabled
                name="references"
                id="references"
                required
              />
            </div>
          </div>
        </div>
        <hr />
        <div class="form-group row" v-if="isMovementType">
          <div class="row w-100">
            <label for="references" class="col-sm-2 col-form-label" v-translate>Mouvement id</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                v-model="form.references[0].id"
                disabled
                name="references"
                id="references"
                required
              />
            </div>
          </div>
        </div>
        <hr />
        <div class="form-group row" v-if="isArtworkType">
          <div class="row w-100">
            <label for="references" class="col-sm-2 col-form-label">
              <span v-translate>Id(s) de référence</span>
              (*)
            </label>
            <div class="col-sm-10">
              <TextNoteReference
                @toggleHeader="toggleHeader"
                @removeReference="removeReference"
                :index="index"
                :is-cart-type="isCartType"
                :reference="form.references[index]"
                :artwork="resource"
                v-for="(artworkId, index) in form.references"
                :key="index"
              />
              <div class="d-flex flex-row-reverse">
                <a class="btn btn-round btn-round-white" @click.stop.prevent="addReference()" v-translate>Ajouter id</a>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="form-group row" v-if="isArtistType">
          <div class="row w-100">
            <label for="references" class="col-sm-2 col-form-label" v-translate>Artiste</label>
            <div class="col-sm-10">
              <select v-model="form.references" class="form-control" multiple="true" id="references">
                <option :key="author.id" v-for="author in authors" :value="{ id: author.id }">
                  {{ author.label }}
                </option>
              </select>
              <p class="help-block" v-translate>La notice est liée à l'artiste surligné en bleu</p>
            </div>
          </div>
        </div>
        <hr />
        <div class="form-group row">
          <label for="label" class="col-sm-2 col-form-label" v-translate>Libellé affichage</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" name="label" v-model="form.label" id="label" />
          </div>
        </div>
        <div class="form-group row" v-if="categories.length">
          <label for="category" class="col-sm-2 col-form-label" v-translate>Catégorie</label>
          <div class="col-sm-10">
            <select v-model="form.category" class="form-control" id="category">
              <option value v-show="!categoryRequired"></option>
              <option :key="category.id" v-for="category in categories" :value="category.id">
                {{ category.label }}
              </option>
            </select>
          </div>
        </div>
        <hr />
        <div class="form-group row">
          <label for="file_name" class="col-sm-2 col-form-label" v-translate>Nom fichier</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" disabled="true" name="doc" v-model="form.doc" id="file_name" />
          </div>
        </div>
        <hr />
        <div class="form-group row">
          <label for="redactor" class="col-sm-2 col-form-label" v-translate>Rédacteur</label>
          <div class="col-sm-10">
            <textarea-autosize class="form-control auto-grow" v-model="form.author" id="redactor"></textarea-autosize>
          </div>
          <div v-if="aliasNames.length > 1" class="offset-2 col-sm-10">
            <select v-model="form.author" class="form-control">
              <option :key="index" v-for="(alias, index) in aliasNames" :value="alias">{{ alias }}</option>
            </select>
          </div>
        </div>
        <hr />
        <div class="form-group row">
          <label for="owner" class="col-sm-2 col-form-label" v-translate>Editeur de la notice</label>
          <div class="col-sm-10">
            <input type="text" disabled="true" class="form-control" name="owner" v-model="form.owner" id="owner" />
          </div>
        </div>
        <hr />
        <div class="form-group row">
          <label for="date" class="col-sm-2 col-form-label" v-translate>Date de la notice</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" v-model="form.date" id="date" />
          </div>
        </div>
        <hr />
        <div class="form-group row">
          <label for="title" class="col-sm-2 col-form-label" v-translate>Titrage</label>
          <div class="col-sm-10">
            <textarea-autosize class="form-control auto-grow" v-model="form.title" id="title"></textarea-autosize>
            <p class="help-block">
              <span v-translate>Retour ligne:</span>
              <code> <span>&lt;</span>br /<span>&gt;</span> </code>
              <span v-translate>Italic:</span>
              <code>*...*</code>
            </p>
          </div>
        </div>
        <hr />
        <div class="form-group row">
          <label for="image" class="col-sm-2 col-form-label" v-translate>Image principale</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" v-model="form.image_url" id="image" />
            <p class="help-block">
              <translate>Si vide, la première image de l'œuvre sera affichée</translate>
            </p>
          </div>
        </div>
        <hr />
        <TextNoteLinks
          key="internal"
          @addLink="addLink"
          @removeLink="removeLink"
          :links="form.notquotedlinks_internal"
          type="internal"
        />
        <hr />
        <TextNoteLinks
          key="external"
          @addLink="addLink"
          @removeLink="removeLink"
          :links="form.notquotedlinks_external"
          type="external"
        />
      </div>
    </perfect-scrollbar>
  </form>
</template>

<script>
import { partialStrings, text_notes_categories, text_notes_categories_mandatory } from 'config';
import { mapGetters, mapActions } from 'vuex';
import TextNoteLinks from './TextNoteLinks.vue';
import TextNoteReference from './TextNoteReference.vue';
import cloneDeep from 'lodash.clonedeep';
import textNoteTitle from '../util/textNoteTitle';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

export default {
  name: 'TextNoteParametersForm',
  components: {
    TextNoteLinks,
    TextNoteReference,
  },
  created() {
    this.form = cloneDeep(this.textNote.note_properties);
    this.form.date = this.form.date.length ? this.form.date : dayjs(new Date()).locale(this.lang).format('MMMM YYYY');
    this.references = this.form.references;
    if (!this.form.author.length) {
      this.form.author = this.aliasNames[0];
    }
    if (this.categoryRequired && !this.category) {
      this.form.category = this.categories[0].id;
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.ps.ps.update();
    }, 200);
  },
  methods: {
    ...mapActions('textNotes', ['setProperties']),
    close() {
      this.$root.$emit('bv::show::modal', 'contentForm');
    },
    submit(event) {
      if (!this.form.references[0]) {
        this.submitted = true;
        this.errors.push('Reference is requred');
      } else {
        this.submitted = false;
        this.errors = [];
        this.setProperties(this.form);
        this.$root.$emit('bv::show::modal', 'contentForm');
      }
    },
    addReference() {
      let { ua_name } = this.form.references[0];
      this.form.references.push({ ua_name });
    },
    removeReference(reference) {
      const index = this.form.references.indexOf(reference);
      if (index >= 0) {
        this.form.references.splice(index, 1);
      }
    },
    toggleHeader() {
      if (this.form.artwork_ids.length) {
        this.form.artwork_ids = [];
      } else {
        this.form.artwork_ids = this.resource.related || [];
      }
    },
    removeLink(index, type) {
      if (type === 'external') {
        this.form.notquotedlinks_external.splice(index, 1);
      } else {
        this.form.notquotedlinks_internal.splice(index, 1);
      }
    },
    addLink(item, type) {
      if (type === 'external') {
        this.form.notquotedlinks_external.push(item);
      } else {
        this.form.notquotedlinks_internal.push(item);
      }
    },
    buildReferences() {
      if (this.isArtistType) {
        this.form.doc = this.form.doc.split('_oeuvres').join('_artist');
        this.form.references = [{ id: this.authors[0].id }];
        this.form.label = partialStrings.text_notes.artistLabel;
      } else if (this.isArtworkType) {
        this.form.doc = this.form.doc.split('_artist').join('_oeuvres');
        this.form.references = this.references;
        this.form.label = partialStrings.text_notes.artworkLabel;
      }
      this.form.title = textNoteTitle(this.resource, this.form.type);
    },
  },
  computed: {
    ...mapGetters('textNotes', { textNote: 'form' }),
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('textNotes', ['resource']),
    publicationStatus() {
      return this.form.publication_status;
    },
    isCartType() {
      return this.form.type === 'cart';
    },
    isMovementType() {
      return this.form.type === 'movement';
    },
    isArtworkType() {
      return this.form.type === 'artwork';
    },
    isArtistType() {
      return this.form.type === 'artist';
    },
    isCartReference() {
      return this.form.references[0].ua_name === 'cart';
    },
    aliasNames() {
      const { alias_names = [] } = this.currentUser;
      return alias_names;
    },
    authors() {
      const { authors = [] } = this.resource;

      return authors.map((a) => {
        return {
          id: a._id,
          label: a.name.list,
        };
      });
    },
    lang() {
      return this.$language.current;
    },
  },
  data() {
    return {
      form: {},
      submitted: false,
      errors: [],
      scrollOptions: {
        wheelSpeed: 1,
        wheelPropagation: false,
        minScrollbarLength: 20,
      },
      categories: text_notes_categories,
      categoryRequired: text_notes_categories_mandatory,
      types: [
        { type: 'artwork', val: 'Œuvre(s)' },
        { type: 'artist', val: 'artiste' },
      ],
    };
  },
};
</script>
<style lang="less">
hr + hr {
  display: none;
}
.modal-parameters-edit {
  overflow: hidden;
  .form-params {
    height: 90vh;
    display: flex;
    flex-direction: column;
  }

  .scroller {
    flex: 1;
    flex-direction: row;
    position: relative;
    overflow-y: hidden;
  }

  textarea.auto-grow {
    resize: none;
    overflow: hidden;
    min-height: 54px;
    margin-bottom: 15px;
  }
  .input-group {
    margin-bottom: 10px;
  }
  .addon-hidden {
    visibility: hidden;
  }
}
</style>
