import { HTTP, NO_CACHE_HEADER } from '../util';

export default {
  async check(email) {
    const { data, status } = await HTTP.get('/users/check', { params: { email: email } }, NO_CACHE_HEADER);
    return { data, status };
  },
  async updateProfile(profile) {
    const { data, status } = await HTTP.put('/users/profile', { profile }, NO_CACHE_HEADER);
    return { data, status };
  },
};
