<template>
  <div class="modal-dialog m-0">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <h2 v-translate>Votre navigateur n'est pas à jour</h2>
          <p v-translate>
            La version de votre navigateur n'est pas assez récente pour visualiser ce site. Il est nécessaire d'avoir:
            Firefox, Google Chrome, Safari or Internet Explorer (10 or higher)
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UnsupportedBrowserModal',
};
</script>
