<template>
  <b-modal
    size="md"
    id="downloadExportModal"
    ref="downloadExportModal"
    hide-footer
    hide-header
    lazy
    :no-close-on-backdrop="true"
    no-stacking
    modal-class="p-0"
  >
    <translate>Votre XLSX est calculé. Cliquer sur Ok pour le télécharger.</translate>
    <div class="select-all-moda-buttons">
      <button class="btn btn-defualt" @click="open()" v-translate>Ok</button>
      <button class="btn btn-defualt" @click="hide()" v-translate>Annuler</button>
    </div>
  </b-modal>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'DwonloadExxportModal',
  methods: {
    ...mapMutations('artworks', ['assign']),
    hide() {
      this.$refs.downloadExportModal.hide();
    },
    open() {
      window.open(this.exportLink, '_blank');
      this.assign({ name: 'exportLink', data: null });
      this.$refs.downloadExportModal.hide();
    },
  },
  computed: {
    ...mapState('artworks', ['exportLink']),
  },
};
</script>
