<template>
  <div class="col-xl-3 pl-4 text-xl-right art-counter pr-0 pr-md-4">
    <span class="filtered" v-if="isFiltered">
      <b>{{ filteredCount | number }}</b> /
    </span>
    <span class="total" id="step1" v-if="totalCount">{{ totalCount | number }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ArtCounter',
  computed: {
    ...mapGetters('artworks', ['filteredCount', 'totalCount']),
    isFiltered() {
      return this.filteredCount !== this.totalCount;
    },
  },
};
</script>
