<template>
  <div class="thematic-note-top">
    <div class="thematic-header">
      <i class="icon-dossier_thema"></i>
      <h1>{{ label }}</h1>
    </div>
    <div>
      <div class="switch" @click.stop.prevent="toggle()" :class="{ on: !showTextNote }">
        <label v-translate>Notice</label>
        <div class="knob"></div>
        <label v-translate>Œuvres</label>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ArtworksTextNoteToggleButton',
  props: {
    showTextNote: Boolean,
  },
  computed: {
    ...mapGetters('artworks', ['textNote']),
    label() {
      const { filter } = this.textNote?.note_properties;
      return filter[0].value;
    },
  },
  methods: {
    toggle() {
      const showTextNote = !this.showTextNote;
      this.$router.push({ query: { ...this.$route.query, show_text_note: showTextNote } });
    },
  },
};
</script>
<style lang="less">
.thematic-note-top {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: @artwork-list-background;

  h1 {
    font-weight: 100;
    display: inline;
  }

  i {
    padding-right: 10px;
    padding-left: 20px;
    font-size: 28px;
  }

  .switch {
    margin-right: 20px;
  }
}

.switch {
  label {
    float: left;
    display: block;
    font-size: 14px;
    line-height: 28px;
    cursor: pointer;
    transition: 0.5s color;
    padding-bottom: 10px;
    color: @dusty-gray;
    &:first-of-type {
      color: @tundora;
    }
    &:hover {
      color: @chalky !important;
    }
  }
  .knob {
    float: left;
    width: 62px;
    height: 28px;
    margin: 0 10px;
    border-radius: 14px;
    background: #fff;
    position: relative;
    &:after {
      // the yellow knob
      content: '';
      background: @chalky;
      width: 22px;
      height: 22px;
      border-radius: 100%;
      position: absolute;
      left: 4px;
      top: 3px;
      display: block;
      transition: 0.5s left;
    }
  }
  &.on {
    label {
      &:first-of-type {
        color: @dusty-gray;
      }
      &:last-of-type {
        color: @tundora;
      }
    }
    .knob {
      &:after {
        left: 36px;
        transition: 0.5s left;
      }
    }
  }
}
</style>
