import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import * as artworks from './artworks';
import * as aggregations from './aggregations';
import * as autocomplete from './autocomplete';
import * as params from './params';
import * as auth from './auth';
import * as local from './local';
import * as carts from './carts';
import * as textNotes from './textNotes';
import * as infinite from './infinite';

import { StorageVerion } from '../util/storage';

const store = new Vuex.Store({
  modules: {
    artworks,
    aggregations,
    params,
    autocomplete,
    auth,
    local,
    carts,
    textNotes,
    infinite,
  },
  strict: true,
});

store.subscribe((mutation, state) => {
  if (
    (mutation.type === 'aggregations/AGGREGATION_OBJECT' && mutation.payload) ||
    mutation.type === 'params/SET_TERM_ORDER' ||
    mutation.type === 'params/SET_TERM_PAGE'
  ) {
    store.dispatch('aggregations/fetchTerms');
  }

  if (mutation.type === 'autocomplete/AUTOCOMPLETE_SEARCH') {
    store.commit('aggregations/UPDATE_TOTAL', mutation.payload);
  }

  // RESET STATE
  if (mutation.type === 'route/ROUTE_CHANGED') {
    store.commit(`autocomplete/UPDATE_QUERY`, '');
    store.commit(`params/RESET_PAGE`);
    store.commit(`aggregations/AGGREGATION_OBJECT`, null);
    store.commit(`aggregations/AGGREGATION_TERMS`, []);
  }

  if (mutation.type === 'autocomplete/UPDATE_QUERY') {
    store.commit(`params/RESET_TERM_PAGE`);

    if (mutation.payload.length < 3) {
      store.commit('autocomplete/RESET_AUTOCOMPLETE');
    }
  }

  if (mutation.type === 'artworks/assign' && mutation.payload.name === 'artwork') {
    store.dispatch('artworks/buildElements');
  }

  if (
    mutation.type === 'local/UPDATE_SUMMARY' ||
    mutation.type === 'local/UPDATE_TITLE' ||
    mutation.type === 'local/UPDATE_COLUMN_WIDTH' ||
    mutation.type === 'local/UPDATE_PREFERENCES'
  ) {
    localStorage.setItem(`navigartLocal${StorageVerion}`, JSON.stringify(state.local));
  }

  if (
    mutation.type === 'artworks/loaded' ||
    mutation.type === 'carts/CARTS_ADD_CART' ||
    mutation.type === 'carts/CARTS_SET_ACTIVE'
  ) {
    store.dispatch('carts/fetchUnselectAllStatus');
  }
});

export default store;
