<template>
  <div class="text-note-content" :class="{ noselect: isHandlerDragging }">
    <div class="modal-header">
      <div class="row w-100">
        <div class="col-md-7">
          <h3 class="modal-title" v-if="isEdit" v-translate>Mise à jour notice rédactionnelle</h3>
          <h3 class="modal-title" v-if="!isEdit" v-translate>Création d'une nouvelle notice rédactionnelle</h3>
        </div>
        <div class="col-md-5 d-flex justify-content-end align-items-baseline">
          <a
            href
            @click.prevent="openParametresModal"
            class="btn btn-valid"
            :class="{ 'btn-valid-green': validParameters }"
          >
            <i class="icon-forms"></i>
            <i class="icon-VALID" v-show="validParameters"></i>
            <span v-translate>Paramètres</span>
          </a>

          <a href class="btn btn-round" @click.stop.prevent="close()" v-translate>Annuler</a>
          <button class="btn btn-round" @click.stop.prevent="save()" v-translate>Valider</button>
        </div>
      </div>
    </div>
    <div class="handler left"></div>
    <perfect-scrollbar class="scroller scroll-always-visible" :options="scrollOptions">
      <div class="modal-body px-2">
        <div class="row" v-if="!validParameters && showValidationAlert">
          <div class="col-md-12">
            <div class="alert alert-warning" role="alert">
              <span v-translate
                >La validation n'est pas possible tant que les paramètres ne sont pas saisis et validés en activant la
                fonction 'Paramètres'</span
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div style="width: 50%" id="resizable-left" :style="leftColumnStyles">
            <TextNoteContentFormMarkdownButtons :simplemde="simplemde" />
            <textarea-autosize v-model="form.content" ref="simplemdeEl"></textarea-autosize>
          </div>
          <div style="width: 50%" id="resizable-right" :style="rightColumnStyles">
            <div class="livepreview">
              <div class="notice-head">
                <div class="controls">
                  <label>
                    <input type="checkbox" v-model="showTextNoteTitle" />
                    <span v-translate>Afficher titre</span>
                  </label>
                  <label>
                    <input type="checkbox" v-model="showTextNoteSummary" />
                    <span v-translate>Afficher récapitulatif des liens</span>
                  </label>
                </div>
                <h2>{{ titleNotice }}</h2>
              </div>
              <div class="notice-body">
                <TextNoteBody
                  :text-note="form"
                  :show-title="showTextNoteTitle"
                  :show-summary="showTextNoteSummary"
                  :target-blank="true"
                  :editing="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <div class="handler right"></div>
  </div>
</template>

<script>
import TextNoteBody from './TextNoteBody.vue';
import TextNoteContentFormMarkdownButtons from './TextNoteContentFormMarkdownButtons.vue';
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import { smdeInitialState } from '../store/artworks/state.js';

export default {
  name: 'TextNoteContentForm',
  components: {
    TextNoteBody,
    TextNoteContentFormMarkdownButtons,
  },
  created() {
    this.form = cloneDeep(this.textNote);
    let right, left, pageX, side, leftW, rightW;

    document.addEventListener('mousedown', (e) => {
      if (e.target.className.includes('handler')) {
        this.isHandlerDragging = true;
        left = document.getElementById('resizable-left');
        right = document.getElementById('resizable-right');
      }
    });

    document.addEventListener('mousemove', (e) => {
      if (!this.isHandlerDragging) {
        return false;
      }

      let diff;
      if (!pageX) {
        side = e.target;
        pageX = e.pageX;
        leftW = left.offsetWidth;
        rightW = right.offsetWidth;
      }

      diff = e.pageX - pageX;

      const wrapper = document.querySelector('.modal-dialog');

      let containerOffsetLeft = wrapper.offsetLeft;

      let pointerRelativeXpos = e.pageX - containerOffsetLeft;

      if (side.className.includes('left')) {
        right.style.flexBasis = rightW + 'px';
        left.style.flexBasis = leftW + diff * -1 + 'px';
        wrapper.style.minWidth = leftW + diff * -1 + rightW + 16 + 'px';
      }

      if (side.className.includes('right')) {
        left.style.flexBasis = leftW + 'px';
        right.style.flexBasis = rightW + diff + 'px';
        wrapper.style.minWidth = leftW + diff + rightW + 16 + 'px';
      }
      this.$store.commit('local/UPDATE_COLUMN_WIDTH', {
        leftColumnWidth: left.offsetWidth,
        rightColumnWidth: right.offsetWidth,
      });
    });

    document.addEventListener('mouseup', (e) => {
      this.isHandlerDragging = false;
      pageX = null;
    });
  },
  methods: {
    ...mapActions('textNotes', ['setContent']),
    ...mapActions('textNotes', { saveTextNote: 'save' }),
    ...mapMutations('artworks', ['assign']),
    close() {
      this.$root.$emit('bv::hide::modal', 'contentForm');
      this.assign({ name: 'smde', data: smdeInitialState });
    },
    save() {
      this.setContent(this.form.content);
      this.saveTextNote({ form: this.textNote, artwork: this.artwork });
      this.$root.$emit('bv::hide::modal', 'contentForm');
      this.assign({ name: 'smde', data: smdeInitialState });
    },
    openParametresModal() {
      let smde = {
        content: this.simplemde.value(),
        cursorStart: this.simplemde.codemirror.getCursor('start'),
        cursorEnd: this.simplemde.codemirror.getCursor('end'),
        link: null,
        selectedText: this.simplemde.codemirror.getSelection(),
      };

      this.assign({ name: 'smde', data: smde });
      this.setContent(this.form.content);
      return this.$root.$emit('bv::show::modal', 'parametersForm');
    },
  },
  computed: {
    ...mapGetters('textNotes', { textNote: 'form' }),
    ...mapGetters('textNotes', ['validParameters']),
    ...mapGetters('artworks', ['artwork']),
    ...mapState('artworks', ['smde']),
    ...mapState('local', ['leftColumnWidth', 'rightColumnWidth']),
    isEdit() {
      return false;
    },
    titleNotice() {
      return this.form.title_notice;
    },
    linkDesc() {
      return this.smde.selectedText.length > 0 ? this.smde.selectedText : this.$gettext('saisir ici le texte du lien');
    },
    leftColumnStyles() {
      if (this.leftColumnWidth) {
        return {
          'flex-basis': this.leftColumnWidth + 'px',
        };
      }

      return {};
    },
    rightColumnStyles() {
      if (this.leftColumnWidth) {
        return {
          'flex-basis': this.rightColumnWidth + 'px',
        };
      }

      return {};
    },
  },
  async mounted() {
    const { default: SimpleMDE } = await import(/* webpackChunkName: "simplemde" */ 'simplemde');
    const wrapper = document.querySelector('.modal-dialog');

    if (this.leftColumnWidth || this.rightColumnWidth) {
      wrapper.style.minWidth = this.leftColumnWidth + this.rightColumnWidth + 16 + 'px';
    }

    this.simplemde = new SimpleMDE(this.configs);
    this.simplemde.codemirror.on('change', () => {
      this.form.content = this.simplemde.value();
    });

    if (this.smde.content) {
      this.simplemde.value(this.smde.content);
      this.simplemde.codemirror.setCursor({ line: this.smde.cursorEnd.line, ch: this.smde.cursorEnd.ch });
    }

    if (this.smde.selectedText) {
      this.simplemde.codemirror.setSelection(this.smde.cursorStart, this.smde.cursorEnd);
    }

    if (this.smde.link) {
      const mdLink = `[${this.linkDesc}](${this.smde.link})\n`;
      this.simplemde.codemirror.replaceSelection(mdLink);
    }
  },
  data() {
    return {
      simplemde: {},
      isHandlerDragging: false,
      form: {},
      showValidationAlert: false,
      showTextNoteSummary: true,
      showTextNoteTitle: true,
      scrollOptions: {
        suppressScrollX: true,
        wheelSpeed: 1,
        wheelPropagation: false,
        minScrollbarLength: 20,
      },
      configs: {
        element: this.$refs.simplemdeEl,
        spellChecker: false,
        spellChecker: false,
        toolbarTips: false,
        status: false,
        toolbar: [],
      },
    };
  },
};
</script>
<style lang="less">
@import '~simplemde/dist/simplemde.min.css';

.modal-notice-edit {
  overflow: hidden;
  .modal-header .btn {
    margin-left: 4px;
  }

  .text-note-content {
    height: 90vh;
    display: flex;
    flex-direction: column;
  }

  .scroller {
    flex: 1;
    flex-direction: row;
    position: relative;
    overflow-y: hidden;
  }
}
.modal-notice-edit {
  .CodeMirror {
    overflow: initial;
    border: 8px solid @chalky !important;
    background-color: @white;
    color: @dusty-gray;
    display: block;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    min-height: 200px;
    &:focus {
      box-shadow: none;
      color: @mine-shaft;
      outline: 0;
    }
    &::after {
      content: '';
      position: absolute;
      top: 55px;
      right: -19px;
      border-top: 17px solid transparent;
      border-left: 17px solid @chalky;
      border-bottom: 17px solid transparent;
    }
  }
}

.livepreview {
  margin-left: -7px;
  padding: 0 30px 30px;
  border: 8px solid @mercury;
  p {
    word-wrap: break-word;
  }
  h2 {
    margin-bottom: 30px;
  }
  .controls {
    background-color: white;
    padding: 8px 0;
    z-index: 1;
  }
  .notice-thumb img {
    padding-bottom: 30px;
    max-width: 120px;
  }

  .notice-body {
    padding: 0px;
    line-height: 1.8;
    .HeaderFont(15px);
    font-weight: normal;
  }
}

.handler {
  width: 20px;
  padding: 0;
  cursor: ew-resize;
  flex: 0 0 auto;
  z-index: 2;
  height: 100%;
  position: absolute;

  &.left {
    top: 0px;
    left: -20px;
  }

  &.right {
    top: 0px;
    right: -20px;
  }
}

.noselect {
  user-select: none;
}
</style>
