import Vue from 'vue';

export const HideIfEmpty = {
  bind(el) {
    let text = el.textContent;
    if (!text.replace(/\s+/, '')) {
      el.style.display = 'none';
    } else {
      el.style.display = 'block';
    }
  },
};

Vue.directive('hide-if-empty', HideIfEmpty);
