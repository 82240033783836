<template>
  <a href @click.prevent.stop="removeTerm(filter, index)" v-b-tooltip.right :title="tooltip">
    <slot></slot>
    <i :class="icon"></i>
  </a>
</template>
<script>
import FilterBuilder from 'lib/filterBuilder';
import { mapGetters } from 'vuex';

export default {
  name: 'RemoveFilterButton',
  props: {
    filter: Object,
    index: Number,
    icon: String,
    tooltip: String,
    removeAll: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('params', ['locationFilters']),
  },
  methods: {
    removeTerm(filter, index) {
      const filters = this.locationFilters;
      const toRemove = filters.find((f) => f.aggregation === this.filter.aggregation && f.term === this.filter.term);

      if (this.removeAll) {
        const toRemoveIndex = filters.indexOf(toRemove);
        filters.splice(toRemoveIndex, 1);
      } else {
        toRemove.term.splice(index, 1);
      }

      const query = this.$route.query;

      this.$router.push({
        name: this.$route.name,
        query: { ...query, page: 1 },
        ...(filters.length && { params: { filters: FilterBuilder.toURLParam(filters) } }),
      });
    },
  },
};
</script>
