<template>
  <li @click.stop="applyTerm(model)">
    <label class="checkbox" :for="key" v-bind:style="{ 'padding-left': treePadding + 'px' }">
      <div class="tree-operator" @click.stop.prevent="toggle" v-if="!type">
        <div v-if="isEmptyFolder" class="empty-nodes"></div>
        <i
          v-else
          v-bind:class="{ true: 'icon-list_moins', false: 'icon-list_plus', undefined: 'icon-list_plus' }[opened]"
        ></i>
      </div>
      <input
        type="checkbox"
        v-if="type == 'multi_term' || type == 'range'"
        v-bind:disabled="type == 'range' && checked.length == 2 && !checked.includes(model.key)"
        :id="key"
        :value="model.key"
        v-model="checked"
        @change="onChange"
      />
      <TooltipEllipses class="filter-name" :value="highlight" />
      <span class="count">[{{ model.doc_count | number }}]</span>
    </label>
    <ul v-if="opened">
      <filter-item
        class="item filter-item no-tree"
        v-for="(model, index) in model.children"
        :key="index"
        :expandAll="expandAll"
        :model="model"
        :type="type"
      />
    </ul>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
import keyToLabel from 'lib/keyToLabel';
import TooltipEllipses from './TooltipEllipses.vue';
import FilterBuilder from 'lib/filterBuilder';

const SPLIT_CHAR = '↹';

export default {
  name: 'FilterItem',
  props: ['value', 'model', 'type', 'expandAll'],
  components: {
    TooltipEllipses,
  },
  mounted() {
    this.autoToggle();
    if (this.expandAll) {
      this.opened = this.expandAll;
    }
  },
  methods: {
    highlightedChildren(highlights, child) {
      const highlightTag = new RegExp('<[^>]*>', 'ui');
      const [last, ...rest] = child.highlight.split(SPLIT_CHAR).reverse();

      if (highlightTag.test(last)) {
        highlights.push(child);
        return highlights;
      }

      if (child.children && child.children.length > 0) {
        child.children.reduce(this.highlightedChildren, highlights);
      }
      return highlights;
    },
    autoToggle() {
      if (this.isFolder && this.model.highlight) {
        const { children } = this.model;

        const highlights = children.reduce(this.highlightedChildren, []);

        if (highlights.length) {
          this.opened = true;
        } else {
          this.opened = false;
        }
      }
    },
    toggle() {
      if (this.isFolder) {
        this.opened = !this.opened;
      }
    },
    applyTerm(term) {
      if (this.type == 'multi_term' || this.type == 'range') {
        return;
      }
      const filters = [{ term: [term.key], operator: 'and', aggregation: this.aggregation, type: this.type || 'term' }];

      const { id, query } = this.$route;

      this.$router.push({
        query: { ...query, page: 1 },
        params: { filters: FilterBuilder.toURLParam(this.locationFilters.concat(filters)) },
      });
    },
    onChange(e) {
      this.$emit('input', this.checkedProxy);
    },
  },
  computed: {
    ...mapGetters('aggregations', ['aggregation']),
    ...mapGetters('params', ['locationFilters']),
    highlight() {
      return keyToLabel(this.model.highlight || this.model.key);
    },
    treePadding() {
      return this.model.level === 1 ? 0 : 10 + 30 * (this.model.level - 1);
    },
    isFolder() {
      return this.model.children;
    },
    isEmptyFolder() {
      return (this.isFolder && this.model.children.length == 0) || !this.isFolder;
    },
    key() {
      return `item${this.$vnode.key}`;
    },
    checked: {
      get() {
        return this.value;
      },
      set(val) {
        this.checkedProxy = val;
      },
    },
  },
  watch: {
    expandAll(newVal, oldVal) {
      if (typeof newVal === 'boolean') {
        this.opened = newVal;
      }
    },
    model() {
      this.autoToggle();
    },
  },
  data() {
    return {
      opened: false,
      checkedProxy: false,
    };
  },
};
</script>
