<template>
  <div id="app" ref="app">
    <router-view />

    <Loader />
    <b-modal
      id="contentForm"
      :no-close-on-backdrop="true"
      :v-bind="true"
      hide-header
      hide-footer
      no-stacking
      lazy
      body-class="p-0"
      modal-class="modal-notice-edit"
    >
      <TextNoteContentForm />
    </b-modal>

    <b-modal
      id="linksModal"
      hide-header
      hide-footer
      :no-close-on-backdrop="true"
      no-stacking
      lazy
      body-class="p-0"
      modal-class="links-modal"
    >
      <LinksModal />
    </b-modal>

    <b-modal
      id="parametersForm"
      :no-close-on-backdrop="true"
      hide-header
      hide-footer
      no-stacking
      lazy
      body-class="p-0"
      modal-class="modal-parameters-edit"
    >
      <TextNoteParametersForm />
    </b-modal>

    <b-modal id="shareModal" hide-header hide-footer lazy body-class="object-share p-0">
      <ShareLinkModal />
    </b-modal>

    <b-modal v-model="unsupportedBrowserModal" hide-header hide-footer lazy body-class="p-0">
      <UnsupportedBrowserModal />
    </b-modal>

    <PrintModal />
    <DownloadExportModal />

    <CartShareModal />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TextNoteContentForm from './TextNoteContentForm.vue';
import TextNoteParametersForm from './TextNoteParametersForm.vue';
import ShareLinkModal from './ShareLinkModal.vue';
import CartShareModal from './CartShareModal.vue';
import UnsupportedBrowserModal from './UnsupportedBrowserModal.vue';
import LinksModal from './LinksModal.vue';
import PrintModal from './PrintModal.vue';
import DownloadExportModal from './DownloadExportModal.vue';
import Loader from './Loader.vue';
import toastr from 'mixins/toastrMixin.js';

import { publicationAccessParameters } from 'config';

export default {
  name: 'App',
  components: {
    TextNoteContentForm,
    TextNoteParametersForm,
    ShareLinkModal,
    UnsupportedBrowserModal,
    Loader,
    LinksModal,
    PrintModal,
    CartShareModal,
    DownloadExportModal,
  },
  mixins: [toastr],
  computed: {
    ...mapState('auth', ['token', 'currentUser']),
    ...mapState('local', ['notifyGranted']),
  },
  methods: {
    setDimensions() {
      this.$refs.app.style.maxHeight = `${window.innerHeight}px`;
      this.$refs.app.style.minHeight = `${window.innerHeight}px`;
    },
    checkNavigator() {
      let ver = -1;
      if (navigator.appName === 'Microsoft Internet Explorer') {
        let ua = navigator.userAgent;
        let re = new RegExp('MSIE ([0-9]{1,}.{0,}[0-9]{0,})');

        if (re.exec(ua) !== null) {
          ver = parseFloat(RegExp.$1);
        }
      }

      if (ver > -1 && ver <= 9.0) {
        return true;
      }

      return false;
    },
  },
  mounted() {
    if (!sessionStorage.getItem('token')) {
      localStorage.setItem('getSessionStorage', Date.now());
    }
    window.addEventListener('storage', (event) => {
      if (event.key == 'getSessionStorage') {
        localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
        localStorage.removeItem('sessionStorage');
      } else if (event.key == 'sessionStorage' && !sessionStorage.getItem('token')) {
        var data = JSON.parse(event.newValue);
        for (var key in data) {
          sessionStorage.setItem(key, data[key]);

          if (key === 'token') {
            this.$store.dispatch('auth/me');
          }
        }
      }
    });
    this.unsupportedBrowserModal = this.checkNavigator();
    window.addEventListener('resize', this.setDimensions);
    this.setDimensions();
  },
  destroyed() {
    window.removeEventListener('resize', this.setDimensions);
  },
  watch: {
    notifyGranted(newValue, oldValue) {
      if (newValue) {
        this.toastr('autoGranted', { body: publicationAccessParameters.private_granted_automatic_message.fr });
        this.$store.commit('local/UPDATE_NOTIFY_GRANTED', null, { root: true });
      }
    },
  },
  data() {
    return {
      unsupportedBrowserModal: false,
    };
  },
};
</script>

<style scoped lang="less">
#app {
  padding: 0px;
  display: flex;
  flex-direction: column;
  background-color: @artwork-list-background;
}
</style>
