<template>
  <button @click="openModal" v-b-tooltip.hover.left :title="title || titles.export">
    <SvgExport />
    <ExportXlsxModal v-model="modalShow" :onClose="onClose" />
  </button>
</template>
<script>
import { mapGetters } from 'vuex';
import ExportXlsxModal from './ExportXlsxModal.vue';
import toastr from 'mixins/toastrMixin.js';
import { exportLimit } from 'config';

export default {
  name: 'ExportXlsxButton',
  mixins: [toastr],
  props: {
    title: String,
  },
  components: {
    ExportXlsxModal,
  },
  computed: {
    ...mapGetters('artworks', ['filteredCount', 'totalCount']),
    titles() {
      return {
        export: this.$gettext('Export xlsx'),
      };
    },
  },
  methods: {
    onClose() {
      this.modalShow = false;
    },
    openModal() {
      if (
        (this.totalCount === this.filteredCount && this.filteredCount > exportLimit) ||
        (this.totalCount !== this.filteredCount && this.filteredCount > exportLimit)
      ) {
        this.toastr('exportLimit', { limit: exportLimit });
        return;
      }
      this.modalShow = true;
    },
  },
  data() {
    return {
      modalShow: false,
    };
  },
};
</script>
<style lang="less" scoped>
button {
  border: none;
  background: transparent;
  padding: 0px;
  cursor: pointer;
  svg {
    width: 24px;
    path {
      fill: @silver;
      stroke: @silver;
    }
  }

  &:hover {
    svg {
      path {
        fill: @chalky;
        stroke: @chalky;
      }
    }
  }
}
</style>
