<template>
  <div :style="{ height: '100%' }" :class="{ false: 'notice-content full-width' }[isSingleArtwork]">
    <div class="notice-head" :class="{ true: 'col-md-8', false: 'col-md-12' }[isSingleArtwork && !isMovement]">
      <div class="notice-thumb">
        <div v-if="isNoImageOrArtistTextNote" v-b-tooltip.right :title="titles.close" tooltip-placement="right">
          <a @click="closeNote">
            <ArtworkImage :image="image" version="200" v-if="image && image.type === 'image'" />
          </a>
        </div>
        <div v-else>
          <img @click.stop.prevent="closeNote" v-bind:src="image" />
        </div>
      </div>

      <div class="publisher-links" v-if="isPublisher" v-hide-on-mobile>
        <span v-if="status">({{ textNoteStatus(textNote) }})</span>

        <button v-show="isDraft" class="btn btn-round" @click.stop.prevent="publish(textNote)">
          <translate>A publier</translate>
        </button>
        <button v-show="isPublishRequested" class="btn btn-round" @click.stop.prevent="cancelPublish(textNote)">
          <translate>Annuler</translate>
        </button>
      </div>

      <div class="publisher-links" v-if="canRemoveNote" v-hide-on-mobile>
        <button class="btn btn-round" @click.stop.prevent="remove()" v-translate>Supprimer la notice</button>
      </div>

      <div class="notice-links">
        <div class="btn-group">
          <a
            @click.prevent="share(artwork)"
            class="btn btn-outline-secondary share"
            v-b-tooltip.hover.left
            :title="titles.share"
          >
            <i class="icon-partager"></i>
          </a>
          <PrintLink
            v-if="isSingleArtwork && !isMovement"
            class="btn btn-outline-secondary"
            link="artwork"
            :artwork="artwork"
            v-hide-on-mobile
          />
        </div>
        <div class="btn-group" v-if="user" v-hide-on-mobile>
          <TextNoteLink
            class="btn btn-outline-secondary"
            :textNote="textNote"
            :title="titles.edit"
            v-show="!isPublishRequested"
            v-if="canEditOwn || $can('edit_all', 'text_note')"
            :resource="artwork"
            icon="Notice_Add_Notes"
          ></TextNoteLink>
          <TextNoteLink
            class="btn btn-outline-secondary"
            v-if="isSingleArtwork && $can('create', 'text_note') && !isCart"
            :title="titles.create"
            :type="type"
            :resource="artwork"
            icon="Notice_New"
          ></TextNoteLink>
        </div>
        <div class="btn-group">
          <TextNoteSummaryButton />
        </div>
        <FavoriteLink
          :artworkId="artwork._id"
          class="btn btn-outline-secondary"
          v-if="isSingleArtwork && !isMovement"
        />
      </div>
    </div>

    <TextNoteBody :text-note="textNote" :show-summary="showSummary" />
  </div>
</template>
<script>
import TextNoteLink from './TextNoteLink.vue';
import TextNoteBody from './TextNoteBody.vue';
import PrintLink from './PrintLink.vue';
import TextNoteSummaryButton from './TextNoteSummaryButton.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import textNoteStatusMixin from 'mixins/textNoteStatusMixin.js';
import abilitiesMixin from 'mixins/abilitiesMixin.js';
import shareMixin from 'mixins/shareMixin.js';
import FavoriteLink from './FavoriteLink.vue';
import ArtworkImage from './ArtworkImage.vue';

export default {
  name: 'TextNote',
  props: {
    textNote: {
      type: Object,
      default: function () {
        return {};
      },
    },
    artwork: Object,
    isSingleArtwork: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    TextNoteBody,
    TextNoteSummaryButton,
    TextNoteLink,
    PrintLink,
    FavoriteLink,
    ArtworkImage,
  },
  mixins: [textNoteStatusMixin, abilitiesMixin, shareMixin],
  computed: {
    ...mapGetters('local', ['showSummary']),
    ...mapGetters('auth', { user: 'currentUser' }),
    ...mapGetters('artworks', ['images']),
    isDraft() {
      return this.textNote.status === 'draft';
    },
    isPublishRequested() {
      return this.textNote.status === 'publish_requested';
    },
    isPublisher() {
      return this.isInternal && this.$can('publish_all', 'text_note');
    },
    isDeleter() {
      return (
        this.$can('deleter', 'text_note') || (this.$can('delete_own', 'text_note') && this.owner === this.user.email)
      );
    },
    owner() {
      const { owner } = this.textNote.note_properties || {};
      return this.textNote.owner || owner;
    },
    canEditOwn() {
      return this.owner === this.user.email && this.$can('edit_own', 'text_note');
    },
    canRemoveNote() {
      return this.isInternal && !this.isPublishRequested && this.isDeleter;
    },
    isInternal() {
      return this.textNote.internal;
    },
    isNoImageOrArtistTextNote() {
      const { image_url } = this.textNote.note_properties || {};
      return (!this.textNote.image_url && !image_url) || this.textNote.type === 'artist';
    },
    type() {
      const { type } = this.textNote.note_properties || {};
      return type;
    },
    status() {
      return this.textNote.status;
    },
    isMovement() {
      return this.type === 'movement';
    },
    isCart() {
      return this.type === 'cart';
    },
    image() {
      if (this.isNoImageOrArtistTextNote) {
        if (this.artwork && this.artwork.type === 'nonseparable') {
          return this.artwork.images[0];
        } else {
          return this.images[0];
        }
      } else {
        return this.textNote.image_url || this.textNote.note_properties.image_url;
      }
    },
    titles() {
      return {
        close: this.$gettext("Fermer la notice et agrandir l'image"),
        share: this.$gettext('Partager'),
        edit: this.$gettext('Modifier la notice rédactionnelle'),
        create: this.$gettext('Créer une nouvelle notice rédactionnelle'),
      };
    },
  },
  methods: {
    ...mapActions('textNotes', ['publish', 'cancelPublish', 'delete', 'assign']),
    remove() {
      let confirmText = this.$gettext('Cette action va supprimer la notice rédactionnelle');
      if (confirm(confirmText)) {
        const artworkId = this.artwork._id;
        this.delete({ note: this.textNote, artworkId });
      }
    },

    closeNote() {
      this.assign({ name: 'textNote', data: null });
      const query = Object.assign({}, this.$route.query, { note: null });
      const slug = this.$route.params.id;
      this.$router.push({ name: 'artwork', params: { id: slug }, query });
    },
  },
};
</script>
<style lang="less">
.notice-links {
  .btn-group {
    display: inline-block;
  }
}
</style>
