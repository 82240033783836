<template>
  <div class="fixed">
    <div class="notice-content">
      <TextNote :textNote="textNote" :artwork="artwork" :isSingleArtwork="true" v-if="textNote" />
    </div>
  </div>
</template>

<script>
import stickybits from 'stickybits';
import TextNote from './TextNote.vue';
import seoMetadata from 'mixins/seoMetadata';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  mixins: [seoMetadata],
  components: {
    TextNote,
  },
  computed: {
    ...mapState('textNotes', ['textNote']),
    ...mapState('artworks', ['artwork']),
    ...mapState('route', ['query', 'params']),
  },
  mounted() {
    stickybits(this.$el);
    this.checkNote();
  },
  methods: {
    ...mapMutations('textNotes', { assignTextNote: 'assign' }),
    ...mapActions('textNotes', { readTextNote: 'read' }),
    checkNote() {
      const { note } = this.params;
      this.assignTextNote({ name: 'textNote', data: null });
      if (note) {
        if (!this.textNote) {
          this.readTextNote(note);
          return;
        }
        if (note !== this.textNote.id) {
          this.readTextNote(note);
        }
      }
    },
  },
  watch: {
    'params.note': function (newValue, oldValue) {
      this.checkNote();
    },
  },
  data() {
    return {
      preventAutoTextNote: false,
    };
  },
  metaInfo() {
    if (this.textNote?.id) {
      return {
        ...this.metaConfig({
          title: `${this.metaNoteTitle} - ${this.metaNoteAuthor}`,
          titleTemplate: this.metaTitleTemplate,
        }),
      };
    }

    return {};
  },
};
</script>

<style lang="less" scoped>
.fixed {
  height: 100vh;
}
</style>
