import valueByPath from '../util/valueByPath';
import { movementParameters, publicationName, seo_metadata } from 'config';
import removeMD from 'remove-markdown';

export default {
  methods: {
    metaConfig(options) {
      const { title, titleTemplate, description } = options;
      const metaConfig = { meta: [] };

      if (title) {
        metaConfig.title = title;
        metaConfig.titleTemplate = titleTemplate;

        const metaItem = {
          property: 'og:title',
          vmid: 'og:title',
          template: titleTemplate,
          content: title,
        };

        metaConfig.meta.push(metaItem);
      }

      if (description) {
        metaConfig.meta.push({
          property: 'og:description',
          vmid: 'og:description',
          content: description,
        });

        metaConfig.meta.push({
          property: 'description',
          vmid: 'description',
          content: description,
        });
      }
      return metaConfig;
    },
  },
  computed: {
    metaTitleTemplate() {
      return this.$options.name === 'SearchContext' ? undefined : `%s | ${publicationName}`;
    },
    metaHomeDescription() {
      return seo_metadata?.homepage_description || publicationName;
    },
    metaArtworkTitle() {
      const path = seo_metadata?.artwork_title || 'title_notice';
      return valueByPath(this.artwork, path);
    },
    metaArtworkAuthor() {
      const path = seo_metadata?.artwork_author || 'authors_notice';
      return valueByPath(this.artwork, path);
    },
    metaArtworkDate() {
      const path = seo_metadata?.artwork_date || 'date_creation';
      return valueByPath(this.artwork, path);
    },
    metaArtworkDescription() {
      const path = seo_metadata?.artwork_description || 'domain_description_mst';
      return valueByPath(this.artwork, path);
    },
    metaNoteAuthor() {
      const path = seo_metadata?.note_author || 'author';
      return valueByPath(this.textNote, `note_properties.${path}`);
    },
    metaNoteTitle() {
      const path = seo_metadata?.note_title || 'title';
      return removeMD(valueByPath(this.textNote, `note_properties.${path}`));
    },
    metaMovementsTitle() {
      return movementParameters.title[this.$language.current];
    },
    metaMovementTitle() {
      const path = seo_metadata?.movement_title || 'title';
      return valueByPath(this.movement, path);
    },
  },
};
