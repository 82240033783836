import { SHORT_STATUSES, STATUSES } from '../util/index';

export default {
  methods: {
    textNoteStatus(textNote, short) {
      if (textNote.status) {
        const isExternal = textNote.external_id ? 'external' : false;

        const status = [textNote.status, isExternal].filter(Boolean).join('_');

        if (short) {
          return SHORT_STATUSES[status];
        } else {
          return this.$gettext(STATUSES[status]);
        }
      }
    },
  },
};
