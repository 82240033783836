<template>
  <a
    href
    @click.stop.prevent="onToggleOperator"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    v-b-tooltip.hover.left
    :title="tooltipText"
    :class="filter.operator"
    class="toggle-operator"
    v-if="/term|category_query|after|before|range|query/.test(filter.type)"
  >
    <span>
      {{ opratorText }}
    </span>
  </a>
</template>

<script>
import FilterBuilder from 'lib/filterBuilder';
import { mapGetters } from 'vuex';

export default {
  name: 'FilterToggleOperator',
  props: ['filter', 'index'],
  methods: {
    onToggleOperator() {
      const toToggle = this.locationFilters[this.index];
      toToggle.operator = toToggle.operator === 'and' ? 'not_and' : 'and';

      const query = this.$route.query;

      this.$router.push({
        query: { ...query, page: 1 },
        params: { filters: FilterBuilder.toURLParam(this.locationFilters) },
      });
    },
  },
  computed: {
    ...mapGetters('params', ['locationFilters']),
    isAnd() {
      return this.filter.operator === 'and';
    },
    opratorText() {
      if (this.hover) {
        return this.isAnd ? this.$gettext('sauf') : this.$gettext('et');
      }
      return this.isAnd ? this.$gettext('et') : this.$gettext('sauf');
    },
    tooltipText() {
      return this.isAnd ? this.$gettext('Changer en SAUF') : this.$gettext('Changer en ET');
    },
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>
<style lang="less">
.toggle-operator {
  border-right: 3px solid @silver-chalice;
  padding: 0px !important;
  position: absolute;
  left: -35px;
  width: 30px;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  min-height: 20px;

  &:hover {
    border-right-color: @golden-sand;
    span {
      background-color: @golden-sand;
    }
  }

  span {
    background-color: @silver-chalice;
    font-size: 10px;
    line-height: 1.2;
    padding-left: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: white;
    text-transform: uppercase;
  }
}
</style>
