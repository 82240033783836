<template>
  <b-modal id="printModal" ref="printModal" size="md" hide-footer hide-header lazy modal-class="p-0">
    <translate>Votre PDF est calculé. Cliquer sur Ok pour le télécharger.</translate>
    <div class="select-all-moda-buttons">
      <button class="btn btn-defualt" @click="open()" v-translate>Ok</button>
      <button class="btn btn-defualt" @click="hide()" v-translate>Annuler</button>
    </div>
  </b-modal>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'PrintModal',
  methods: {
    ...mapMutations('artworks', ['assign']),
    hide() {
      this.$refs.printModal.hide();
    },
    open() {
      window.open(this.printLink, '_blank');
      this.assign({ name: 'printLink', data: null });
      this.$refs.printModal.hide();
    },
  },
  computed: {
    ...mapState('artworks', ['printLink']),
  },
};
</script>
