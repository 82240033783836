<template>
  <div class="reference">
    <div class="input-group d-flex justify-content-between">
      <input
        @input="checkArtwork"
        v-model="reference.id"
        class="form-control col-6"
        :class="{ 'header-element': isNonSeparable }"
        type="text"
        name="references"
        required
      />

      <select v-model="reference.element_id" class="form-control element-control col-6" v-if="isNonseparableElement">
        <option v-for="r in related" :value="r.id" :key="r.id">{{ r.label }}</option>
      </select>

      <div class="input-group-append">
        <a href class="icon-trash" v-show="showRemove && !isCartType" @click.stop.prevent="remove"></a>
        <a href class="icon-trash addon-hidden" v-show="!showRemove"></a>
      </div>
    </div>

    <div class="help-block row w-100" v-show="isNonSeparable || isSeparable">
      <div :class="{ 'col-md-6': isNonSeparable, 'col-md-2': isSeparable }">
        <label class="radio-inline">
          <input type="radio" v-model="reference.ua_name" @change="toggleHeader" value="header_artwork" v-translate />
          sur récape
        </label>
      </div>
      <div class="col-md-6">
        <label class="radio-inline">
          <input type="radio" v-model="reference.ua_name" @change="toggleHeader" value="element" v-translate /> sur
          fiche liée
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import Artwork from 'api/artwork';
import cloneDeep from 'lodash.clonedeep';

export default {
  props: {
    artwork: Object,
    reference: Object,
    isCartType: Boolean,
    index: Number,
  },
  mounted() {
    this.elements = cloneDeep(this.artwork.elements);
  },
  computed: {
    related() {
      return this.elements.map((e) => {
        const { inventory, title_list, _id: id } = e;
        return {
          label: `Inv.: ${inventory}, ${title_list}`,
          id,
        };
      });
    },
    isNonseparableElement() {
      return this.isNonSeparable && this.isElement;
    },
    isSeparableElement() {
      return this.isSeparable && this.isElement;
    },
    isElement() {
      return this.reference.ua_name === 'element';
    },
    isNonSeparable() {
      return this.reference.ref_type === 'nonseparable';
    },
    isSeparable() {
      return this.reference.ref_type === 'separable';
    },
    showRemove() {
      return this.index !== 0;
    },
  },
  methods: {
    toggleHeader() {
      this.$emit('toggleHeader');
    },
    remove() {
      this.$emit('removeReference', this.reference);
    },
    async checkArtwork() {
      try {
        const data = await Artwork.read({
          id: this.reference.id,
          config: {
            validateStatus: function (status) {
              return true;
            },
          },
        });
        const { type, elements } = data;
        this.elements = elements || [];
        this.$set(this.reference, 'ref_type', type);

        if (type === 'separable' || type === 'nonseparable') {
          this.$set(this.reference, 'ua_name', 'header_artwork');
        } else {
          this.$set(this.reference, 'ua_name', this.reference.element_id ? 'element' : 'artwork');
        }
      } catch (e) {
        this.elements = [];
      }
    },
  },
  data() {
    return {
      elements: [],
    };
  },
};
</script>
<style lang="less">
.reference {
  .header-element {
    background-color: #eeeeee;
    border: 1px solid #ccc;
  }
}
</style>
