<template>
  <a
    href="#"
    @click.prevent.stop="print()"
    class="print"
    v-hide-on-mobile
    v-b-tooltip.hover.left
    :title="title || titles.print"
  >
    <i class="icon-imprimer" v-if="isIconLink"></i>
    <slot></slot>

    <PrintGeneralModal
      :link="link"
      :short="short"
      :resource="artwork"
      v-model="modalShow"
      :onClose="onClose"
      v-if="link !== 'facets'"
    />
  </a>
</template>
<script>
import { printLimit } from 'config';
import { mapGetters, mapMutations } from 'vuex';
import PrintGeneralModal from './PrintGeneralModal.vue';
import appUrl from 'lib/appUrl';
import { v4 as uuidv4 } from 'uuid';
import qs from 'qs';
import toastr from 'mixins/toastrMixin.js';
import { HTTP } from '../util';

export default {
  name: 'PrintLink',
  props: {
    title: String,
    link: String,
    artwork: Object,
    short: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [toastr],
  components: {
    PrintGeneralModal,
  },
  mounted() {
    const { id } = this.$el;
    if (this.$slots.default && id) {
      this.$root.$emit('bv::disable::tooltip', id);
    }
  },
  data() {
    return {
      modalShow: false,
    };
  },
  computed: {
    ...mapGetters('aggregations', ['aggregation']),
    ...mapGetters('params', ['termOrder', 'artworkApi']),
    ...mapGetters('autocomplete', ['query']),
    ...mapGetters('artworks', ['filteredCount', 'totalCount']),
    referrer() {
      const { path } = this.$router.currentRoute;
      return `${appUrl}${path}`;
    },
    isIconLink() {
      return !this.$slots.default;
    },
  },
  methods: {
    ...mapMutations('artworks', ['assign']),
    subscribe(id) {
      this.$root.fayeClient.subscribe('/' + id, (data) => {
        this.assign({ name: 'printLink', data });
        this.$toastr('clear', '*');
        this.$root.$emit('bv::show::modal', 'printModal');
      });
    },
    facetsUrl() {
      const referrer = encodeURIComponent(this.referrer);
      const {
        meta: { endpoint = 'artworks' },
      } = this.$router.currentRoute;
      const term_word = this.query;
      const term = this.aggregation;

      let params = {
        size: 0,
        term_size: 10000,
        referrer: referrer,
        id: uuidv4(),
        term,
        term_word,
        term_order: this.termOrder,
        filters: this.artworkApi.filters,
      };

      this.subscribe(params.id);

      return `/${endpoint}.pdf?${qs.stringify(params)}`;
    },
    onClose() {
      this.modalShow = false;
    },

    print() {
      switch (this.link) {
        case 'facets':
          this.toastr('printPdf');
          const url = this.facetsUrl();
          HTTP.get(url);
          break;
        case 'artworks':
          if (
            (this.totalCount === this.filteredCount && this.filteredCount > printLimit) ||
            (this.totalCount !== this.filteredCount && this.filteredCount > printLimit)
          ) {
            this.toastr('printLimit', { limit: printLimit });
            return;
          }
          this.modalShow = true;
          break;
        case 'cart':
          if (this.artwork.artwork_ids_length > printLimit) {
            this.toastr('printLimit', { limit: printLimit });
            return;
          }
          this.modalShow = true;
          break;
        case 'artwork':
          this.modalShow = true;
          break;
      }
    },
    titles() {
      return {
        print: this.$gettext('Imprimer'),
      };
    },
  },
};
</script>
