'use strict';

import { ArtworkAttributes, ElementList, ResultPrefix } from 'config';
import valueByPath from '../util/valueByPath';

export default {
  artwork: function (result, isElement) {
    let artworkResult;
    if (isElement) {
      artworkResult = valueByPath(result, ArtworkAttributes);
      artworkResult.id = result.ua.artwork._id;
    } else {
      artworkResult = valueByPath(result, ResultPrefix + ArtworkAttributes);
      artworkResult.id = result._source.ua.artwork._id;
    }
    return artworkResult;
  },

  medias: function (result, isElement) {
    const MediaList = `${ArtworkAttributes}.medias`;
    if (isElement) {
      return valueByPath(result, MediaList);
    } else {
      return valueByPath(result, ResultPrefix + MediaList);
    }
  },

  elements: function (result) {
    return valueByPath(result, ElementList);
  },

  authors: function (result, isElement) {
    let path = isElement ? 'ua.authors' : ResultPrefix + 'ua.authors';
    return valueByPath(result, path);
  },
};
