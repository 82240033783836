<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import ArtworkImage from './ArtworkImage.vue';
import FavoriteLink from './FavoriteLink.vue';
import ArtworkMediaLegend from './ArtworkMediaLegend.vue';
import TooltipEllipses from './TooltipEllipses.vue';
import ArtworkGridDetailsEllipsis from './ArtworkGridDetailsEllipsis.vue';
import closestValue from 'lib/closestValue';
import { HideIfEmpty } from 'directives/HideIfEmpty';
import shareMixin from 'mixins/shareMixin.js';
import { partialStrings } from 'config';
import template from '../../views/partials/artworks/grid.html';
import FilterBuilder from 'lib/filterBuilder';

export default {
  name: 'artwork-grid',
  props: {
    artwork: {
      type: Object,
      required: true,
    },
  },
  template,
  directives: {
    HideIfEmpty,
  },
  components: {
    ArtworkImage,
    FavoriteLink,
    ArtworkGridDetailsEllipsis,
    TooltipEllipses,
    ArtworkMediaLegend,
  },
  mixins: [shareMixin],
  mounted() {
    this.maxHeight =
      this.$refs.content.offsetHeight -
      this.$refs.author.offsetHeight -
      this.$refs.textNoteDetails.offsetHeight -
      this.$refs.bottomDetails.offsetHeight;
    this.scrollHeight = this.$refs.topDetails.scrollHeight;
  },
  computed: {
    ...mapGetters('carts', ['activeCart']),
    ...mapGetters('artworks', ['copyright']),
    ...mapGetters('params', ['filters']),
    ...mapState('auth', ['currentUser']),
    slug() {
      return this.artwork.slug;
    },
    filtersQuery() {
      return FilterBuilder.toQueryParam(this.filters);
    },
    isStarred() {
      if (!this.activeCart) return false;

      return this.activeCart.artwork_ids.includes(this.artwork.id);
    },
    limitedImages() {
      return this.mediaImage.slice(0, 4);
    },
    mediaImage() {
      if (!this.artwork.medias) {
        return [];
      }

      return this.artwork.medias.filter((i) => i.type === 'image');
    },
    mediaVideoAudio() {
      if (!this.artwork.medias) {
        return [];
      }

      return this.artwork.medias.filter((i) => i.type !== 'image');
    },
    fullCopyright() {
      return this.copyright(this.artwork.id);
    },
    boxWidth() {
      return parseInt(this.mediaImage[0].box_width, 10) + 20;
    },
    textNoteHeaderArtwork() {
      const { text_notes } = this.artwork;
      return text_notes && text_notes.header_artwork && text_notes.header_artwork[0];
    },
    textNoteArtwork() {
      const { text_notes } = this.artwork;
      return text_notes && text_notes.artwork && text_notes.artwork[0];
    },
    textNoteArtist() {
      const { text_notes } = this.artwork;
      return text_notes && text_notes.artist && text_notes.artist[0];
    },
    textNoteHeaderArtist() {
      const { text_notes } = this.artwork;
      return text_notes && text_notes.header_artist && text_notes.header_artist[0];
    },
    textNotesThematic() {
      const { text_notes } = this.artwork;
      return text_notes ? text_notes.thema01 || [] : [];
    },

    mediaVersion() {
      return closestValue(this.mediaImage[0])[1];
    },
    thumbIconClass() {
      return {
        'fa-music': this.artwork.medias.filter((m) => m.type === 'audio' && m.header_aggregate === false).length > 0,
        'fa-video-camera':
          this.artwork.medias.filter((m) => m.type === 'video' && m.header_aggregate === false).length > 0,
      };
    },
  },
  methods: {
    ...mapMutations('artworks', ['assign']),
    isInternal(artwork) {
      return artwork.internal;
    },
  },
  watch: {
    maxHeight(newVal, oldVal) {
      if (this.scrollHeight > this.maxHeight && !this.ellipsis) {
        this.ellipsis = true;
        this.$refs.topDetails.style.overflow = 'hidden';
        this.$refs.topDetails.style.height = `${this.maxHeight - 34}px`;
      }
    },
  },
  data() {
    return {
      partial: partialStrings,
      maxHeight: 0,
      scrollHeight: 0,
      ellipsis: false,
    };
  },
};
</script>

<style lang="less">
.art-item {
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0px 15px 15px 15px;
  color: @default-link-hover-color;

  .copyright {
    .ellipsis;
    font-size: 10px;
    color: @grid-item-copyright-color;
    padding: 5px 10px;
    height: 24px;
  }

  .left-side {
    .art-image-content {
      padding: 3px 10px 10px 10px;
      height: 294px;
      background-color: @grid-item-left-background;
      position: relative;
      display: flex;
      flex-direction: column;

      .work-type {
        .ellipsis;
        color: @grid-item-work-type-color;
        font-size: 12px;
        min-height: 17px;
      }

      .art-item-image {
        width: 100%;
        padding: 0;
        margin: 0;

        img {
          vertical-align: middle;
          width: 100%;
          -moz-appearance: checkbox-container;
        }
      }
    }
  }

  .right-side {
    width: 203px;
    position: relative;
    height: 294px;
    word-wrap: break-word;
    background-color: @grid-item-right-background;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;

    ul {
      list-style: none;
      margin-bottom: 0px;
      padding-left: 0px;

      li {
        padding: 10px;
        border-bottom: 1px solid @grid-item-separator-line;
        font-size: 12px;
        position: relative;

        span {
          display: inline-block;
        }
      }

      li:last-child {
        border-bottom: none;
      }
    }

    .details-ellipsis {
      background-color: @grid-item-details-ellipsis-background;
      width: 100%;
      padding: 0 10px;
      font-size: 16px;

      span {
        letter-spacing: 5px;
        padding: 0px 0px 0px 5px;
      }
      span:before {
        content: '[';
      }
      span:after {
        content: ']';
      }
    }

    .top-details {
      position: relative;
    }

    .bottom-details {
      margin-top: auto;
      li {
        line-height: 1;
      }

      .ellipsis {
        width: 90%;
      }
    }

    h2 {
      font-size: 16px;
      margin: 0;
      font-weight: 700;
      position: relative;
      padding: 10px;
      border-bottom: 1px solid @grid-item-separator-line;
      .ellipsis {
        width: 90%;
        display: inline-block;
      }
    }

    h3 {
      font-weight: 400;
      font-size: 16px;
      margin: 0;
    }

    h3 + p {
      margin-top: 10px;
    }

    p {
      margin: 0px;
    }

    a.add-favourite {
      width: 34px;
      height: 34px;
      display: block;
      text-align: center;
      position: absolute;
      right: 0;
      top: 2px;
    }

    a.share {
      width: 34px;
      height: 34px;
      display: block;
      text-align: center;
      position: absolute;
      right: 0;
      top: 2px;
    }

    .other-images {
      margin: 5px 10px 10px 10px;
      min-height: 60px;
      padding: 0px;
      color: @grid-item-other-images-color;

      img {
        margin: 5px 5px 0 0;
        display: block;
        line-height: 1;
        max-height: 50px;
        max-width: 45px;
      }

      .image {
        display: table-cell;
        vertical-align: bottom;
      }
    }
  }
}

.left-side .art-image-content {
  border: 3px solid @mercury;
  border-right: none;
}

.right-side {
  border: 3px solid @mercury;
}

.box-starred .left-side .art-image-content {
  border: 3px solid @chalky;
  border-right: none;
}

.box-starred .right-side {
  border: 3px solid @chalky;
}

.left-side + .right-side {
  border-left: 1px solid @grid-item-separator-line;
}

.no-image-content {
  background-color: #000;
  min-width: 100px;
  position: relative;
  flex-grow: 1;
}
</style>
