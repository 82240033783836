<template>
  <div class="single-filter tree">
    <div class="search tree-search">
      <div class="input-tree-search-wrap">
        <input class="form-control" type="text" v-model="termWord" @input="search" v-focus />
        <div class="search-actions">
          <i class="fa fa-search"></i>
        </div>
      </div>
      <div class="tree-buttons">
        <a href="#" @click.stop.prevent="toggleAll()" class="btn btn-round">
          <translate v-show="expandAll">Tout replier</translate>
          <translate v-show="!expandAll">Tout déplier</translate>
        </a>
      </div>
      <div class="expand" v-if="!modalOpen">
        <div class="expand-icon">
          <a href @click.stop.prevent v-b-modal.filterModal>
            <i class="icon-expand_viewPORT"></i>
          </a>
        </div>
      </div>
      <div class="expand" v-else>
        <a class="tree-modal-close" @click.stop.prevent="close()">
          <i class="icon-CLOSE"></i>
        </a>
      </div>
    </div>
    <div class="filter-group">
      <ul>
        <filter-item
          v-for="(term, index) in terms"
          :model="term"
          :key="index"
          :expandAll="expandAll"
          :class="{ odd: !(index % 2) }"
          class="item filter-item no-tree"
        />
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import FilterItem from './FilterItem.vue';

export default {
  name: 'FilterTree',
  components: {
    FilterItem,
  },
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('aggregations', ['terms', 'aggregation']),
    termWord: {
      get() {
        return this.$store.state.autocomplete.query;
      },
      set(value) {
        this.$store.commit('autocomplete/UPDATE_QUERY', value);
      },
    },
  },
  methods: {
    toggleAll() {
      this.$nextTick(() => {
        this.expandAll = !this.expandAll;
      });
    },
    search() {
      this.$nextTick(() => {
        this.expandAll = false;
        this.$emit('search');
      });
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'filterModal');
    },
  },
  watch: {
    aggregation(newValue, oldValue) {
      if (oldValue !== newValue) {
        this.expandAll = false;
      }
    },
  },
  data() {
    return {
      expandAll: false,
    };
  },
};
</script>
