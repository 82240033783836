<template>
  <a href="" :class="className" @click.prevent="openAuthNetwork">
    <slot></slot>
  </a>
</template>

<script>
import { oauthBaseUrl, vault, loginOptions } from 'config';

export default {
  name: 'LoginAuthLink',
  props: ['network', 'className'],
  methods: {
    openAuthNetwork() {
      const network = this.network === 'external' ? loginOptions.oauthExternalUrl : this.network;
      const openUrl = `${oauthBaseUrl}users/auth/${network}?vault=${vault}`;
      return window.open(openUrl, 'Authenticate Account', 'width=500, height=500');
    },
  },
};
</script>
