<template>
  <div class="modal-body">
    <div class="share-header">
      <form>
        <div class="row">
          <div class="col-md-7">
            <h2 v-translate>Lien</h2>
            <div v-if="true || params" class="share-context">
              <label class="radio-inline">
                <input type="radio" name="context" :value="true" v-model="withContext" />
                <span v-translate>avec contexte de la recherche</span>
              </label>
              <label class="radio-inline">
                <input type="radio" name="context" :value="false" v-model="withContext" />
                <span v-translate>oeuvre seule</span>
              </label>
            </div>
          </div>
          <div class="col-md-5 d-none d-md-block">
            <h2 v-translate>Réseaux sociaux</h2>
          </div>
        </div>
      </form>
    </div>
    <div class="share-url-container">
      <div class="row">
        <div class="col-md-7">
          <p class="share-url">
            <a :href="url">
              <i class="fa fa-link"></i>
            </a>
            {{ url }}
          </p>
        </div>
        <div class="col-md-5">
          <h2 class="d-md-none" v-translate>Réseaux sociaux</h2>
          <ul class="share-buttons">
            <li class="pinterest-box" v-if="share.pinterest">
              <a class="fa fa-pinterest fa-2x" @click.prevent="shareOnPinterest()"></a>
            </li>
            <li class="twitter-box" v-if="share.twitter">
              <a class="fa fa-twitter fa-2x" @click.prevent="shareOnTwitter()"></a>
            </li>
            <li class="facebook-box" v-if="share.facebook">
              <a class="fa fa-facebook fa-2x" @click.prevent="shareOnFacebook()"></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="share-footer">
      <div class="row">
        <div class="col-md-7">
          <button
            ref="copyBtn"
            class="btn btn-defualt pull-left"
            v-clipboard:copy="url"
            v-clipboard:error="clipboardErrorHandler"
            v-clipboard:success="clipboardSuccessHandler"
            v-translate
          >
            copier l'URL
          </button>
        </div>
        <div class="col-md-5">
          <button
            class="btn btn-defualt modal-close pull-right"
            @click="$root.$emit('bv::hide::modal', 'shareModal')"
            v-translate
          >
            fermer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUrl from 'lib/appUrl.js';
import { urlPrefix, share } from 'config';
import qs from 'qs';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'ShareLinkModal',
  computed: {
    ...mapGetters('params', ['textNoteFilters']),
    ...mapState('artworks', ['shareArtwork', 'images']),
    shareWithPrefixUrl() {
      return `${appUrl}${urlPrefix}`;
    },
    path() {
      const currentRoute = this.$router.currentRoute;

      if (currentRoute.name === 'Artworks' && this.shareArtwork) {
        let basePath = `artwork/${this.shareArtwork.id}`;
        if (this.withContext) {
          return `${basePath}${qs.stringify(currentRoute.query)}`;
        } else {
          return `${basePath}`;
        }
      } else if (currentRoute.name === 'Artworks') {
        let basePath = `artworks?`;
        if (this.withContext) {
          return `${basePath}${qs.stringify(currentRoute.query)}`;
        } else {
          const { aggregation, term } = this.textNoteFilters[0];
          const filters = `${aggregation}:${term[0]}`;
          const query = { filters, show_text_note: true };
          return `${basePath}${qs.stringify(query)}`;
        }
      }

      if (this.withContext) {
        return currentRoute.fullPath;
      } else {
        return currentRoute.path;
      }
    },
    url() {
      const path = this.path.startsWith('/') ? this.path.substring(1) : this.path;
      return this.shareWithPrefixUrl + path;
    },
  },
  methods: {
    clipboardSuccessHandler() {
      this.$refs.copyBtn.classList.add('btn-success');
    },
    clipboardErrorHandler() {
      this.$refs.copyBtn.classList.add('btn-danger');
    },
    mapQueryParams(opts) {
      let r = [];
      for (let opt in opts) {
        if (!opts.hasOwnProperty(opt)) {
          continue;
        }
        r.push(opt + '=' + encodeURIComponent(opts[opt]));
      }

      return r;
    },

    shareOnPinterest() {
      const title = `${this.shareArtwork.authors_notice} - ${this.shareArtwork.title_notice}`;
      const image = this.shareArtwork?.images?.filter((i) => i.type === 'image')[0];
      const media = image ? `https://images.navigart.fr/1000/${image.file_name}` : '';
      const endpoint = 'https://pinterest.com/pin/create/button/?url=@url&media=@media&description=@title'
        .replace(/@url/g, encodeURIComponent(this.url))
        .replace(/@title/g, encodeURIComponent(title))
        .replace(/@media/g, media);

      window.open(endpoint, 'pinterest-share-dialog', 'width=626,height=436');
      return false;
    },

    shareOnFacebook() {
      const endpoint = 'https://www.facebook.com/sharer/sharer.php?';
      const opts = {
        u: this.url,
      };

      window.open(`${endpoint}${this.mapQueryParams(opts).join('&')}`, 'facebook-share-dialog', 'width=626,height=436');
      return false;
    },

    shareOnTwitter() {
      const endpoint = 'https://twitter.com/intent/tweet?';
      const opts = {
        url: this.url,
        text: share.twitter.text,
        via: share.twitter.via,
      };

      window.open(`${endpoint}${this.mapQueryParams(opts).join('&')}`, 'twitter-share-dialog', 'width=626,height=436');
      return false;
    },
  },
  data() {
    return {
      withContext: true,
      share,
    };
  },
};
</script>
