import { vault } from 'config';

export default {
  showSummary: (state) => (!state.showTextNoteSummary === false ? true : false),
  showTitle: (state) => (!state.showTextNoteTitle === false ? true : false),
  preferences: (state, _, rootState) => {
    let localPreferences = state.preferences[vault];
    const { profile } = rootState.auth.currentUser;
    if (profile && profile[vault]) {
      return profile[vault];
    }
    return localPreferences;
  },
};
