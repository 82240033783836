import config from 'config';
import axios from 'axios';
import router from '../router';
import store from '../store';
import appUrl from 'lib/appUrl';

import { sessionStorageIsSupported, localStorageIsSupported } from './storage';

export const HTTP = axios.create({
  baseURL: config.baseUrl,
});

HTTP.interceptors.request.use(
  (config) => {
    let token;
    if (sessionStorageIsSupported() || localStorageIsSupported()) {
      token = sessionStorage.getItem('token') || localStorage.getItem('token');
    }

    if (token) {
      config.headers['X-Token'] = token;
    }
    store.commit('local/INCREMENT_ACTIVE_REQUESTS');
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

HTTP.interceptors.response.use(
  (response) => {
    store.commit('local/DECREMENT_ACTIVE_REQUESTS');
    return response;
  },

  (error) => {
    const { response } = error;

    if (!sessionStorageIsSupported() || !localStorageIsSupported()) {
      router.push('/cookies_required');
      store.commit('local/DECREMENT_ACTIVE_REQUESTS');
      return Promise.reject(error);
    }

    if (response) {
      if ([401].includes(response.status)) {
        sessionStorage.removeItem('token');
        localStorage.removeItem('token');

        if (response.data.error == 'Session expired') {
          router.push({ name: 'login', query: { sessionExpired: true } });
        } else if (response.data.error[0] === 'Unconfirmed email') {
          router.push({ name: 'Unconfirmed', params: { email: response.data.error[1] } });
        } else {
          router.push({ name: 'login' });
        }
      }
      if ([403].includes(response.status)) {
        router.push('/request_access');
      }

      if ([404].includes(response.status)) {
        router.push('/404');
      }
    }

    store.commit('local/DECREMENT_ACTIVE_REQUESTS');
    return Promise.reject(error);
  }
);

export const NO_CACHE_HEADER = {
  headers: { 'Cache-Control': 'no-cache' },
};

export const SHORT_STATUSES = {
  draft: 'C0',
  draft_external: 'M0',
  publish_requested: 'C1',
  publish_requested_external: 'C2',
};

export const STATUSES = {
  draft: 'création en cours',
  draft_external: 'publiée + modif en cours',
  publish_requested: 'création en cours : demande de publication en cours',
  publish_requested_external: 'publiée + modif en cours : demande de publication en cours',
};

export const TYPE_LABELS = {
  artwork: 'Œuvre(s)',
  artist: 'Artiste',
  cart: 'Thématique',
  movement: 'Mouvement',
  default: 'unknown type',
};

export const STATUS_LABELS = {
  draft_external: 'Modification en cours',
  publish_requested: 'En attente de publication',
  unpublish_requested: 'En attente de dépublication',
  draft: '',
};

export const ACTION_LABELS = {
  publish_requested: 'Annuler la demande de publication',
  unpublish_requested: 'Annuler la demande de dépublication',
  published: 'Dépublier',
  draft: 'Publier',
};

export const noteLink = (note) => {
  const properties = note.note_properties;
  const isPublished = note.status === 'published';
  let docId;
  let indexPath = '';
  let docPath = 'artwork/';
  const { type, doc_ref = {}, external_id } = properties;

  const { ref_id, ua_name, element_id, element_index } = doc_ref;
  docId = isPublished && external_id ? external_id : ref_id;

  if (ua_name === 'element' && element_id) {
    indexPath = `&index=${element_index}`;
  } else if (type === 'movement') {
    return `${appUrl}movements/map?note=${note.id}&id=${docId}`;
  }

  return `${appUrl}${docPath}${docId}/note/${note.id}${indexPath}`;
};
