import Vue from 'vue';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import * as VueGoogleMaps from 'vue2-google-maps';
import GetTextPlugin from 'vue-gettext';
import { TooltipPlugin, ModalPlugin, DropdownPlugin, FormSelectPlugin } from 'bootstrap-vue';
import translations from './translation.json';
import VueIntro from 'vue-introjs';
import VueClipboard from 'vue-clipboard2';
import VueToastr from '@deveodk/vue-toastr';
import VueTextareaAutosize from 'vue-textarea-autosize';
import LoadScript from 'vue-plugin-load-script';
import Vue2TouchEvents from 'vue2-touch-events';

import { fayeServerUrl, extractLength, analytics } from 'config';
import VueMatomo from 'vue-matomo';

import { sync } from 'vuex-router-sync';
import router from './router';
import store from './store';

import './lib/supplant';
import supplantLink from './lib/supplantLink';

import { isMobile } from './util/isMobile';
import './components/svg';
import App from './components/App.vue';

import VueLazyload from 'vue-lazyload';

Vue.use(VueLazyload, {
  observer: true,
});

import 'intro.js/introjs.css';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import 'simplemde/dist/simplemde.min.css';
import 'font-awesome/css/font-awesome.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@deveodk/vue-toastr/dist/@deveodk/vue-toastr.css';

import '../less/app.less';

const unsync = sync(store, router);
const { server_url: host = 'https://analytics.videomuseum.fr', site_id: siteId = 5 } = analytics || {};

Vue.use(VueMatomo, {
  host,
  siteId,
  router,
  /*debug: true,*/
});

Vue.use(GetTextPlugin, {
  availableLanguages: {
    en: 'en',
    fr: 'fr',
  },
  defaultLanguage: 'fr',
  translations: translations,
  silent: true,
});

Vue.use(VueTextareaAutosize);
Vue.use(PerfectScrollbar);
Vue.use(VueIntro);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAwFr72673wxfffArBTFTbv7_RxO-ahKl0',
  },
});
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(VueToastr, { defaultPosition: 'toast-top-left', defaultType: 'info', defaultTimeout: 55000 });
Vue.use(LoadScript);
Vue.use(Vue2TouchEvents);

Vue.filter('truncate', (text = '', limit = extractLength) => {
  const end = ' [...]';

  if (typeof text !== 'undefined' && text.length - end.length <= limit) {
    return text;
  } else {
    text = String(text).substring(0, limit);

    if (text.length > 0) {
      return text + end;
    }

    return text;
  }
});

Vue.filter('supplantLink', supplantLink);

Vue.filter('number', (value) => {
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
});

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (event.target.classList.contains('loader')) {
        return;
      }

      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

Vue.directive('hide-on-mobile', {
  inserted: function (el, _, vnode) {
    const isMobile = vnode.context.$isMobile();

    if (isMobile) {
      vnode.context.$nextTick(function () {
        el.style.display = 'none';
      });
    }
  },
});

Vue.directive('preview', {
  inserted: function (el, binding, vnode) {
    const parts = el.href.split('/');
    const search = el.href.split('=');
    const [id] = parts.slice(-1);
    let ids;

    if (search[1]) {
      ids = search[1];
    } else {
      ids = id;
    }

    vnode.context.$nextTick(function () {
      let position = { top: `${el.offsetTop + el.offsetHeight}px` };

      const index = store.state.textNotes.previews.length;
      const elementId = `preview${index}`;
      el.setAttribute('id', elementId);

      store.commit('textNotes/ADD_PREVIEW', {
        elementId,
        position,
        ids,
      });

      vnode.context.$root.$on('previewOpened', (preview) => {
        if (preview.elementId === el.id) {
          el.classList.add('unfolded');
        } else {
          el.classList.remove('unfolded');
        }
      });

      const handler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        vnode.context.$root.$emit('openPreview', elementId);
      };

      el.removeEventListener('click', handler);
      el.addEventListener('click', handler);
      el.$destroy = () => {
        el.removeEventListener('click', handler);
      };
    });
  },
  unbind: function (el) {
    el.$destroy();
  },
});

Vue.use(TooltipPlugin);
Vue.use(ModalPlugin);
Vue.use(DropdownPlugin);
Vue.use(FormSelectPlugin);

Vue.prototype.$isMobile = isMobile;

Vue.directive('focus', {
  inserted: function (el, _, vnode) {
    const isMobile = vnode.context.$isMobile();

    if (isMobile) {
      return;
    }

    vnode.context.$nextTick(function () {
      el.focus();
    });
  },
  update: function (el, _, vnode) {
    const isMobile = vnode.context.$isMobile();
    const { value } = _;

    if (isMobile) {
      return;
    }

    if (!value) {
      return;
    }

    vnode.context.$nextTick(function () {
      if (value) el.focus();
      else el.blur();
    });
  },
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    this.$store.commit('local/INIT');
    this.$loadScript(`${fayeServerUrl}.js`).then(() => {
      this.fayeClient = new Faye.Client(fayeServerUrl, { retry: 5 });
      this.fayeClient.disable('websocket');
      this.fayeClient.disable('autodisconnect');
    });
  },
  data: {},
});

window.addEventListener('message', (e) => {
  const data = e.data;

  if (data.token) {
    sessionStorage.setItem('token', data.token);
    router.go();
  }
});
