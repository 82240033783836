import axios from 'axios';
import config from 'config';
const { baseUrl } = config;

export default {
  async fetch() {
    const arr = baseUrl.split('/');
    const vault = arr.pop();
    const url = arr.join('/');
    const HTTP = axios.create({ baseURL: url });

    const { data } = await HTTP.get(`/publications/${vault}`);
    return data;
  },
};
