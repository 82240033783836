export default {
  computed: {
    messages() {
      return {
        noAuth: (_) =>
          this.$gettext(
            'Si vous voulez utiliser les dossiers de sélection, vous devez être connecté (voir menu général)'
          ),
        noCarts: (_) =>
          this.$gettext(
            "Vous n'avez aucun dossier de sélection actif, la sélection d'oeuvres ne peut donc pas fonctionner. <br> Vous devez donc créer d'abord un dossier de sélection."
          ),
        sessionExpired: (_) => this.$gettext('Votre session a expirée, il vous faut vous ré-authentifier.'),
        printPdf: (_) =>
          this.$gettext(
            "Le PDF est en cours de génération. <br> Vous pouvez fermer ce message et continuer à utiliser l'application. <br> Un message vous informera quand le PDF sera disponible."
          ),
        exportXlsx: (_) =>
          this.$gettext(
            "Le XLS est en cours de génération. <br> Vous pouvez fermer ce message et continuer à utiliser l'application. <br> Un message vous informera quand le XLS sera disponible."
          ),
        printLimit: (data) =>
          this.$gettextInterpolate(
            this.$gettext('Cette impression ne peut pas être faite (limite maximum de %{ limit } oeuvres)'),
            data
          ),
        exportLimit: (data) =>
          this.$gettextInterpolate(
            this.$gettext('Cet export ne peut pas être fait (limite maximum de %{ limit } oeuvres)'),
            data
          ),
        internalNoteExists: (data) =>
          this.$gettextInterpolate(
            this.$gettext(
              "La version modifiée de la notice existe déjà ( créée par %{ author } ). Veuillez ouvrir cette version modifiée pour d'autres modifications."
            ),
            data
          ),
        autoGranted: (data) => this.$gettextInterpolate(this.$gettext('%{body}'), data),
        cartSent: (_) =>
          this.$gettext(
            `Le dossier de sélection a bien été envoyé. <br> Et un mail a également été envoyé à votre destinataire pour l'en informer.`
          ),
      };
    },
  },
  methods: {
    toastr(name, data) {
      this.$toastr('info', this.messages[name](data));
    },
  },
};
