export default {
  artworks: (_, getters) => Object.values(getters.artworkPages).reduce((p, c) => ((p = { ...p, ...c }), p), {}),
  artworkPages: (state) =>
    Object.keys(state.artworkPages)
      .sort()
      .reduce((r, k) => ((r[k] = state.artworkPages[k]), r), {}),
  artworksArray: (_, getters) => Object.values(getters.artworks),
  artwork: (state) => state.artwork,
  totalCount: (state) => state.totalCount,
  filteredCount: (state) => state.filteredCount,
  getById: (_, getters) => (id) => {
    const parsedId = id?.split('-').reverse()[0];
    return getters.artworks[parsedId];
  },
  copyright: (_, getters) => (id) => {
    const parsedId = id.split('-').reverse()[0];
    const artwork = getters.getById(parsedId);

    if (!artwork || !artwork.medias || artwork.medias.length === 0) {
      return '';
    }

    const { copyright = '', photographer } = artwork.medias[0];

    if (photographer) {
      return `${copyright}, photo : ${photographer}`;
    } else {
      return copyright;
    }
  },
  loading: (state) => state.loading,
  movement: (state) => state.movement,
  textNote: (state) => state.textNote,
  images: (state) => state.images,
  currentImage: (state) => state.currentImage,
  elements: (state) => state.elements,
  getElementById: (state) => (id) => state.elements.filter((e) => e._id === id)[0],
  isFolded: (state) => state.isFolded,
};
