import { HTTP } from '../util';
import { CancelToken } from 'axios';
import * as qs from 'qs';

let cancel;

export default {
  async search(params) {
    if (cancel != undefined) {
      cancel('Only one request allowed!');
    }

    const { data } = await HTTP.get('autocomplete', {
      params,
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    });

    const { aggregations, full_text, group_1 } = data;

    return { aggregations, group_1, full_text };
  },
};
