<template>
  <div :class="containerClass" id="step9" v-if="pageCount > 1">
    <a href @click.stop.prevent="firstPage" v-if="!firstPageSelected() && showFirst">
      <i class="fa fa-angle-double-up"></i>
    </a>
    <a href class="prev" @click.stop.prevent="prevPage" :class="firstPageSelected() ? 'disabled' : ''">
      <i class="icon-PREVIOUS"></i>
    </a>

    <span class="counter" v-if="!showEditor || !editorEnabled" @click="toggleEditor()"
      >{{ selected }}/{{ pageCount }}</span
    >
    <span v-if="editorEnabled && showEditor">
      <form v-on:submit="setPage(inputSelected)">
        <input
          v-on:blur="setPage(inputSelected)"
          v-model="inputSelected"
          v-focus
          type="text"
          size="10"
          :placeholder="selected + '/' + pageCount"
        />
      </form>
    </span>

    <a href class="next">
      <i class="icon-Next" @click.stop.prevent="nextPage" :class="lastPageSelected() ? 'disabled' : ''"></i>
    </a>
    <slot v-bind:pagination="{ nextPage, prevPage, firstPageSelected, lastPageSelected }"></slot>
  </div>
</template>

<script>
export default {
  name: 'artworks-pagination',
  props: {
    value: {
      type: Number,
      default: 1,
    },
    showEditor: {
      type: Boolean,
      default: false,
    },
    showFirst: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
    },
    perPage: {
      type: Number,
    },
    containerClass: {
      type: String,
    },
    clickHandler: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
    },
    pageCount() {
      return Math.ceil(parseInt(this.total, 10) / parseInt(this.perPage, 10));
    },
  },
  methods: {
    toggleEditor() {
      this.editorEnabled = !this.editorEnabled;
    },
    firstPage() {
      this.clickHandler(1);
    },
    prevPage() {
      if (this.selected <= 1) return;

      this.clickHandler(this.selected - 1);
    },
    nextPage() {
      if (this.selected >= this.pageCount) return;

      this.clickHandler(this.selected + 1);
    },
    firstPageSelected() {
      return this.selected === 1;
    },
    lastPageSelected() {
      return this.selected === this.pageCount || this.pageCount === 0;
    },
    setPage(page) {
      this.editorEnabled = false;
      this.clickHandler(parseInt(page || this.value));
    },
  },
  data() {
    return {
      editorEnabled: false,
      inputSelected: this.selected,
    };
  },
};
</script>

<style scoped lang="less">
a {
  i.fa {
    color: @silver;
  }
}

a:hover {
  i.fa {
    color: @chalky;
  }
}
</style>
