<template>
  <div class="iframe-wrapper" ref="iframeWrapper" v-if="externalMenu && externalMenu.url" :style="sideStyles">
    <iframe ref="iframe" :src="externalMenu.url" height="100%" width="100%" />
  </div>
</template>

<script>
import { externalMenu } from 'config';

export default {
  name: 'ExternalHeader',
  created() {
    const { url } = this.externalMenu;
    if (url) {
      window.addEventListener('message', this.iframeMessage);
    }
  },
  destroyed() {
    const { url } = this.externalMenu;
    if (url) {
      window.removeEventListener('message', this.iframeMessage);
    }
  },
  computed: {
    sideStyles() {
      if (this.externalMenu['position'] === 'left') {
        return {
          order: 0,
        };
      } else if (this.externalMenu['position'] === 'right') {
        return {
          order: 1,
        };
      } else return {};
    },
  },
  methods: {
    iframeMessage(e) {
      const { data } = e;

      if (data.initialWidth) {
        this.initialWidth = data.initialWidth;
        this.$refs.iframeWrapper.style.width = `${data.initialWidth}px`;
        this.$refs.iframeWrapper.style.maxWidth = `${data.initialWidth}px`;
        this.$refs.iframeWrapper.style.minHeight = 'inherit';
        this.$refs.iframeWrapper.style.zIndex = 1000;
        this.$refs.iframe.style.width = `${data.initialWidth}px`;
      }

      if (data.initialHeight) {
        this.$refs.iframeWrapper.style.height = `${data.initialHeight}px`;
      }

      if (data === 'maximize') {
        this.addZIndex(e);
      } else if (data === 'minimize') {
        this.removeZIndex(e);
      }
    },
    addZIndex(e) {
      this.$refs.iframe.style.position = 'absolute';
      this.$refs.iframe.style.zIndex = 10000;
      if (this.externalMenu['position'] !== 'top') {
        this.$refs.iframeWrapper.style.width = `100vw`;
        this.$refs.iframe.style.width = `100vw`;
      }

      if (this.externalMenu['position'] === 'top') {
        this.$refs.iframe.style.height = `100vh`;
      }
    },
    removeZIndex(e) {
      this.$refs.iframe.style.position = 'relative';
      this.$refs.iframe.style.overflow = 'hidden';
      if (this.externalMenu['position'] !== 'top') {
        this.$refs.iframeWrapper.style.width = `${this.initialWidth}px`;
        this.$refs.iframe.style.width = `${this.initialWidth}px`;
      }

      if (this.externalMenu['position'] === 'top') {
        this.$refs.iframe.style.height = `initial`;
      }
    },
  },
  data() {
    return {
      externalMenu: externalMenu || {},
    };
  },
};
</script>

<style lang="less">
.iframe-wrapper {
  flex: 1 0 auto;
  overflow: hidden;
}
iframe {
  border: none;
  margin: 0px;
  border-width: 0px;
}
</style>
