import * as mutations from '../mutation-types';
import Auth from 'api/auth';
import User from 'api/user';
import router from '../../router';

export default {
  async login({ commit, dispatch }, credentials) {
    try {
      const data = await Auth.login(credentials);

      sessionStorage.setItem('token', data.token);
      if (credentials.remember_me) {
        localStorage.setItem('token', data.token);
      }
      commit(mutations.AUTH_SUCCESS, data);
      await dispatch('me');

      if (data.AutoGrantNotifyUser) {
        commit('local/UPDATE_NOTIFY_GRANTED', data.AutoGrantNotifyUser, { root: true });
      }

      let previousPath = sessionStorage.previousPath || '/artworks';
      sessionStorage.removeItem('previousPath');
      router.push(previousPath);
    } catch (e) {
      const { data } = e.response;
      sessionStorage.removeItem('token');
      commit(mutations.AUTH_FAILURE, data.error);
    }
  },

  async register({ commit, dispatch }, credentials) {
    try {
      const data = await Auth.register(credentials);
      sessionStorage.setItem('token', data.token);
      commit(mutations.AUTH_SUCCESS, data);
      await dispatch('me');

      if (data.AutoGrantNotifyUser) {
        commit('local/UPDATE_NOTIFY_GRANTED', data.AutoGrantNotifyUser, { root: true });
      }

      router.push({ name: 'Artworks' });
    } catch (e) {
      const { data } = e.response;
      commit(mutations.AUTH_FAILURE, data.error);
    }
  },

  async me({ commit, dispatch }) {
    try {
      const me = await Auth.me();
      commit(mutations.AUTH_USER, me);
      await dispatch('carts/getAll', null, { root: true });
      await dispatch('carts/getActive', null, { root: true });
      return me;
    } catch (e) {
      console.log(e);
      //
    }
  },

  async update({ commit }, params) {
    const me = await Auth.update(params);
    commit(mutations.UPDATE_NAMES, me);
  },

  async updatePreferences({ commit }, params) {
    const me = await User.updateProfile(params);
    commit(mutations.UPDATE_PROFILE, me);
  },

  logout({ commit }) {
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    commit(mutations.AUTH_LOGOUT);
    commit('carts/CARTS_RESET', null, { root: true });
  },

  clearErrors({ commit }) {
    commit(mutations.AUTH_CLEAR_ERRORS);
  },
};
