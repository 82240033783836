import './supplant';
import appUrl from './appUrl';
import { urlPrefix, shareSingleWorkPrefix, shareMultipleWorksPrefix } from 'config';

const supplantOptions = {
  shareBaseUrl: `${appUrl}${urlPrefix}`,
  shareBaseURL: `${appUrl}${urlPrefix}`,
  shareSingleWorkPrefix: shareSingleWorkPrefix,
  shareMultipleWorksPrefix: shareMultipleWorksPrefix,
};

export default function supplantLink(value) {
  if (typeof value === 'undefined') {
    return value;
  } else {
    return value.supplant(supplantOptions);
  }
}
