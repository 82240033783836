export default {
  all: (state) => {
    return state.all
      .slice()
      .sort((a, b) => a.id - b.id)
      .map((cart) => {
        cart.artwork_ids_length = cart.es_count;
        return cart;
      });
  },
  exportSummary: (state) => state.exportSummary,
  importSummary: (state) => state.importSummary,
  unselectAllStatus: (state) => state.unselectAllStatus,
  displayedCart: (state, getters, rootState, rootGetters) => {
    if (!rootGetters['params/favorites']) {
      return null;
    }
    return state.all.find((c) => c.id === parseInt(rootGetters['params/favorites']));
  },
  activeCart: (state) => {
    if (!state.activeCart) {
      return null;
    }
    return state.all.find((c) => c.id === state.activeCart.id);
  },
};
