<script>
import template from '../../views/partials/artworks/grid-tooltip.html';

export default {
  name: 'ArtworkGridTooltip',
  template,
  props: {
    artwork: {
      type: Object,
      required: true,
    },
    refs: {
      type: Object,
      required: true,
    },
  },
};
</script>
