<template>
  <div class="checkboxes">
    <div :key="index" v-for="(checkbox, index) in orderedCheckboxes" class="checkbox-item">
      <input
        type="checkbox"
        class="checkbox"
        v-bind:id="'checkbox-' + index"
        v-on:change="toggleCheckbox(checkbox)"
        v-bind:value="checkbox.key"
        v-model="checked"
      />
      <label class="checkbox-label" v-bind:for="'checkbox-' + index"
        >{{ checkbox.term }} [{{ checkbox.doc_count | number }}]</label
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FilterBuilder from 'lib/filterBuilder';

export default {
  name: 'filter-checkboxes',
  computed: {
    ...mapGetters('aggregations', ['checkboxes']),
    ...mapGetters('params', ['anyFilterApplied', 'filters', 'locationFilters']),
    orderedCheckboxes() {
      return this.checkboxes.sort((c1, c2) => (c1.checkbox_pos > c2.checkbox_pos ? 1 : -1));
    },
  },
  mounted() {
    const appliedCheckboxes = this.filters.filter((f) => f.type === 'checkbox').map((f) => f.aggregation);
    this.checked = [...this.checked, ...appliedCheckboxes];
  },
  methods: {
    toggleCheckbox(checkbox) {
      const term = checkbox.term;
      const aggregation = checkbox.key;
      const type = 'checkbox';
      if (this.checked.includes(checkbox.key)) {
        const filters = [{ term: [term], aggregation, type, operator: 'and' }];

        const query = this.$route.query;

        this.$router.push({
          query: { ...query, page: 1 },
          params: { filters: FilterBuilder.toURLParam(this.locationFilters.concat(filters)) },
        });
      } else {
        const filter = this.filters.find((f) => f.aggregation === aggregation);
        const toRemove = this.locationFilters.find((f) => f.aggregation === filter.aggregation);

        if (typeof index === 'number') {
          toRemove.term.splice(index, 1);
        } else {
          const filterIndex = this.locationFilters.indexOf(toRemove);
          this.locationFilters.splice(filterIndex, 1);
        }

        const query = this.$route.query;

        this.$router.push({
          query: { ...query, page: 1 },
          params: { filters: FilterBuilder.toURLParam(this.locationFilters) },
        });
      }
    },
  },
  data() {
    return {
      checked: [],
    };
  },
  watch: {
    anyFilterApplied(newVal, oldVal) {
      if (newVal === null) {
        this.checked = [];
      }
    },
    filters(newVal, oldVal) {
      if (newVal) {
        const appliedCheckboxes = this.filters.filter((f) => f.type === 'checkbox').map((f) => f.aggregation);
        this.checked = [...appliedCheckboxes];
      }
    },
  },
};
</script>

<style scoped lang="less">
.checkboxes {
  display: flex;
  .checkbox {
    display: none;
  }
  .checkbox-item {
    margin-right: 15px;
  }
  .checkbox-item:last-child {
    margin-right: 0px;
  }
  .checkbox-label {
    cursor: pointer;
    position: relative;
    margin: 0;
    color: @black;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .checkbox + .checkbox-label:before {
    content: '';
    width: 10px;
    height: 10px;
    display: inline-block;
    background: @mercury;
    border: 1px solid @boulder;
    margin-right: 10px;
  }
  .checkbox:hover + .checkbox-label:hover,
  .checkbox:checked + .checkbox-label {
    color: @boulder;
  }
  .checkbox:hover + .checkbox-label:before,
  .checkbox:checked + .checkbox-label:before {
    background: @white;
    border: 2px solid @chalky;
  }
  .checkbox:checked + .checkbox-label:after {
    transform: rotate(-45deg);
    content: '';
    position: absolute;
    top: 3px;
    left: 1px;
    width: 10px;
    height: 6px;
    background: transparent;
    border: 2px solid @mine-shaft;
    border-top-style: none;
    border-right-style: none;
  }
}
</style>
