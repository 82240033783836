<template>
  <perfect-scrollbar class="notice-body-wrapper scrollbar scroll-always-visible" :options="scrollOptions" ref="scroll">
    <div class="notice-body">
      <h1 v-show="showTitle">
        <Markdown :input="title"></Markdown>
      </h1>

      <Markdown :input="highlighedContent || textNote.content" :options="{ targetBlank: targetBlank }"></Markdown>

      <br />
      <br />

      <div v-if="authorOrDate">
        <div v-html="author"></div>
        {{ date }}
      </div>

      <div class="related-section" v-if="hasNotQuotedLinks">
        <h3 v-translate>Liens complémentaires</h3>
        <div class="internal" v-if="notQuotedInternalLinks.length > 0">
          <h4 v-translate>Œuvres</h4>
          <ul>
            <li :key="index" v-for="(link, index) in notQuotedInternalLinks">
              <a
                v-preview
                v-bind:id="'nonquoted-internal-link-' + index"
                v-bind:target="targetBlank ? '_blank' : '_self'"
                :href="link.href | supplantLink"
                >{{ link.title }}</a
              >
            </li>
          </ul>
        </div>
        <div class="external" v-if="notQuotedExternalLinks.length > 0">
          <h4 v-translate>Externes</h4>
          <ul>
            <li :key="index" v-for="(link, index) in notQuotedExternalLinks">
              <div>{{ link.title }}</div>
              <a :href="link.href" target="_blank">{{ link.href }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div v-show="showSummary">
        <div class="related-resources" v-if="hasQuotedLinks">
          <div class="head clearfix">
            <h3 v-translate>Récapitulatif des liens mentionnés dans la notice</h3>
          </div>
          <div class="related-section">
            <div v-if="quotedInternalLinks.length > 0">
              <h4 v-translate>Œuvres</h4>
              <a href class="see-all" id="see-all-anchor" @click.stop.prevent="showAllInternal">
                <i class="icon-See"></i>
                <span v-translate>Voir toutes les œuvres mentionnées</span>
                ({{ internalIds.length }})
              </a>
            </div>
            <div class="external" v-if="quotedExternalLinks.length > 0">
              <h4 v-translate>Externes</h4>
              <ul>
                <li :key="index" v-for="(link, index) in quotedExternalLinks">
                  <Markdown :input="link.title"></Markdown>
                  <a :href="link.href | supplantLink" target="_blank">{{ link.href | supplantLink }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Preview />
    </div>
  </perfect-scrollbar>
</template>
<script>
import Markdown from './Markdown.vue';
import Preview from './Preview.vue';
import Footnote from 'directives/Footnote';
import Reversefootnote from 'directives/Reversefootnote';
import VueScrollTo from 'vue-scrollto';
import { mapGetters, mapState, mapMutations } from 'vuex';
import { shareBaseUrl } from 'config';

export default {
  name: 'TextNoteBody',
  props: {
    showSummary: {
      type: Boolean,
      default: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    textNote: Object,
    editing: {
      type: Boolean,
      default: false,
    },
    targetBlank: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Markdown,
    Preview,
  },
  directives: {
    Footnote,
    Reversefootnote,
  },
  destroyed() {
    if (!this.editing) {
      this.assign({ name: 'textNote', data: null });
      this.assign({ name: 'previews', data: [] });
    }
  },
  mounted() {
    if (this.notquotedLinkId) {
      const scrollTo = this.notquotedLinkId;
      VueScrollTo.scrollTo(`#${scrollTo}`, {
        container: '.scrollbar',
        force: true,
        x: false,
        y: true,
        onDone: (element) => {
          this.assign({ name: 'notquotedLinkId', data: null });
        },
      });
    }
  },
  computed: {
    ...mapState('textNotes', ['notquotedLinkId']),
    textNoteProperties() {
      return this.textNote.note_properties || this.textNote;
    },
    date() {
      return this.textNoteProperties.date;
    },
    author() {
      return this.textNoteProperties.author;
    },
    authorOrDate() {
      return this.date || this.author;
    },
    title() {
      return this.textNoteProperties.title;
    },
    notQuotedInternalLinks() {
      return this.textNoteProperties.notquotedlinks_internal || [];
    },
    notQuotedExternalLinks() {
      return this.textNoteProperties.notquotedlinks_external || [];
    },
    quotedInternalLinks() {
      return this.textNoteProperties.quotedlinks_internal || [];
    },
    quotedExternalLinks() {
      return this.textNoteProperties.quotedlinks_external || [];
    },
    hasQuotedLinks() {
      return this.quotedInternalLinks.length || this.quotedExternalLinks.length;
    },
    hasNotQuotedLinks() {
      return this.notQuotedInternalLinks.length || this.notQuotedExternalLinks.length;
    },
    internalIds() {
      const REGEXP = /\}([^}]+)$/;
      let internalIds = [];
      for (let link of [...this.quotedInternalLinks, ...this.notQuotedInternalLinks]) {
        let match = link.href.match(REGEXP);
        if (match) {
          match.shift();
          let ids = match[0].split(',');
          internalIds = [...internalIds, ...ids];
        }
      }
      return internalIds;
    },
    highlighedContent() {
      if (this.textNote.hasOwnProperty('highlight')) {
        return this.textNote.highlight.content[0];
      } else {
        return null;
      }
    },
  },
  methods: {
    ...mapMutations('textNotes', ['assign']),
    showAllInternal(e) {
      const notquotedLinkId = e.currentTarget.id;
      this.assign({ name: 'notquotedLinkId', data: notquotedLinkId });
      this.$router.push({ name: 'Artworks', query: { ids: this.internalIds.join(',') } });
    },
  },
  data() {
    return {
      scrollOptions: {
        suppressScrollX: true,
      },
    };
  },
  watch: {
    textNote(newValue, oldValue) {
      if (newValue != oldValue) {
        this.assign({ name: 'previews', data: [] });
        this.$refs.scroll.$el.scrollTop = 0;
      }
    },
  },
};
</script>
<style lang="less">
.notice-body {
  text-align: justify;
  background: #fff;
  padding: 10% 6% 6% 15%;
  line-height: 1.6;
  .HeaderFont(18px);
  font-weight: normal;
  color: @notice-body-color;
  position: relative;
  word-break: normal;

  .more {
    background-color: @notice-body-more-background;
    color: @notice-body-more-color;
    padding: 0 3px;

    &.unfolded {
      color: @notice-body-more-unfolded-color;
      background-color: @notice-body-more-unfolded-background;
    }
  }

  .underline {
    border-bottom: 1px solid @notice-body-underline-border-color;
    padding-bottom: 4px;
    color: @notice-body-underline-text-color;
  }

  p {
    margin-bottom: 18px;
    padding-bottom: 5px;
  }

  div {
    img {
      max-width: 45%;
      margin-right: 4%;
      margin-bottom: 2%;
      float: left;
    }
  }

  h1 {
    text-align: right;
    margin-bottom: 50px;
  }

  hr {
    border-top: 1px solid #c0c0c0;
  }
}
</style>
