import { HTTP } from '../util';
import { CancelToken } from 'axios';
import * as qs from 'qs';

import ConfigService from '../services/config-service.js';
import { movementParameters } from 'config';
import merge from 'lodash.merge';
let cancel;

export default {
  async fetch(params, endpoint = 'artworks') {
    if (cancel != undefined) {
      cancel('Only one request allowed!');
    }
    const { data } = await HTTP.get(endpoint, {
      params,
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    });

    const { filteredCount, totalCount, aggregations } = data;
    const artworks = data.results.map((h) => extract(endpoint)(h));

    return { artworks, filteredCount, totalCount, aggregations };
  },

  async read({ id, config = {} }, endpoint = 'artworks') {
    const {
      data: { results },
    } = await HTTP.get(`${endpoint}/${id}`, config);

    const records = results.map((h) => extract(endpoint)(h));

    return records[0];
  },

  async fetchRelated({ ids }) {
    const { data } = await HTTP.get('artworks/get_related_by_ids', {
      params: { ids: ids.join(','), size: 10000 },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    });

    const artworks = data.map((h) => extract('artworks')(h));
    return artworks;
  },

  async movementArtworks(movementId) {
    const { thumbnails_max } = movementParameters;
    let params = {
      size: thumbnails_max + 10,
      sort: 'by_author',
      fields: 'medias',
    };

    const {
      data: { results },
    } = await HTTP.get(`/movements/${movementId}/artworks`, {
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    });

    return results
      .reduce((acc, artwork) => {
        let artworkMedia = artwork._source.ua.medias || [];

        let images = artworkMedia.filter((medium) => medium && medium.type === 'image');

        return [...acc, ...images];
      }, [])
      .splice(0, thumbnails_max);
  },
};

function extract(endpoint) {
  return function (result) {
    switch (endpoint) {
      case 'artworks': {
        return extractArtwork(result);
      }
      case 'movements': {
        return extractMovement(result);
      }
      default: {
        return result;
      }
    }
  };
}

function extractMovement(result) {
  let movement = result._source.ua.movements;
  movement.color =
    (movementParameters.categories &&
      movementParameters.categories[movement.movement_type_key] &&
      movementParameters.categories[movement.movement_type_key].color) ||
    '#EEEEEE';

  movement.movement_category_key = movement.movement_category === 'Dépôt' ? 'deposit' : 'exhibition';
  movement.dos_nb_oeu_coll = parseInt(movement.dos_nb_oeu_coll);
  movement.dos_nb_oeu_hcoll = parseInt(movement.dos_nb_oeu_hcoll);

  movement.text_notes = movement?.text_notes?.movement ? movement?.text_notes : { movement: [] };

  if (result._source.ua.medias) {
    movement.images = Object.values(result._source.ua.medias);
  }

  if (movement.movement_category_key === 'deposit') {
    movement.label = `${movement.title}, ${movement.location_city} (${movement.location_country})`;
  } else {
    movement.label = `${movement.title} [${movement.exhibition_dates}], ${movement.location_name}, ${movement.location_city} (${movement.location_country})`;
  }

  return movement;
}

function extractArtwork(result, isElement) {
  let artwork = ConfigService.artwork(result, isElement);

  artwork.slug = artwork.slug ?? artwork._id;
  artwork.highlights = result.highlight;

  artwork.authors = ConfigService.authors(result, isElement);

  artwork.imageSet = {
    headers: [],
    images: [],
  };

  artwork.text_notes = merge({}, artwork.text_notes, {
    artist: [],
    artwork: [],
    cart: [],
    header_artist: [],
    header_artwork: [],
    header_cart: [],
  });

  if (artwork.type === 'nonseparable') {
    if (!isElement) {
      artwork.elements = ConfigService.elements(result).map((e) => extractArtwork(e, true));
    }
  }

  artwork.images = [];

  for (let media of artwork.medias || []) {
    var attr;

    if (media.header_aggregate) {
      attr = 'headers';
    } else {
      attr = 'images';
    }

    artwork.imageSet[attr] || (artwork.imageSet[attr] = []);
    artwork.imageSet[attr].push(media);

    artwork.images.push(media);
  }

  return artwork;
}
