<template>
  <b-modal
    id="printGeneralModal"
    ref="printGeneralModal"
    v-model="value"
    size="md"
    hide-footer
    hide-header
    :no-close-on-backdrop="true"
    no-stacking
    lazy
    body-class="p-0"
    modal-class="p-0 print-general-params"
  >
    <form @submit.prevent="submit" class="form-params">
      <div class="modal-header">
        <div class="row w-100">
          <div class="col-md-8">
            <h3 class="modal-title">{{ stepTitle }}</h3>
          </div>
          <div class="col-md-4">
            <div class="float-right">
              <a href class="btn btn-round btn-round-white modal-close" @click.prevent="close" v-translate>Annuler</a>
              <a
                v-if="isPreviousStep"
                href
                class="btn btn-round btn-round-white modal-close"
                @click.prevent="previousStep()"
                v-translate
                >Précédent</a
              >
              <a
                v-if="isNextStep"
                href
                class="btn btn-round btn-round-white modal-close"
                @click.prevent="nextStep()"
                v-translate
                >Suivant</a
              >
              <button v-if="step === 3" class="btn btn-round btn-round-white" type="submit" v-translate>Valider</button>
            </div>
          </div>
        </div>
      </div>
      <div class="alert alert-warning mb-0" role="alert" v-if="submitted && error">
        <translate>Vous ne pouvez pas imprimer sans informations sélectionnées.</translate>
      </div>
      <div class="form-group mb-0" v-if="step === 1">
        <div class="form-row py-2">
          <div class="form-check form-check col justify-content-center">
            <input class="form-check-input" type="radio" v-model="form.template" name="type" id="full" value="full" />
            <label class="form-check-label" for="full">
              <span class="pb-2">{{ templates.full.label }}</span>
              <component :is="templates.full.icon" />
            </label>
          </div>
          <div class="form-check form-check col justify-content-center">
            <input
              class="form-check-input"
              type="radio"
              v-model="form.template"
              name="type"
              id="columns"
              value="columns"
            />
            <label class="form-check-label" for="columns">
              <span class="pb-2">{{ templates.columns.label }}</span>
              <component :is="templates.columns.icon" />
            </label>
          </div>
          <div class="form-check form-check col justify-content-center">
            <input
              class="form-check-input"
              type="radio"
              v-model="form.template"
              name="type"
              id="mosaic"
              value="mosaic"
            />
            <label class="form-check-label" for="mosaic">
              <span class="pb-2">{{ templates.mosaic.label }}</span>
              <component :is="templates.mosaic.icon" />
            </label>
          </div>
        </div>
        <hr class="strong mb-0" />
      </div>
      <perfect-scrollbar class="scroller scroll-always-visible" :options="scrollOptions" ref="ps">
        <div class="modal-body" v-if="step === 1">
          <div class="form-group" key="context-page">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                v-model="form.context_page"
                type="checkbox"
                id="context-page"
                value="true"
              />
              <label class="form-check-label" for="context-page" v-translate
                >1ère page avec contexte (recherche, dossier de sélection, ...)</label
              >
            </div>
          </div>
          <hr />
          <div class="form-group" v-translate>En-tête</div>
          <div class="form-row align-items-center pl-5 mb-2">
            <div class="form-check col-3 py-2">
              <input
                class="form-check-input"
                v-model="showLabel"
                @change="updateLabel()"
                type="checkbox"
                id="showLabel"
              />
              <label class="form-check-label" for="showLabel" v-translate> libellé à droite </label>
            </div>
            <div class="col-auto col-5" v-show="showLabel">
              <label for="label" class="sr-only">libellé à droite</label>
              <input type="text" v-model="form.label" name="label" class="form-control" id="label" />
            </div>
          </div>
          <div class="form-group pl-5 mb-2">
            <div class="form-row align-items-center">
              <div class="form-check col-3 py-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="showDate"
                  @change="updateDate()"
                  name="showDate"
                  id="showDate"
                />
                <label class="form-check-label" for="showDate" v-translate> date du jour à gauche </label>
              </div>
              <div class="col-auto col-5" v-show="showDate">
                <label for="label" class="sr-only" v-translate>date du jour à gauche</label>
                <input type="text" v-model="form.date" disabled="true" name="label" class="form-control" id="date" />
              </div>
            </div>
            <translate>(pagination toujours présente en bas à droite)</translate>
          </div>
          <div class="form-group" v-if="form.template !== 'mosaic'">
            <hr />
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="form.break_page"
                id="break_page"
                name="break_page"
                value="true"
              />
              <label class="form-check-label" for="break_page"><translate>Rupture de page par œuvre</translate></label>
            </div>
          </div>
          <hr />
          <div class="form-inline" key="sort">
            <label for="sort" class="pr-3"><translate>Tri de l'impression</translate></label>
            <b-form-select id="sort" name="sort" v-model="form.sort" :options="sortOptions"></b-form-select>
          </div>
          <hr />
          <div class="form-inline" key="text-size">
            <label class="pr-3"><translate>Taille typo</translate></label>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                v-model="form.text_size"
                name="text_size"
                id="text-grande"
                value="l"
              />
              <label class="form-check-label" for="text-grande" v-translate>grande</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                v-model="form.text_size"
                name="text_size"
                id="text-petite"
                value="s"
              />
              <label class="form-check-label" for="text-petite" v-translate>petite</label>
            </div>
          </div>
          <hr v-if="form.template === 'full'" />
          <div class="form-inline" v-if="form.template === 'full'">
            <label class="pr-3"><translate>Place notice rédactionnelle (si présente)</translate></label>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                v-model="form.text_notes_position"
                name="text_notes_position"
                id="text-notes-end"
                value="end"
              />
              <label class="form-check-label" for="text-notes-end" v-translate>à la fin</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                v-model="form.text_notes_position"
                name="text_notes_position"
                id="text-notes-start"
                value="start"
              />
              <label class="form-check-label" for="text-notes-start" v-translate>au début</label>
            </div>
          </div>
        </div>
        <div class="modal-body d-flex h-100 step-2" v-if="step === 2">
          <div
            class="current-template d-flex flex-column align-items-center col-4"
            v-if="currentTemplate"
            key="current-template"
          >
            <component :is="currentTemplate.icon" />
            <h6>{{ currentTemplate.label }}</h6>
          </div>
          <div class="form-group col-8" key="with_image">
            <div class="form-check form-check-inline pb-2">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="form.with_image"
                id="with_image"
                name="with_image"
                value="true"
              />
              <label class="form-check-label" for="with_image" v-translate>avec images</label>
            </div>

            <div key="full" v-if="form.with_image && form.template === 'full'">
              <div class="form-row pl-4" v-translate>Taille image</div>

              <div class="align-items-center pl-5 mb-2">
                <div class="form-check py-2">
                  <input
                    class="form-check-input"
                    name="image_size"
                    v-model="form.image_size"
                    type="radio"
                    value="xxl"
                    id="image_size_big"
                  />
                  <label class="form-check-label" for="image_size_big" v-translate> grande (1000 x 1000) </label>
                </div>
                <div class="form-check py-2">
                  <input
                    class="form-check-input"
                    name="image_size"
                    v-model="form.image_size"
                    type="radio"
                    value="l"
                    id="image_size_large"
                  />
                  <label class="form-check-label" for="image_size_large" v-translate> moyenne (600 x 600) </label>
                </div>
                <div class="form-check py-2">
                  <input
                    class="form-check-input"
                    name="image_size"
                    v-model="form.image_size"
                    type="radio"
                    value="s"
                    id="image_size_small"
                  />
                  <label class="form-check-label" for="image_size_small" v-translate> petite (200 x 200) </label>
                </div>
              </div>

              <div class="form-row pl-4" v-translate>Si plusieurs</div>
              <div class="align-items-center pl-5 mb-2">
                <div class="form-check py-2">
                  <input
                    class="form-check-input"
                    name="image_multiple"
                    v-model="form.multiple"
                    type="radio"
                    value="first"
                    id="image_multiple_first"
                  />
                  <label class="form-check-label" for="image_multiple_first" v-translate> uniquement la 1ère </label>
                </div>
                <div class="form-check py-2">
                  <input
                    class="form-check-input"
                    name="image_multiple"
                    v-model="form.multiple"
                    type="radio"
                    value="all"
                    id="image_size_all"
                  />
                  <label class="form-check-label" for="image_size_all" v-translate> toutes </label>
                </div>
              </div>
              <div class="align-items-center pl-5 ml-4 mb-2" v-if="form.multiple === 'all'">
                <div class="form-check py-2">
                  <input
                    class="form-check-input"
                    name="image_multiple_big"
                    v-model="form.multiple_big"
                    type="radio"
                    value="first"
                    id="image_multiple_big_first"
                  />
                  <label class="form-check-label" for="image_multiple_big_first" v-translate>
                    1ère en grand (les autres en vignette)
                  </label>
                </div>
                <div class="form-check py-2">
                  <input
                    class="form-check-input"
                    name="image_multiple_big"
                    v-model="form.multiple_big"
                    type="radio"
                    value="all"
                    id="image_multiple_big_all"
                  />
                  <label class="form-check-label" for="image_multiple_big_all" v-translate> toutes en grand </label>
                </div>
              </div>
            </div>

            <div key="columns" v-if="form.with_image && form.template === 'columns'">
              <div class="form-row pl-4" v-translate>Taille image</div>

              <div class="align-items-center pl-5 mb-2">
                <div class="form-check py-2">
                  <input
                    class="form-check-input"
                    name="image_size"
                    v-model="form.image_size"
                    type="radio"
                    value="m"
                    id="image_size_large"
                  />
                  <label class="form-check-label" for="image_size_large" v-translate> grande (400 x 400) </label>
                </div>
                <div class="form-check py-2">
                  <input
                    class="form-check-input"
                    name="image_size"
                    v-model="form.image_size"
                    type="radio"
                    value="s"
                    id="image_size_small"
                  />
                  <label class="form-check-label" for="image_size_small" v-translate> petite (200 x 200) </label>
                </div>
              </div>

              <div class="form-row pl-4" v-translate>Si plusieurs</div>
              <div class="align-items-center pl-5 mb-2">
                <div class="form-check py-2">
                  <input
                    class="form-check-input"
                    name="image_multiple"
                    v-model="form.multiple"
                    type="radio"
                    value="first"
                    id="image_multiple_first"
                  />
                  <label class="form-check-label" for="image_multiple_first" v-translate> uniquement la 1ère </label>
                </div>
                <div class="form-check py-2">
                  <input
                    class="form-check-input"
                    name="image_multiple"
                    v-model="form.multiple"
                    type="radio"
                    value="all"
                    id="image_multiple_all"
                  />
                  <label class="form-check-label" for="image_multiple_all" v-translate> toutes </label>
                </div>
              </div>
            </div>

            <div key="mosaic" v-if="form.with_image && form.template === 'mosaic'">
              <div class="form-row pl-4" v-translate>Taille image</div>

              <div class="align-items-center pl-5 mb-2">
                <div class="form-check py-2">
                  <input
                    class="form-check-input"
                    name="image_size"
                    v-model="form.image_size"
                    type="radio"
                    value="m"
                    id="image_size_big"
                  />
                  <label class="form-check-label" for="image_size_big" v-translate> grande (400 x 400) </label>
                </div>
                <div class="form-check py-2">
                  <input
                    class="form-check-input"
                    name="image_size"
                    v-model="form.image_size"
                    type="radio"
                    value="s"
                    id="image_size_large"
                  />
                  <label class="form-check-label" for="image_size_large" v-translate> moyenne (200 x 200) </label>
                </div>
                <div class="form-check py-2">
                  <input
                    class="form-check-input"
                    name="image_size"
                    v-model="form.image_size"
                    type="radio"
                    value="xs"
                    id="image_size_small"
                  />
                  <label class="form-check-label" for="image_size_small" v-translate> petite (100 x 100) </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body d-flex h-100 step-3" v-if="step === 3">
          <div
            class="current-template d-flex flex-column align-items-center col-4"
            v-if="currentTemplate"
            key="current-template"
          >
            <component :is="currentTemplate.icon" />
            <h6>{{ currentTemplate.label }}</h6>
          </div>
          <div class="form-group">
            <div class="form-group" key="url">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="form.url"
                  id="show_url"
                  name="url"
                  value="true"
                />
                <label class="form-check-label" for="show_url" v-translate>URL pour chaque œuvre</label>
              </div>
            </div>
            <hr />
            <div class="form-group">
              <button type="button" @click="selectAll" key="select-all" class="btn btn-sm mr-3" v-translate>
                tout sélectionner
              </button>
              <button type="button" @click="deselectAll" key="deselect-all" class="btn btn-sm" v-translate>
                tout désélectionner
              </button>
            </div>
            <div class="form-group">
              <div class="form-inline">
                <label for="search" class="pr-3">Search</label>
                <input type="text" v-model="search" name="search" class="form-control" id="search" />
              </div>
            </div>
            <div class="form-group d-flex flex-wrap">
              <div :key="group.key" class="form-check py-1 pl-4 w-100" v-for="group in filteredGroups">
                <input class="form-check-input" v-model="groups" type="checkbox" :id="group.key" :value="group" />
                <label class="form-check-label" :for="group.key">{{ group.label[lang] }}</label>
              </div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </form>
  </b-modal>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { sort, publicationName, autocompletion, vault } from 'config';
const {
  popup: {
    identified_fields: { groups },
  },
} = autocompletion;
const availableGroups = Object.keys(groups)
  .map((key) => Object.assign({ key }, groups[key]))
  .filter((group) => group.pos);

import dayjs from 'dayjs';
import 'dayjs/locale/fr';

import { HTTP } from '../util';

import defaultSort from 'lib/defaultSort';
import { v4 as uuidv4 } from 'uuid';
import appUrl from 'lib/appUrl';
import qs from 'qs';

import toastr from 'mixins/toastrMixin.js';

export default {
  name: 'PrintGeneralModal',
  mixins: [toastr],
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('params', ['sort', 'artworkApi']),
    ...mapGetters('local', ['preferences']),
    referrer() {
      const { path } = this.$router.currentRoute;
      return `${appUrl}${path}`;
    },
    sortOptions() {
      return Object.keys(sort['artworks']).map((k) => {
        return { value: k, text: sort['artworks'][k]['label'][this.lang] };
      });
    },
    availableGroups() {
      return availableGroups.reduce((memo, item) => {
        const { template } = this.form;
        const notTextNoteFull = /text_note_/.test(item.key) && !/full/.test(template);
        const notInMosaic = !item.in_mosaic && /mosaic/.test(template);

        if (notInMosaic || notTextNoteFull) {
          return memo;
        } else {
          memo.push(item);
        }
        return memo;
      }, []);
    },
    filteredGroups() {
      return this.availableGroups
        .filter((group) => {
          return group.label[this.lang].toLowerCase().includes(this.search.toLowerCase());
        })
        .sort((a, b) => a.pos - b.pos);
    },
    stepTitle() {
      return this.titles[this.step];
    },
    isNextStep() {
      return this.step !== 3;
    },
    isPreviousStep() {
      return this.step !== 1;
    },
    currentTemplate() {
      return this.templates[this.form.template];
    },
    defaultForm() {
      return {
        full: Object.assign(
          {
            text_notes_position: 'end',
            image_size: 'xxl',
            multiple: 'all',
            multiple_big: 'all',
            break_page: true,
            notes: true,
          },
          this.defualtCommon
        ),
        columns: Object.assign(
          {
            image_size: 'm',
            multiple: 'first',
            break_page: true,
            notes: false,
          },
          this.defualtCommon
        ),
        mosaic: Object.assign(
          {
            image_size: 'xs',
            notes: false,
          },
          this.defualtCommon
        ),
      };
    },

    defualtCommon() {
      const {
        meta: { endpoint = 'artworks' },
      } = this.$router.currentRoute;
      return {
        context_page: true,
        text_size: 'l',
        sort: this.sort || defaultSort(endpoint, true),
        date: dayjs(new Date()).format('DD/MM/YYYY'),
        label: publicationName,
        url: null,
        with_image: true,
        short: true,
      };
    },
    lang() {
      return this.$language.current;
    },
  },
  props: ['value', 'onClose', 'link', 'resource', 'short'],
  methods: {
    ...mapActions('auth', ['updatePreferences']),
    ...mapMutations('artworks', ['assign']),
    subscribe(id) {
      this.$root.fayeClient.subscribe('/' + id, (data) => {
        this.assign({ name: 'printLink', data });
        this.$toastr('clear', '*');
        this.$root.$emit('bv::show::modal', 'printModal');
      });
    },
    nextStep() {
      this.step += 1;
    },
    previousStep() {
      this.step -= 1;
    },
    selectAll() {
      this.groups = this.availableGroups;
    },
    deselectAll() {
      this.groups = [];
    },
    updateLabel() {
      if (!this.showLabel) {
        this.form.label = '';
      } else {
        this.form.label = publicationName;
      }
    },
    updateDate() {
      if (!this.showDate) {
        this.form.date = '';
      } else {
        this.form.date = dayjs(new Date()).format('DD/MM/YYYY');
      }
    },
    setFormParameters(template) {
      if (!template) {
        if (this.preferences && this.preferences.template) {
          template = this.preferences.template;
        } else {
          template = 'full';
        }
      }

      let formParams = Object.assign({ template }, this.defaultForm[template]);
      let groupsParams = [];

      this.showDate = true;
      this.showLabel = true;

      if (this.preferences && this.preferences[template]) {
        const { form, groups } = this.preferences[template];
        formParams = form;

        const groupKeys = groups ? groups.split(',') : [];
        groupsParams = this.availableGroups.reduce((memo, item) => {
          if (groupKeys.includes(item.key)) memo.push(item);
          return memo;
        }, []);

        this.showDate = form.date;
        this.showLabel = form.label;
      }

      this.form = { ...formParams };
      this.updateDate();
      this.groups = groupsParams;
    },
    close() {
      this.onClose();
    },
    async submit(e) {
      if (this.groups.length === 0) {
        this.submitted = true;
        this.error = true;
        return;
      }

      const { query } = this.$router.currentRoute;

      const artworksQuery = this.link === 'artworks' ? `?${qs.stringify(query)}` : '';
      const referrer = encodeURIComponent(`${this.referrer}${artworksQuery}`);

      const params = {
        id: uuidv4(),
        groups: this.groups.map((g) => g.key).join(','),
        referrer,
        short: this.short,
      };

      const queryParams = {
        artworks: this.artworkApi,
        artwork: { ids: this?.resource?.id },
        cart: { displayed_cart_id: this?.resource?.id, favorites: this?.resource?.id },
      };
      const pdfParams = Object.assign({}, queryParams[this.link] || {}, this.form, params);
      delete pdfParams.size;
      delete pdfParams.favorites;
      const url = `/artworks.pdf?${qs.stringify(pdfParams)}`;

      this.toastr('printPdf');

      const printPreferences = {
        template: this.form.template,
        [this.form.template]: {
          form: this.form,
          groups: params.groups,
        },
      };

      this.$store.commit('local/UPDATE_PREFERENCES', {
        [vault]: printPreferences,
      });

      this.subscribe(params.id);

      HTTP.get(url);

      if (this.isAuthenticated) {
        await this.updatePreferences(printPreferences);
      }

      this.close();
    },
  },
  watch: {
    step: {
      handler(newVal, oldVal) {
        setTimeout(() => {
          this.$refs.ps.ps.update();
        }, 200);
      },
    },
    value: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.submitted = false;
          this.error = false;
          this.step = 1;
          this.setFormParameters();
          setTimeout(() => {
            this.$refs.ps.ps.update();
          }, 200);
        }
      },
    },
    'form.template': {
      handler(newVal, oldVal) {
        if (newVal && oldVal && newVal !== oldVal) {
          this.setFormParameters(newVal);
        }
      },
    },
  },
  data() {
    return {
      step: 1,
      titles: {
        1: this.$gettext(`Choix du modèle d'impression et des paramètres généraux`),
        2: this.$gettext(`Choix des paramètres pour les images`),
        3: this.$gettext(`Choix des informations à imprimer`),
      },
      form: {},
      search: '',
      showDate: true,
      showLabel: true,
      submitted: false,
      error: false,
      groups: [],
      templates: {
        columns: {
          icon: 'SvgColumns',
          label: this.$gettext('liste de notices 2 colonnes'),
        },
        full: {
          icon: 'SvgFull',
          label: this.$gettext('liste de notices pleine page'),
        },
        mosaic: {
          icon: 'SvgMosaic',
          label: this.$gettext('mosaïque'),
        },
      },
      scrollOptions: {
        wheelSpeed: 1,
        wheelPropagation: false,
        minScrollbarLength: 20,
        suppressScrollX: true,
      },
    };
  },
};
</script>

<style lang="less">
.current-template {
  svg {
    width: 200px;
  }
}

.current-template + .form-group {
  border-left: 1px solid #ededed;
  padding-left: 20px;
}
.step-2 {
  svg .data {
    stroke: #cccccc;
  }
}

.step-3 {
  svg .image {
    stroke: #cccccc;
  }
}

.form-check-input,
.form-check-label {
  cursor: pointer;
}

.modal-body {
  padding: 1rem 2rem;
}

hr.strong {
  border-width: 2px;
  border-color: #adadad;
}

input[name='type'] {
  visibility: hidden;
}

input[name='type'] + label {
  display: flex;
  flex-direction: column;
  justify-items: center;
  text-align: center;
}

input[name='type'] + label svg {
  border: 2px solid transparent;
  max-width: 100px;
  align-self: center;
}

input[name='type']:checked + label svg {
  border: 2px solid black;
}

.print-general-params {
  overflow: hidden;
  .form-params {
    height: 90vh;
    display: flex;
    flex-direction: column;
  }

  .scroller {
    flex: 1;
    flex-direction: row;
    position: relative;
    overflow-y: hidden;
    height: 100%;
  }
}
</style>
