import Vue from 'vue';
import Router from 'vue-router';
import VueMeta from 'vue-meta';

import SearchContext from '../components/SearchContext.vue';
import Artworks from '../components/Artworks.vue';
import NoResults from '../components/NoResults.vue';
import Header from '../components/Header.vue';
import ArtworkTextNote from '../components/ArtworkTextNote.vue';
import TopHeader from '../components/TopHeader.vue';

const Artwork = () => import(/* webpackChunkName: "views" */ '../components/Artwork.vue');
const Movements = () => import(/* webpackChunkName: "views" */ '../components/Movements.vue');
const MovementsHeader = () => import(/* webpackChunkName: "views" */ '../components/MovementsHeader.vue');
const Login = () => import(/* webpackChunkName: "views" */ '../components/Login.vue');
const Register = () => import(/* webpackChunkName: "views" */ '../components/Register.vue');
const Carts = () => import(/* webpackChunkName: "views" */ '../components/Carts.vue');
const TextNotes = () => import(/* webpackChunkName: "views" */ '../components/TextNotes.vue');
const NotFound = () => import(/* webpackChunkName: "views" */ '../components/NotFound.vue');
const CookiesRequired = () => import(/* webpackChunkName: "views" */ '../components/CookiesRequired.vue');
const Help = () => import(/* webpackChunkName: "views" */ '../components/Help.vue');
const RequestAccess = () => import(/* webpackChunkName: "views" */ '../components/RequestAccess.vue');
const Unconfirmed = () => import(/* webpackChunkName: "views" */ '../components/Unconfirmed.vue');

import { urlPrefix, shareBaseUrl } from 'config';
import { sessionStorageIsSupported, localStorageIsSupported } from '../util/storage';
import FilterBuilder from 'lib/filterBuilder';

import store from '../store';

Vue.use(VueMeta);
Vue.use(Router);

const env = process.env.NODE_ENV;

const { pathname: dir } = new URL(shareBaseUrl);
const isDir = dir !== '/';

const router = new Router({
  mode: 'history',
  base: env === 'production' && isDir ? dir : '/',
  routes: [
    {
      path: '/',
      redirect: '/artworks',
      component: SearchContext,
      children: [
        {
          path: 'artworks/:filters*',
          name: 'Artworks',
          alias: 'artworks/search?',
          components: {
            default: Artworks,
            header: Header,
            empty: NoResults,
          },
        },
        {
          name: 'artwork',
          path: 'artwork/:id',
          alias: 'artworks/search/:id',
          components: {
            default: Artwork,
            header: TopHeader,
          },
          children: [
            {
              name: 'ArtworkNote',
              path: 'note/:note',
              component: ArtworkTextNote,
            },
          ],
        },
        {
          name: 'Movements',
          path: 'movements/map/:filters*',
          components: {
            header: MovementsHeader,
            default: Movements,
          },
          meta: {
            endpoint: 'movements',
          },
        },
      ],
    },
    {
      name: 'carts',
      path: '/carts',
      component: Carts,
      meta: { requiresAuth: true },
    },
    {
      name: 'TextNotes',
      path: '/text_notes',
      component: TextNotes,
      meta: { requiresAuth: true },
    },
    {
      name: 'login',
      path: '/login',
      component: Login,
    },
    {
      name: 'register',
      path: '/register',
      component: Register,
    },
    {
      name: 'RequestAccess',
      path: '/request_access',
      component: RequestAccess,
    },
    {
      name: 'Unconfirmed',
      path: '/unconfirmed',
      component: Unconfirmed,
    },
    { path: '/404', component: NotFound },
    { path: '/cookies_required', component: CookiesRequired, name: 'cookies_required' },
    { path: '/help', component: Help },
    { path: '*', redirect: '/artworks' },
  ],
  // TODO This returns an anchor, but doesn't move the page when a column is fixed
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const { hash } = to;
  const { page } = to.query;

  if (hash) {
    next({
      path: hash.replace('#', ''),
    });
  }

  if (to.name === 'Artworks' && to.query.filters) {
    next({
      ...to,
      query: { ...to.query, filters: null },
      params: { filters: FilterBuilder.toURLParam(FilterBuilder.fromParam(to.query.filters)) },
    });
  }

  if (page && page === '0') {
    to.query.page = 1;
    next({ ...to, replace: true });
  }

  const { token, isAuthenticated } = store.state.auth;
  if (token && !isAuthenticated && !['RequestAccess'].includes(to.name)) {
    await store.dispatch('auth/me');
  }

  if (['cookies_required'].includes(to.name)) {
    return next();
  }

  if (!sessionStorageIsSupported() || !localStorageIsSupported()) {
    return next({
      path: '/cookies_required',
    });
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if ((sessionStorage.getItem('token') || localStorage.getItem('token')) == null) {
      next({
        path: '/login',
      });
    } else {
      next();
    }
  } else {
    next();
  }

  if (['login', 'register'].includes(to.name)) {
    if ((sessionStorage.getItem('token') || localStorage.getItem('token')) != null) {
      next({
        path: '/artworks',
      });
    } else {
      sessionStorage.setItem('previousPath', from.fullPath);
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  const { fullPath } = to;
  let search = location.search.substring(1);
  if (search) {
    try {
      search = JSON.parse(`{"${search.replace(/&/g, '","').replace(/=/g, '":"')}"}`, function (key, value) {
        return key === '' ? value : decodeURIComponent(value);
      });
    } catch (e) {
      search = {};
    }
    if (search.token && sessionStorageIsSupported()) {
      sessionStorage.setItem('token', search.token);
    }
  }

  const sender = window.parent;

  if (fullPath !== '/' + urlPrefix) {
    sender.postMessage(JSON.stringify({ path: fullPath }), '*');
  }
});

export default router;
