import { autocompletion } from 'config';
import FilterBuilder from 'lib/filterBuilder';
import { isMobile } from 'util/isMobile';

import { pageSize } from 'config';
export default {
  silent: (state, getters, rootState) => rootState.route.params.silent,
  page: (state, getters, rootState) => parseInt(rootState.route.query.page || 1),
  favorites: (state, getters, rootState) => rootState.route.query.favorites,
  layout: (state, getters, rootState) => rootState.route.query.layout || (isMobile() ? 'box' : 'grid'),
  ids: (state, getters, rootState) => rootState.route.query.ids,
  movement_id: (state, getters, rootState) => rootState.route.query.movement_id,
  filters: (state, getters) => getters.locationFilters,
  appliedFilters: (state, getters, rootState) =>
    getters.locationFilters
      .filter((f) => {
        if (f.type !== 'checkbox') {
          return f;
        }
      })
      .map((f) => {
        if (/term|range|before|after/.test(f.type)) {
          if (rootState.aggregations.aggregations[f.aggregation]) {
            f.label = rootState.aggregations.aggregations[f.aggregation].displayed_as;
          } else {
            f.label = '';
          }
        } else if (f.type === 'category_query') {
          f.label = autocompletion.popup.identified_fields.groups[f.aggregation].label;
        }

        if (
          rootState.aggregations.aggregations[f.aggregation] &&
          rootState.aggregations.aggregations[f.aggregation].years_combined
        ) {
          f.years_combined = true;
          f.label = rootState.aggregations.aggregations[f.aggregation].displayed_as;
        }

        return f;
      }),
  textNoteFilters: (state, getters, rootState) =>
    getters.locationFilters.filter((f) => {
      const agg = rootState.aggregations.aggregations[f.aggregation];
      return agg && agg.with_text_note && f;
    }),
  termOrder: (state) => state.term_order,
  termPage: (state) => state.term_page,
  numericalRange: (state, getters) => (aggregation) => {
    const filter = getters.locationFilters.find((f) => f.aggregation === aggregation && f.type === 'range');
    const { term } = filter || {};
    return term ? term[0].split('__') : [];
  },
  anyFilterApplied: (state, getters) => {
    const filters = getters.locationFilters.filter((f) => f.type !== 'checkbox');
    return filters.length > 0 || getters.favorites || getters.movement_id || (getters.ids && getters.ids.length);
  },
  sort: (state, getters, rootState) => rootState.route.query.sort || null,
  locationFilters: (state, getters, rootState, rootGetters) => {
    const { endpoint = 'artworks' } = rootState.route.meta;
    const { filters: queryFilters } = rootState.route.query;

    const prefix = endpoint === 'artworks' ? `/${endpoint}/` : `/${endpoint}/map/`;

    const locationFilters = location.pathname
      ?.split(prefix)[1]
      ?.split('/')
      ?.map((s) => decodeURIComponent(s));
    const filters = FilterBuilder.fromURLParam(locationFilters || queryFilters);

    return filters;
  },
  artworkApi: (state, getters, rootState, rootGetters) => {
    const { endpoint = 'artworks' } = rootState.route.meta;
    const { layout, page, sort: querySort, favorites } = rootState.route.query;
    const activeCartId = rootGetters['carts/activeCart']?.id;
    const PAGE = page || 1;
    const size = endpoint === 'movements' ? 1000 : pageSize[layout] || pageSize.grid;
    const from = parseInt(PAGE) * size - size;

    let filters = getters.locationFilters;
    let stateParams = Object.assign(
      {},
      state,
      rootState.route.query,
      { filters, size, from },
      { displayed_cart_id: favorites }
    );
    let params = FilterBuilder.toApiParam(Object.assign({}, stateParams, { endpoint }));

    return params;
  },
  autocompleteApi: (state, getters, rootState) => {
    const { endpoint = 'artworks' } = rootState.route.meta;

    let params = {
      term_order: 'count:desc',
    };

    let filters = getters.locationFilters;
    let stateParams = Object.assign({}, state, rootState.route.query, { filters });
    params = Object.assign({}, params, FilterBuilder.toApiParam(Object.assign({}, stateParams, { endpoint })));

    delete params.text_notes_categories;
    delete params.sort;
    delete params.buster;

    return params;
  },
  aggregationsApi:
    (state, getters, rootState) =>
    (exlude = [], aggregation) => {
      const { endpoint = 'artworks' } = rootState.route.meta;

      const TERM_SIZE = 12;

      const aggregationKey = rootState.aggregations.aggregation || aggregation;

      let params = {
        size: 0,
        term: aggregationKey,
        term_order: rootState.params.term_order,
        term_size: TERM_SIZE,
        term_from: rootState.params.term_page * TERM_SIZE - TERM_SIZE,
      };

      let filters = getters.locationFilters.filter((f) => !exlude.includes(f.aggregation));
      let stateParams = Object.assign({}, state, rootState.route.query, { filters });
      params = Object.assign({}, params, FilterBuilder.toApiParam(Object.assign({}, stateParams, { endpoint })));

      if (rootState.autocomplete.query.length) {
        params.term_word = rootState.autocomplete.query;
      }

      delete params.text_notes_categories;
      delete params.sort;
      delete params.buster;

      return params;
    },
};
