export const localStorageIsSupported = function () {
  try {
    const key = btoa(Math.random(Date.now()));
    localStorage.setItem(key, key);
    localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

export const sessionStorageIsSupported = function () {
  try {
    const key = btoa(Math.random(Date.now()));
    sessionStorage.setItem(key, key);
    sessionStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

export const StorageVerion = 'v3';
