<template>
  <div>
    <div class="modal-header">
      <div class="row w-100">
        <div class="col-md-8">
          <h3 class="modal-title" v-translate>Construction d'un lien oeuvre(s) de la collection</h3>
        </div>
        <div class="col-md-4 d-flex justify-content-end">
          <button class="btn btn-round pull-right" @click="insert()" :disabled="!canInsert" v-translate>Valider</button>
          <a href class="btn btn-round" @click.stop.prevent="close" v-translate>Annuler</a>
        </div>
      </div>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="form-group">
          <ol class="start">
            <li v-translate>Ouvrez dans un autre onglet l'affichage de l’œuvre</li>
            <li v-translate>
              Double cliquez, dans la barre d'adresse, sur la partie de l'URL où se trouve l'id de l’œuvre ET copier la
              partie surlignée par ctrl-c (ou cmd-c)
            </li>
            <li v-translate>Coller l'id dans le champ ci-dessous par ctrl-v (ou cmd-v)</li>
          </ol>
          <div class="form-group">
            <textarea-autosize
              v-model="ids"
              class="form-control auto-grow"
              v-focus
              :min-height="60"
              autofocus
            ></textarea-autosize>
            <label class="col-form-label" for="link_params" v-translate
              >Chaque id insérée dans le champ ci-dessus construit l'URL du lien œuvre(s):</label
            >
          </div>

          <pre>{{ link }}</pre>

          <ol class="continue">
            <li class="bracket" v-translate>
              Si lien avec plusieurs œuvres, répétez les opérations ci-dessus et coller les id en les séparant par des
              virgules
            </li>
            <li v-translate>Cliquez sur «Valider» pour insérer l'URL du lien dans le texte</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUrl from 'lib/appUrl.js';
import { shareSingleWorkPrefix, shareMultipleWorksPrefix } from 'config';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'LinksModal',
  computed: {
    ...mapState('artworks', ['smde']),
    link() {
      let path = '';
      if (this.ids) {
        path = /,/.test(this.ids) ? shareMultipleWorksPrefix : shareSingleWorkPrefix;
        this.canInsert = true;
      } else {
        this.canInsert = false;
      }

      return `${appUrl}${path}${this.ids}`;
    },
    saveLink() {
      const prefix = /,/.test(this.ids) ? 'shareMultipleWorksPrefix' : 'shareSingleWorkPrefix';
      return `{shareBaseUrl}{${prefix}}${this.ids}`;
    },
  },
  methods: {
    ...mapMutations('artworks', ['assign']),
    insert() {
      let smde = Object.assign({}, this.smde);
      smde.link = this.saveLink;

      this.assign({ name: 'smde', data: smde });
      return this.$root.$emit('bv::show::modal', 'contentForm');
    },
    close() {
      return this.$root.$emit('bv::show::modal', 'contentForm');
    },
  },
  data() {
    return {
      ids: '',
      canInsert: false,
    };
  },
};
</script>

<style lang="less" scoped>
.links-modal {
  .modal-header .btn {
    margin-left: 4px;
  }
  .modal-body {
    ol {
      padding: 0;
      li {
        counter-increment: mycounter;
        list-style-type: none;
        margin-bottom: 15px;
        &:before {
          content: counter(mycounter) '- ';
        }
        &.bracket:before {
          content: '[' counter(mycounter) ']- ';
        }
      }
    }
    ol.start {
      counter-reset: mycounter;
    }
    ol.continue {
      counter-reset: mycounter 3;
    }

    pre {
      padding: 9.5px;
      margin: 0 0 10px;
      line-height: 1.42857143;
      word-break: break-all;
      word-wrap: break-word;
      background-color: #f5f5f5;
      border: 1px solid #ccc;
      border-radius: 0;
    }
  }
}
</style>
