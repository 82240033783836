import {
  CARTS_SET_ACTIVE,
  CARTS_SET_ALL,
  CARTS_SET_UNSELECT_ALL_STATUS,
  CARTS_ADD_CART,
  CARTS_UPDATE_CART,
  CARTS_UPDATE_TEXT_NOTE,
  CARTS_REMOVE_CART,
  CARTS_RESET,
} from '../mutation-types';

import { initialState } from './state';

export default {
  [CARTS_SET_ALL](state, carts) {
    state.all = carts;
  },
  [CARTS_SET_UNSELECT_ALL_STATUS](state, status) {
    state.unselectAllStatus = status;
  },
  [CARTS_SET_ACTIVE](state, { activeCart, noPulse }) {
    if (Object.keys(activeCart).length > 0) {
      state.all = [...state.all.filter((c) => c.id !== activeCart.id), activeCart];
      state.activeCart = activeCart;
    } else {
      state.activeCart = null;
    }
    if (!noPulse) {
      state.pulseCartId = activeCart.id;
    }
  },
  [CARTS_ADD_CART](state, cart) {
    state.all.push(cart);
    state.activeCart = cart;
    state.pulseCartId = cart.id;
  },
  [CARTS_UPDATE_CART](state, cart) {
    state.all = state.all.filter((c) => c.id !== cart.id).concat(cart);
    state.editCardId = null;
    state.updateUniqName = true;
    state.selectedCartId = null;
  },
  [CARTS_REMOVE_CART](state, cart_id) {
    state.all = state.all.filter((c) => c.id !== cart_id);
  },
  [CARTS_RESET](state) {
    for (let f in state) {
      state[f] = initialState[f];
    }
  },
  [CARTS_UPDATE_TEXT_NOTE](state, { textNote }) {
    const { cart_id } = textNote;
    const cart = state.all.find((c) => c.id === cart_id);
    if (cart) {
      cart.text_note = textNote;
      state.all = [...state.all.filter((c) => c.id !== cart_id), cart];
    }
  },
  assign(state, { name, data }) {
    state[name] = data;
  },
};
