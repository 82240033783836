<template>
  <div class="top-tools">
    <MenuBarAccount />
    <MenuBarUserHelp />
    <MenuBarFavorites />
    <MenuBarLanguage />
    <div class="quit-buttons">
      <a v-if="favorites" @click="removeFavorites" class="btn btn-round" v-translate>Quitter sélection</a>
      <a v-if="ids" @click.stop.prevent="$router.back()" class="btn btn-round" v-translate>Retour à la notice</a>
    </div>
  </div>
</template>

<script>
import {mapGetters } from 'vuex';
import router from '../router';
import MenuBarAccount from './MenuBarAccount.vue';
import MenuBarUserHelp from './MenuBarUserHelp.vue';
import MenuBarFavorites from './MenuBarFavorites.vue';
import MenuBarLanguage from './MenuBarLanguage.vue';
import { translateEnable } from 'config';

export default {
  name: 'MenuBar',
  components: { MenuBarAccount, MenuBarUserHelp, MenuBarFavorites, MenuBarLanguage },
  computed: {
    ...mapGetters('params', ['layout', 'favorites', 'ids']),
    isGrid() {
      // !$ctrl.showTextNote missing
      return this.layout === 'grid';
    },
    isMosaic() {
      // !$ctrl.showTextNote missing
      return this.layout === 'mosaic';
    },
    isBox() {
      return this.layout === 'box';
    },
  },
  methods: {
    removeFavorites() {
      const query = this.$route.query;
      this.$router.push({ name: 'Artworks', query: { ...query, favorites: null, page: 1 } });
    },
  },
};
</script>
