<template>
  <div class="account">
    <router-link to="/login" v-if="loginInternal" v-b-tooltip.hover.left :title="titles.loginInternal">
      <i class="icon-moncompte"></i>
    </router-link>
    <login-auth-link
      v-b-tooltip.hover.left
      :title="titles.login"
      v-if="loginOptions.external && !isAuthenticated"
      network="external"
    >
      <i class="icon-moncompte"></i>
    </login-auth-link>
    <b-dropdown
      v-if="isAuthenticated"
      right
      variant="link"
      toggle-class="active"
      menu-class="dropdown-menu top-tools-dropdown"
      no-caret
      v-b-tooltip.hover.left
      :title="titles.account"
    >
      <template slot="button-content">
        <i class="icon-moncompte"></i>
      </template>
      <ul>
        <b-dropdown-item v-b-modal.accountEdit v-hide-on-mobile>
          <span v-translate>Mon compte :</span>
          <br />
          <span class="current-user">{{ currentUser.name || currentUser.email }}</span>
        </b-dropdown-item>
        <b-dropdown-item to="/text_notes" v-if="canAccessTextNotes" v-hide-on-mobile
          ><translate>Gestion des notices rédactionnelles</translate></b-dropdown-item
        >
        <b-dropdown-item v-if="canAccessAdminPanel" @click.prevent="adminPanel"
          ><translate>Administration de la publication</translate></b-dropdown-item
        >
        <b-dropdown-item @click.prevent="logout"><translate>Se déconnecter</translate></b-dropdown-item>
      </ul>
    </b-dropdown>

    <b-modal id="accountEdit" hide-header hide-footer lazy body-class="p-0" modal-class="object-share">
      <MenuBarAccountModal />
    </b-modal>
  </div>
</template>

<script>
import { loginOptions, oauthBaseUrl } from 'config';
import { mapState } from 'vuex';
import MenuBarAccountModal from './MenuBarAccountModal.vue';
import LoginAuthLink from './LoginAuthLink.vue';
import abilitiesMixin from 'mixins/abilitiesMixin.js';

export default {
  name: 'MenuBarAccount',
  components: { MenuBarAccountModal, LoginAuthLink },
  mixins: [abilitiesMixin],
  computed: {
    ...mapState('auth', ['isAuthenticated', 'currentUser']),
    loginInternal() {
      return !this.isAuthenticated && !loginOptions.external;
    },
    canAccessAdminPanel() {
      return this.$can('manage_private', 'private_publ_management');
    },
    canAccessTextNotes() {
      return (
        this.$can('publish_all', 'text_note') ||
        this.$can('edit_all', 'text_note') ||
        this.$can('edit_own', 'text_note')
      );
    },
    titles() {
      return {
        loginInternal: this.$gettext('Cliquer pour se connecter'),
        login: this.$gettext('Cliquer pour se connecter'),
        account: this.$gettext("Cliquer pour accéder aux fonctions d'un utilisateur connecté"),
      };
    },
  },
  methods: {
    adminPanel() {
      window.open(`${oauthBaseUrl}users/sign_in`, '_blank');
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.dropdownOpen = false;
      this.$router.go();
    },
  },
  data() {
    return {
      loginOptions,
    };
  },
};
</script>
