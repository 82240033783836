<template>
  <div class="show-more-suggestions" v-if="!lastPageSelected()">
    <a href @click.stop.prevent="nextPage" v-b-tooltip.top :title="titles.next">
      <strong>...</strong>
    </a>
  </div>
</template>

<script>
import Pagination from './Pagination.vue';

export default {
  name: 'AutocompletePagination',
  extends: Pagination,
  props: {
    term: String,
    type: String,
  },
  methods: {
    nextPage() {
      if (this.localPage >= this.pageCount) return;
      this.localPage = this.localPage + 1;
      this.clickHandler({ page: this.localPage, term: this.term, type: this.type, size: this.perPage });
    },
    lastPageSelected() {
      return this.localPage === this.pageCount || this.pageCount === 0;
    },
  },
  computed: {
    titles() {
      return {
        next: this.$gettext('Cliquez ici pour avoir la suite de la liste'),
      };
    },
  },
  data() {
    return {
      localPage: 1,
    };
  },
};
</script>

<style scoped lang="less">
.show-more-suggestions {
  height: 30px;
  text-align: center;
  letter-spacing: 2px;
  font-size: 17px;
  border-bottom: 1px solid #d6d6d6;
  a {
    font-weight: 700;
  }
}
</style>
