<template>
  <b-modal id="cartShareModal" ref="cartShareModal" modal-class="share-cart" size="md" hide-header hide-footer lazy>
    <div v-if="shareCart">
      <div class="modal-header">
        <div class="row w-100">
          <div class="col-md-7">
            <h3 class="modal-title" v-translate>Partage de la sélection</h3>
          </div>
          <div class="col-md-5">
            <div class="float-right">
              <button class="btn btn-round btn-round-white" type="button" @click="hide()" v-translate>Annuler</button>
              <button
                class="btn btn-round btn-round-white"
                type="button"
                @click="save()"
                v-show="isCartOwner"
                v-translate
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="body">
        <div class="alert alert-warning" role="alert" v-show="showEmailMessage">
          <div v-translate>
            Ce mail n'existe pas dans les utilisateurs Navigart.Demandez à votre interlocuteur de créer un compte.
          </div>
        </div>
        <div class="form-group row">
          <label for="cart_name" class="col-4 control-label text-right" v-translate>Nom de la sélection</label>
          <div class="col-6">
            <input type="text" v-model="name" class="form-control" id="cart_name" name="cart_name" disabled="true" />
          </div>
        </div>
        <div class="form-group row">
          <label for="cart_owner" class="col-4 control-label text-right" v-translate
            >Propriétaire de la sélection</label
          >
          <div class="col-6">
            <input type="text" v-model="owner" class="form-control" id="cart_owner" name="cart_owner" disabled="true" />
          </div>
        </div>
        <div class="form-group row form-well well-mercury">
          <i class="icon-Share_intern group-icon"></i>
          <label class="col-4 control-label text-right" v-translate>Partagé avec</label>
          <div :key="index" v-for="(collaborator, index) in collaborators" class="collaborators-list d-flex col-8">
            <div class="col-9" :class="{ 'offset-4': index !== 0 }">
              <input
                type="text"
                v-model="collaborator.email"
                class="form-control"
                id="shared_with"
                name="shared_with"
                disabled="true"
              />
            </div>
            <a v-show="isCartOwner" @click.stop.prevent="removeCollaborator(index)">
              <i class="icon-trash" />
            </a>
          </div>

          <div class="collaborators-list col-8" v-if="!collaborators || collaborators.length == 0">
            <div class="col-9">
              <input type="text" class="form-control" disabled="true" :placeholder="titles.notShared" />
            </div>
            <div></div>
          </div>
        </div>
        <div class="form-group row form-well well-silver" v-show="isCartOwner">
          <i class="icon-ADD_USER group-icon"></i>
          <label for="email" class="col-4 control-label text-right" v-translate>Ajouter partage</label>
          <div class="col-6">
            <input
              type="email"
              v-model="email"
              class="form-control"
              id="email"
              name="email"
              :placeholder="titles.email"
            />
          </div>
          <div class="col-2">
            <a class="btn btn-round pull-left" @click.stop.prevent="addCollaborator()" v-translate>Partager</a>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import User from 'api/user';

export default {
  name: 'CartShareModal',
  methods: {
    ...mapMutations('carts', ['assign']),
    ...mapActions('carts', ['updateCollaborators']),
    hide() {
      this.$refs.cartShareModal.hide();
      this.assign({ name: 'shareCart', data: null });
    },
    async addCollaborator() {
      var EMAIL_REGEXP = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      if (
        this.email === '' ||
        !EMAIL_REGEXP.test(this.email) ||
        this.collaborators.some((obj) => {
          return obj.email === this.email;
        })
      ) {
        return;
      }
      try {
        const { data, status } = await User.check(this.email);
        if (status === 200) {
          this.collaborators.push(data);
          this.email = '';
        } else if (status === 204) {
          this.showEmailMessage = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    removeCollaborator(index) {
      this.collaborators.splice(index, 1);
    },
    async save() {
      const params = {
        id: this.shareCart.id,
        users: this.collaborators.map((u) => u.id),
      };

      await this.updateCollaborators(params);
      this.hide();
    },
  },
  computed: {
    ...mapState('carts', ['shareCart']),
    ...mapState('auth', ['currentUser']),

    isCartOwner() {
      return this.shareCart.user_id === this.currentUser.id;
    },
    name() {
      return this.shareCart.name;
    },
    owner() {
      return this.shareCart.user_email;
    },
    titles() {
      return {
        email: this.$gettext('Mail du compte Navigart'),
        notShared: this.$gettext('Non partagé'),
      };
    },
  },
  data() {
    return {
      collaborators: [],
      showEmailMessage: false,
      email: '',
    };
  },
  watch: {
    shareCart(newV, oldV) {
      if (newV && newV.collaborators) {
        this.collaborators = cloneDeep(newV.collaborators);
      }
    },
  },
};
</script>
