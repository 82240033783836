const SPLIT_CHAR = '↹';

export default function (key) {
  let toLabel = key;
  const [afterOrRange, beforeOrRange] = key.split('__');

  if (afterOrRange.length > 0 && beforeOrRange && beforeOrRange.length > 0) {
    toLabel = [afterOrRange, beforeOrRange];
  } else if (afterOrRange.length > 0 && typeof beforeOrRange === 'string') {
    toLabel = afterOrRange;
  } else if (typeof beforeOrRange === 'string' && beforeOrRange.length > 0) {
    toLabel = beforeOrRange;
  }

  if (typeof toLabel == 'string') {
    const [label, ...rest] = toLabel.split(SPLIT_CHAR).reverse();
    return toLabel === '9999' ? 'sans date' : label;
  } else {
    return toLabel.map((l) => {
      const [label, ...rest] = l.split(SPLIT_CHAR).reverse();
      return l === '9999' ? 'sans date' : label;
    });
  }
}
