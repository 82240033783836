import {
  AUTOCOMPLETE_SEARCH,
  SET_FULLTEXT_CHECK,
  APPEND_AUTOCOMPLETE_AGGREGATIONS,
  APPEND_AUTOCOMPLETE_GROUPS,
  UPDATE_QUERY,
  RESET_AUTOCOMPLETE,
} from '../mutation-types';

import keyToLabel from 'lib/keyToLabel';
import flattenTerms from 'lib/flattenTerms';

import { autocompletion } from 'config';

export default {
  [SET_FULLTEXT_CHECK](state, data) {
    if (data === undefined) {
      state.fullTextCheck = 0;
    } else {
      state.fullTextCheck = data > 0 ? 1 : 2;
    }
  },
  [AUTOCOMPLETE_SEARCH](state, data) {
    const contextGroups = autocompletion.popup.identified_fields.groups;

    state.groups = data.group_1.reduce((groups, item) => {
      const { category, total_count } = item;
      const { label, pos } = contextGroups[category] || {};

      groups[category] = groups[category] || {
        category_label: label || { fr: category, en: category },
        pos,
        category,
        total_count,
        items: [],
      };

      groups[category].items = groups[category].items || [];
      groups[category].items.push(item);

      return groups;
    }, {});

    state.aggregations = Object.entries(data.aggregations)
      .filter((o) => o[1].pos && o[1].total !== 0 && !!!o[1].checkbox)
      .reduce((aggs, o) => {
        const [key, agg] = o;
        agg.key = key;
        let terms = agg.tree ? flattenTerms(agg.terms) : agg.terms;

        agg.items = terms.map((t) => {
          t.label = t.label ? t.label : keyToLabel(t.highlight || t.key);
          return t;
        });

        if (agg.items.length && agg.tree) {
          agg.total = agg.items.length;
        }

        aggs[key] = agg;
        return aggs;
      }, {});
  },
  [APPEND_AUTOCOMPLETE_GROUPS](state, { data, category }) {
    state.groups[category].items = data;
  },
  [APPEND_AUTOCOMPLETE_AGGREGATIONS](state, { data, term }) {
    let terms = data.tree ? flattenTerms(data.terms) : data.terms;
    terms = terms.map((t) => {
      t.label = t.label ? t.label : keyToLabel(t.highlight || t.key);
      return t;
    });

    state.aggregations[term].items = [...state.aggregations[term].items, ...terms];
  },
  [UPDATE_QUERY](state, data) {
    state.query = data;
  },
  [RESET_AUTOCOMPLETE](state) {
    state.aggregations = {};
    state.groups = {};
    state.fullText = [];
    state.fullTextCheck = 0;
  },
};
