<template>
  <a href="#" class="btn btn-outline-secondary" v-b-tooltip.hover.left :title="tooltip" @click.stop.prevent="toggle()">
    <i :class="{ true: 'icon-ICON_Link_Notice_ON', false: 'icon-ICON_Link_Notice_OFF' }[showSummary]"></i>
  </a>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TextNoteSummaryButton',
  methods: {
    toggle() {
      const newShowSummary = !this.showSummary;
      this.$store.commit('local/UPDATE_SUMMARY', newShowSummary);
    },
  },
  computed: {
    ...mapGetters('local', ['showSummary']),
    tooltip() {
      if (this.showSummary) {
        return this.$gettext('Ne pas afficher le récapitulatif des liens à la fin de la notice');
      } else {
        return this.$gettext('Afficher le récapitulatif des liens à la fin de la notice');
      }
    },
  },
};
</script>
