<template>
  <router-link
    :to="{
      name: 'artwork',
      params: { id: slug },
      query: { ...$route.query, ...(filtersQuery && { filters: filtersQuery }) },
    }"
    class="art-mosaic"
    :class="{ 'box-starred': isStarred }"
  >
    <div class="content" ref="artMosaic">
      <FavoriteLink v-hide-on-mobile :artworkId="artwork.id" placement="bottom" />
      <artwork-image v-if="mediaImage[0]" :image="mediaImage[0]" :version="100" />
      <div class="no-image-content" v-else-if="mediaVideoAudio.length"></div>
      <p class="no-image" v-if="!mediaImage[0] && !mediaVideoAudio.length">
        {{ noImage.mosaic[artwork.image_unavailable] }}
      </p>
      <div class="thumb-icon" v-if="mediaVideoAudio.length">
        <span :class="thumbIconClass" class="fa fa-3x media-icon"></span>
      </div>
    </div>
    <ArtworkMosaicTooltip :refs="$refs" :artwork="artwork" />
  </router-link>
</template>
<script>
import ArtworkImage from './ArtworkImage.vue';
import { mapGetters } from 'vuex';
import ArtworkMosaicTooltip from './ArtworkMosaicTooltip.vue';
import FavoriteLink from './FavoriteLink.vue';
import { unavailableImage } from 'config';
import FilterBuilder from 'lib/filterBuilder';

export default {
  name: 'artwork-mosaic',
  props: {
    artwork: {
      type: Object,
      require: true,
    },
  },
  components: {
    ArtworkImage,
    FavoriteLink,
    ArtworkMosaicTooltip,
  },
  computed: {
    ...mapGetters('carts', ['activeCart']),
    ...mapGetters('params', ['filters']),
    slug() {
      return this.artwork.slug;
    },
    isStarred() {
      if (!this.activeCart) return false;

      return this.activeCart.artwork_ids.includes(this.artwork.id);
    },
    mediaImage() {
      if (!this.artwork.medias) {
        return [];
      }

      return this.artwork.medias.filter((i) => i.type === 'image');
    },
    filtersQuery() {
      return FilterBuilder.toQueryParam(this.filters);
    },
    mediaVideoAudio() {
      if (!this.artwork.medias) {
        return [];
      }

      return this.artwork.medias.filter((i) => i.type !== 'image');
    },
    thumbIconClass() {
      return {
        'fa-music': this.artwork.medias.filter((m) => m.type === 'audio' && m.header_aggregate === false).length > 0,
        'fa-video-camera':
          this.artwork.medias.filter((m) => m.type === 'video' && m.header_aggregate === false).length > 0,
      };
    },
  },
  data() {
    return {
      noImage: unavailableImage,
    };
  },
};
</script>
<style lang="less">
.art-mosaic {
  margin-bottom: 14px;
  margin-right: 7px;
  margin-left: 7px;
  list-style-type: none;
  border: 3px solid transparent;
  color: @default-link-hover-color;

  .content {
    width: 145px;
    height: 145px;
    padding: 22px;
    position: relative;
    display: flex;
    background-color: @mosaic-item-background;
  }

  .no-image {
    .vertical-align;
    text-align: center;
  }

  .thumb-icon {
    margin-top: 30%;
    vertical-align: middle;
    text-align: center;
  }

  img {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .add-favourite {
    position: absolute;
    top: 0px;
    right: 5px;
  }

  &.box-starred {
    border: 3px solid @chalky;
  }
}

.no-image-content {
  background-color: #000;
  position: relative;
}
</style>
