<template>
  <HeaderWrapper :headerOpened="headerOpened" :toggleHeader="toggleHeader">
    <div class="row mb-4" v-show="headerOpened">
      <ArtCounter class="order-1 w-auto flex-grow-1" />
      <SearchBar class="order-3 order-md-2" />
      <MenuBar class="w-auto col-md-3 order-2 order-md-3" />
    </div>
    <FilterBar />
    <FilterContent />
    <ArtworksTextNoteToggleButton :showTextNote="showTextNote" v-if="textNote" />
  </HeaderWrapper>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import FilterBar from './FilterBar.vue';
import FilterContent from './FilterContent.vue';
import ArtCounter from './ArtCounter.vue';
import SearchBar from './SearchBar.vue';
import MenuBar from './MenuBar.vue';
import HeaderWrapper from './HeaderWrapper.vue';
import ArtworksTextNoteToggleButton from './ArtworksTextNoteToggleButton.vue';

export default {
  name: 'HeaderMain',
  components: {
    ArtCounter,
    SearchBar,
    MenuBar,
    FilterBar,
    FilterContent,
    ArtworksTextNoteToggleButton,
    HeaderWrapper,
  },
  methods: {
    toggleHeader() {
      this.headerOpened = !this.headerOpened;
    },
  },
  mounted() {
    if (this.$isMobile()) {
      this.headerOpened = false;
    }
  },
  computed: {
    ...mapGetters('artworks', ['textNote']),
    ...mapState('route', ['query']),
    showTextNote() {
      const showTextNote = [true, 'true'].indexOf(this.query.show_text_note) >= 0 ? true : false;
      return this.textNote && showTextNote;
    },
  },
  data() {
    return {
      headerOpened: true,
    };
  },
};
</script>
