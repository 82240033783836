<template>
  <div class="modal-body">
    <div class="share-header">
      <div class="col-md-7">
        <h2 v-translate>A propos</h2>
      </div>
    </div>
    <div class="share-url-container">
      <p class="row">
        <span class="col-md-5" v-translate>Date des données :</span>
        {{ dates.gcoll2_data || '' }} (publication Navigart3 : {{ dates.import }} )
      </p>
      <p class="row">
        <span class="col-md-5" v-translate>Version de Navigart3 :</span>
        {{ frontVersion }}
      </p>
      <p class="row">
        <span class="col-md-5" v-translate>Version de l'API :</span>
        {{ apiVersion }}
      </p>
    </div>
    <div class="share-footer">
      <div class="col-md-12">
        <p v-translate>
          L'application Navigart3 et son API sont développés par <a href="http://www.videomuseum.fr">Videomuseum</a>.
          <br />Pour toute remarque ou question, envoyer un mail à aideATvideomuseum.fr
        </p>
      </div>
      <div class="col-md-12 text-right">
        <button class="btn btn-defualt modal-close" @click="close()" v-translate>fermer</button>
      </div>
    </div>
  </div>
</template>

<script>
import Publication from 'api/publication';
import { front, api } from 'config';

export default {
  name: 'MenuBarAbout',
  methods: {
    close() {
      this.$root.$emit('bv::hide::modal', 'about');
    },
    async getPublication() {
      const data = await Publication.fetch();
      this.dates = data.dates;
    },
  },
  mounted() {
    this.getPublication();
  },
  data() {
    return {
      dates: {},
      frontVersion: front,
      apiVersion: api,
    };
  },
};
</script>
