<template>
  <div class="autocomplete-group">
    <div class="list-header">
      <div class="filter-name-count">
        <div class="filter-name">{{ agg.displayed_as[lang] }}</div>
        <div class="filter-count">[{{ items.length }} / {{ agg.total }}]</div>
      </div>
      <div class="icons">
        <a href="#" @click.stop.prevent="openFilter(agg.key)" :id="'openFilter' + index">
          <i class="icon-See_All_Filters_FilT_plus"></i>
        </a>
        <b-tooltip boundary="window" placement="right" :target="'openFilter' + index">
          <div v-html="openFilterTooltip"></div>
        </b-tooltip>
      </div>
    </div>
    <ul>
      <li
        class="search-bar-term"
        @click.stop.prevent="applyTerm(agg.key, item.key)"
        v-for="(item, index) in items"
        :key="index"
        :class="{ odd: !(index % 2) }"
      >
        <TooltipEllipses class="term-name" :value="highlight(item)" />
        <div class="term-count">[{{ item.doc_count | number }}]</div>
      </li>
    </ul>
    <AutocompletePagination
      v-if="pagination"
      :total="agg.total"
      :clickHandler="loadMore"
      :perPage="aggsSize"
      :term="agg.key"
      type="aggregations"
    />
    <div class="empty-line" v-else-if="isLast"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { autocompletion } from 'config';

import AutocompletePagination from './AutocompletePagination.vue';
import TooltipEllipses from './TooltipEllipses.vue';
import FilterBuilder from 'lib/filterBuilder';

const aggsSize = autocompletion.popup.filters.number_of_values;

export default {
  name: 'AutocompleteAggregationItem',
  components: {
    AutocompletePagination,
    TooltipEllipses,
  },
  props: ['agg', 'index', 'isLast'],
  computed: {
    ...mapGetters('params', ['locationFilters']),
    openFilterTooltip() {
      return this.$gettext(
        'Accès au filtre complet <br> avec des fonctionnalités supplémentaires <br> (tri, choix de plusieurs valeurs, etc.)'
      );
    },
    pagination() {
      const count = this.isTree ? this.agg.items.length : this.agg.total;
      return count > this.aggsSize;
    },

    items() {
      let items = this.agg.items;
      return this.agg.tree ? items.slice(0, this.currentPage * this.aggsSize) : items;
    },

    isTree() {
      return this.agg.tree;
    },
    lang() {
      return this.$language.current;
    },
  },
  data() {
    return {
      aggsSize: aggsSize,
      currentPage: 1,
    };
  },
  methods: {
    ...mapActions('aggregations', ['toggleAggregation']),
    highlight(term) {
      return term.label;
    },
    openFilter(key) {
      this.toggleAggregation(key);
    },
    applyTerm(aggregation, term) {
      const filter = [{ term: [term], operator: 'and', aggregation, type: this.type || 'term' }];

      const query = this.$route.query;

      this.$router.push({
        query: { ...query, page: 1 },
        params: { filters: FilterBuilder.toURLParam(this.locationFilters.concat(filter)) },
      });
    },
    loadMore(options) {
      if (this.isTree) {
        this.currentPage = options.page;
      } else {
        this.$emit('loadMore', options);
      }
    },
  },
};
</script>
