<template>
  <div class="filter-content col-md-12" v-if="aggregation" v-click-outside="closeFilter">
    <div class="col-md-6">
      <div class="col-md-10 single-filter no-tree no-animate" v-if="!aggregationObject.tree">
        <div class="search" v-if="!isGroup">
          <div class="select-group d-flex justify-content-between">
            <div class="d-flex">
              <div>
                <label class="radio inline">
                  <input v-model="type" type="radio" name="selectOptions" value="term" />
                  <translate>un choix</translate>
                </label>
              </div>
              <div>
                <label class="radio inline">
                  <input v-model="type" type="radio" name="selectOptions" value="multi_term" />
                  <translate>plusieurs choix</translate>
                </label>
              </div>
            </div>
            <div v-if="hasYear" class="d-flex flex-grow-1">
              <div>
                <label class="radio inline">
                  <input v-model="type" type="radio" name="selectOptions" value="range" />
                  <span>
                    <translate>avant</translate> | <translate>après</translate> |
                    <translate>entre</translate>
                  </span>
                </label>
              </div>
            </div>
            <PrintLink v-if="type != 'range'" link="facets" :title="titles.print" />
          </div>
          <input
            class="form-control"
            prevent-arrow-keys
            type="text"
            v-model="termWord"
            @input="onSearch"
            v-focus="focused"
            @focus="focused = true"
            @blur="focused = false"
            v-if="type != 'range'"
          />
        </div>
        <div class="filter-group" v-if="terms.length && type != 'range'">
          <div class="sort">
            <FilterSort :onSort="onSort" :termOrder="termOrder" sortValue="term">{{
              aggregationObject.displayed_as[this.lang]
            }}</FilterSort>
            <FilterSort :onSort="onSort" :termOrder="termOrder" sortValue="count">{{ filterLabel }}</FilterSort>
          </div>

          <ul>
            <FilterItem
              v-for="(term, index) in terms"
              :model="term"
              :key="index"
              :class="{ odd: !(index % 2) }"
              :type="type"
              v-model="checked"
              class="item filter-item no-tree"
            />
          </ul>

          <Pagination
            v-model="termPage"
            :click-handler="onUpdatePage"
            :total="aggregationObject.total"
            :perPage="12"
            :containerClass="'pagination'"
          />
          <div class="filter-actions" v-show="type == 'multi_term'">
            <button class="button" key="reset-button" @click="checked = []" v-translate>Annuler</button>
            <button class="button" :disabled="!checked.length" @click="applyMultiple()" v-translate>Valider</button>
          </div>
        </div>

        <div v-if="hasYear && type == 'range' && loading === false" class="mx-2 mt-4">
          <FilterRange
            :aggregation="range.key"
            :min="range.min"
            :max="range.max"
            :showLabel="isGroup"
            :group="group"
            v-on:setGroup="updateGroup"
            v-on:rangeUpdate="updateCounter"
            :key="range.key"
            v-for="range in aggregationObject.numerical_values"
          >
          </FilterRange>

          <h2 class="counter text-center my-2">
            <span class="filtered">[{{ localFiltered | number }}</span>
            /
            <span class="total">{{ totalCount | number }}]</span>
          </h2>

          <div class="filter-actions text-center my-4" localFiltered>
            <button
              class="btn btn-round"
              key="submit-button"
              :disabled="!localFiltered"
              @click="applyRange"
              v-translate
            >
              Valider
            </button>
          </div>
        </div>
      </div>

      <FilterTree v-if="aggregationObject.tree" @search="onSearch" class="col-md-10 no-animate" />

      <b-modal id="filterModal" hide-header hide-footer lazy body-class="tree-modal filter-content">
        <FilterTree @search="onSearch" :modalOpen="true" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FilterSort from './FilterSort.vue';
import Pagination from './Pagination.vue';
import FilterItem from './FilterItem.vue';
import FilterTree from './FilterTree.vue';
import FilterRange from './FilterRange.vue';
import PrintLink from './PrintLink.vue';
import { movementParameters } from 'config';

import Artwork from 'api/artwork';
import FilterBuilder from 'lib/filterBuilder';

import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'FilterContent',
  components: {
    FilterSort,
    FilterTree,
    FilterItem,
    Pagination,
    PrintLink,
    FilterRange,
  },
  computed: {
    ...mapGetters('artworks', ['totalCount', 'filteredCount']),
    ...mapGetters('aggregations', ['aggregations', 'aggregation', 'terms', 'loading', 'numericalValues']),
    ...mapGetters('params', ['termOrder', 'termPage', 'numericalRange', 'locationFilters']),
    ...mapGetters('autocomplete', ['query']),
    hasYear() {
      return (
        this.aggregationObject &&
        (this.aggregationObject.numerical_value ||
          (this.aggregationObject.hasOwnProperty('numerical_values') && this.aggregationObject.numerical_values))
      );
    },
    isGroup() {
      return this.aggregationObject.group;
    },
    aggregationObject() {
      return this.aggregations[this.aggregation];
    },
    termWord: {
      get() {
        return this.$store.state.autocomplete.query;
      },
      set(value) {
        this.$store.commit('autocomplete/UPDATE_QUERY', value);
      },
    },
    lang() {
      return this.$language.current;
    },
    filterLabel() {
      const { name } = this.$route;
      return name === 'Movements'
        ? movementParameters.sort_counter_label[this.lang]
        : this.$gettext('nb. total oeuvre');
    },
    titles() {
      return {
        print: this.$gettext('Imprimer la liste de toutes les valeurs du filtre'),
      };
    },
  },
  methods: {
    ...mapActions('aggregations', ['fetchTerms', 'toggleAggregation']),
    closeFilter() {
      if (this.aggregation && !this.loading && !this.loadingCounter) {
        this.toggleAggregation(null);
      }
    },
    updateGroup(value) {
      this.group = [...this.group.filter((g) => g.key !== value.key), value];
      this.updateCounter();
    },
    applyMultiple() {
      const filter = [{ term: this.checked, operator: 'and', aggregation: this.aggregation, type: this.type }];

      const query = this.$route.query;

      this.$router.push({
        query: { ...query, page: 1 },
        params: { filters: FilterBuilder.toURLParam(this.locationFilters.concat(filter)) },
      });
    },
    async updateCounter() {
      this.loadingCounter = true;
      const { endpoint = 'artworks' } = this.$router.currentRoute.meta;
      const stateParams = cloneDeep(this.$store.state.params);
      const query = this.$route.query;
      const favorites = this.$route.query.favorites;
      try {
        this.group.map((g) => {
          const cf = stateParams.filters.find((f) => f.aggregation === g.key) || {
            aggregation: g.key,
            operator: 'and',
            term: [[g.min, g.max].join('__')],
            type: 'range',
          };

          if (
            this.numericalValues[g.key].max !== parseInt(g.max) ||
            this.numericalValues[g.key].min !== parseInt(g.min)
          ) {
            const currentFilter = {
              aggregation: g.key,
              operator: 'and',
              term: [[g.min, g.max].join('__')],
              type: 'range',
            };
            stateParams.filters = [...this.locationFilters.filter((f) => f.aggregation != g.key), currentFilter];
          } else if (cf) {
            stateParams.filters = [...this.locationFilters.filter((f) => f.aggregation != g.key), cf];
          }
        });

        let params = Object.assign(
          { size: 0 },
          FilterBuilder.toApiParam(
            Object.assign({}, stateParams, query, { displayed_cart_id: favorites }, { endpoint })
          ),
          { term: this.aggregation }
        );
        const { filteredCount } = await Artwork.fetch(params, endpoint);
        this.loadingCounter = false;
        this.localFiltered = filteredCount || 0;
      } catch (err) {
        this.loadingCounter = false;
      }
    },
    applyRange() {
      const groups = this.group.reduce((acc, g) => {
        if (
          this.numericalValues[g.key].max !== parseInt(g.max) ||
          this.numericalValues[g.key].min !== parseInt(g.min)
        ) {
          acc.push({
            aggregation: g.key,
            term: [g.min, g.max],
            type: 'range',
            operator: 'and',
          });
        }

        return acc;
      }, []);

      const query = this.$route.query;

      this.$router.push({
        query: { ...query, page: 1 },
        params: { filters: FilterBuilder.toURLParam(this.locationFilters.concat(groups)) },
      });
    },
    onSort(sortBy, sortDir) {
      this.$store.commit('params/SET_TERM_ORDER', `${sortBy}:${sortDir}`);
    },
    onUpdatePage(page) {
      this.$store.commit('params/SET_TERM_PAGE', page);
    },
    onSearch() {
      this.fetchTerms();
    },
  },
  watch: {
    aggregation(newValue, oldValue) {
      if (newValue) {
        this.$nextTick(() => {
          if (this.hasYear) {
            this.type = 'range';
          } else {
            this.type = 'term';
          }
          this.checked = [];
          this.focused = true;
        });
      }
    },
    loading(newValue, oldValue) {
      this.localFiltered = 0;
      if (newValue == false && this.hasYear) {
        this.loadingCounter = true;
        this.group = [];
        if (this.aggregationObject.numerical_values) {
          this.aggregationObject.numerical_values.map((range) => {
            this.group.push(range);
          });
        }
        if (this.isGroup) {
          this.localFiltered = this.filteredCount;
        } else {
          this.updateCounter();
        }
      }
    },
  },
  data() {
    return {
      type: 'term',
      checked: [],
      focused: false,
      localFiltered: 0,
      loadingCounter: false,
      group: [],
    };
  },
};
</script>

<style scoped lang="less">
.filter-actions {
  .btn-round {
    background-color: @art-navigation-page-number-background;
    color: @art-navigation-link-color;
    border: none;
  }
}
</style>
