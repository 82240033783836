import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';

const Reversefootnote = {
  bind(el, binding, vnode) {
    const href = el.getAttribute('href');
    const footnoteId = href.replace(/#/, '');
    el.setAttribute('title', vnode.context.$gettext('retour au texte'));
    el.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      const footnoteEl = document.getElementById(footnoteId);
      const artworkTextNote = document.getElementsByClassName('art-notice');
      const container = artworkTextNote.length ? '.notice-content' : 'body';
      VueScrollTo.scrollTo(footnoteEl, {
        container,
        offset: -133,
        force: true,
        x: false,
        y: true,
      });
    });
  },
};
Vue.directive('reversefootnote', Reversefootnote);

export default Reversefootnote;
