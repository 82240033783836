import * as mutations from '../mutation-types';
import Carts from 'api/carts';
import Auth from 'api/auth';

export default {
  async getAll({ commit }) {
    const carts = await Carts.getAll();
    commit(mutations.CARTS_SET_ALL, carts);
  },
  async getActive({ commit }) {
    const activeCart = await Carts.getActive();
    commit(mutations.CARTS_SET_ACTIVE, { activeCart, noPulse: true });
  },

  async fetchUnselectAllStatus({ commit, rootState, rootGetters }) {
    const { activeCart } = rootState.carts;
    const { filteredCount } = rootState.artworks;
    let artworkParams = rootGetters['params/artworkApi'];

    if (!activeCart) {
      return;
    }

    if (activeCart.artwork_ids.length < filteredCount) {
      commit(mutations.CARTS_SET_UNSELECT_ALL_STATUS, false);
      return;
    }

    let params = Object.assign(artworkParams, { size: 0, from: 0, displayed_cart_id: activeCart.id });

    try {
      const { filteredCount: cartArtworksCount } = await Carts.unselectAllSStatus(params);
      if (cartArtworksCount < filteredCount) {
        commit(mutations.CARTS_SET_UNSELECT_ALL_STATUS, false);
      } else {
        commit(mutations.CARTS_SET_UNSELECT_ALL_STATUS, true);
      }
    } catch (e) {
      console.log(e);
    }
  },

  async setActive({ commit, dispatch }, data) {
    try {
      const cart = await Auth.setActiveCart({ cart_id: data.id });
      commit(mutations.CARTS_SET_ACTIVE, { activeCart: cart });
      setTimeout(() => {
        commit('assign', { name: 'pulseCartId', data: null });
      }, 800);
    } catch (e) {
      // console.log(e)
    }
  },
  async create({ commit }, data) {
    try {
      const cart = await Carts.create(data);
      commit(mutations.CARTS_ADD_CART, cart);
      commit('assign', { name: 'isNewCartOpen', data: false });
      setTimeout(() => {
        commit('assign', { name: 'pulseCartId', data: null });
      }, 800);
    } catch (e) {
      if ([422].includes(e.response.status)) {
        commit('assign', { name: 'createCartError', data: true });
      }
    }
  },
  async update({ commit }, { cartId, data }) {
    const cart = await Carts.update(cartId, data);
    commit(mutations.CARTS_UPDATE_CART, cart);
  },
  async remove({ commit }, cart) {
    const activeCart = await Carts.delete(cart.id);
    commit(mutations.CARTS_REMOVE_CART, cart.id);
    commit(mutations.CARTS_SET_ACTIVE, { activeCart });
    setTimeout(() => {
      commit('assign', { name: 'pulseCartId', data: null });
    }, 800);
  },
  async send({}, { cart_id }) {
    await Carts.send(cart_id);
  },
  async addArtwork({ commit, state }, artworkId) {
    const activeCart = await Carts.addArtwork(artworkId, state.activeCart);
    commit(mutations.CARTS_SET_ACTIVE, { activeCart });
    setTimeout(() => {
      commit('assign', { name: 'pulseCartId', data: null });
    }, 800);
  },
  async removeArtwork({ commit, state }, artworkId) {
    const activeCart = await Carts.removeArtwork(artworkId, state.activeCart);
    commit(mutations.CARTS_SET_ACTIVE, { activeCart });
    setTimeout(() => {
      commit('assign', { name: 'pulseCartId', data: null });
    }, 800);
  },
  async selectAll({ commit, state, rootGetters }) {
    let params = rootGetters['params/artworkApi'];
    const activeCart = await Carts.selectAll(params, state.activeCart);
    commit(mutations.CARTS_SET_ACTIVE, { activeCart });
    setTimeout(() => {
      commit('assign', { name: 'pulseCartId', data: null });
    }, 800);
  },

  async unselectAll({ commit, state, rootGetters }) {
    let params = rootGetters['params/artworkApi'];
    const activeCart = await Carts.unselectAll(params, state.activeCart);
    commit(mutations.CARTS_SET_ACTIVE, { activeCart });
    setTimeout(() => {
      commit('assign', { name: 'pulseCartId', data: null });
    }, 800);
  },

  async updateCollaborators({ commit }, params) {
    const cart = await Carts.updateCollaborators(params);
    commit(mutations.CARTS_UPDATE_CART, cart);
  },

  async summary({ commit }, { id, type = 'import' }) {
    const summary = await Carts.summary(id, { type });
    commit('assign', { name: `${type}Summary`, data: summary });
  },

  add({ commit }, cart) {
    commit(mutations.CARTS_ADD_CART, cart);
    setTimeout(() => {
      commit('assign', { name: 'pulseCartId', data: null });
    }, 800);
  },
};
