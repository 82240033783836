import {
  INIT,
  UPDATE_SUMMARY,
  UPDATE_TITLE,
  INCREMENT_ACTIVE_REQUESTS,
  DECREMENT_ACTIVE_REQUESTS,
  UPDATE_NOTIFY_GRANTED,
  UPDATE_COLUMN_WIDTH,
  UPDATE_PREFERENCES,
} from '../mutation-types';
import { showSummary } from 'config';
import { localStorageIsSupported, StorageVerion } from '../../util/storage';
import merge from 'lodash.merge';

export default {
  [INIT](state) {
    if (localStorageIsSupported() && localStorage.getItem(`navigartLocal${StorageVerion}`)) {
      state = Object.assign(state, JSON.parse(localStorage.getItem(`navigartLocal${StorageVerion}`)));
    } else {
      state = Object.assign(state, { showTextNoteSummary: showSummary, showTextNoteTitle: true });
    }
  },
  [UPDATE_SUMMARY](state, data) {
    state.showTextNoteSummary = data;
  },
  [UPDATE_TITLE](state, data) {
    state.showTextNoteTitle = data;
  },
  [INCREMENT_ACTIVE_REQUESTS](state) {
    state.activeRequests++;
  },
  [DECREMENT_ACTIVE_REQUESTS](state) {
    if (state.activeRequests > 0) {
      state.activeRequests--;
    }
  },
  [UPDATE_NOTIFY_GRANTED](state, notifyGranted) {
    state.notifyGranted = notifyGranted;
  },
  [UPDATE_COLUMN_WIDTH](state, data) {
    state.leftColumnWidth = data.leftColumnWidth;
    state.rightColumnWidth = data.rightColumnWidth;
  },
  [UPDATE_PREFERENCES](state, data) {
    state.preferences = merge({}, state.preferences, data);
  },
};
