<template>
  <div :style="styles" class="notice-window" v-if="opened">
    <a href class="close-window" @click.stop.prevent="close">
      <i class="icon-notice_close"></i>
    </a>

    <div class="art-pagination-internal" v-if="previousSize > 1">
      <a href class="prev" @click.stop.prevent="prevPreview()">
        <i class="icon-fleche_collapse_back"></i>
      </a>
      <span class="counter">{{ currentPreviewIndex + 1 }}/{{ previousSize }}</span>
      <a href class="next" @click.stop.prevent="nextPreview()">
        <i class="icon-fleche_collapse"></i>
      </a>
    </div>

    <ul class="details col-md-6">
      <li class="author">
        <h2>{{ artwork.authors_notice }}</h2>
        <p v-html="artwork.authors_name_complement" v-if="artwork.authors_name_complement"></p>
        <p v-html="artwork.authors_birth_death"></p>
      </li>
      <li>
        <h3>{{ artwork.title_notice }}</h3>
        <p v-if="artwork.title_translated">({{ artwork.title_translated }})</p>
        <p v-if="artwork.title_attributed">Titre attribué : {{ artwork.title_attributed }}</p>
        <p v-if="artwork.title_serial">de la série {{ artwork.title_serial }}</p>
        <p v-if="artwork.title_old_by_artist">Ancien titre donné par l\'artiste : {{ artwork.title_old_by_artist }}</p>
        <p v-if="artwork.title_old">Ancien titre : {{ artwork.title_old }}</p>
        <p v-if="artwork.subtitle">Sous-titre : {{ artwork.subtitle }}</p>
        <p v-html="artwork.date_creation"></p>
        <p v-html="artwork.realisation_location"></p>
        <p v-html="artwork.production_circumstances"></p>
      </li>
      <li v-hide-if-empty v-for="textNote in artwork.artwork_text_notes" :key="textNote.id">
        <a href class="serif" @click.stop.prevent>{{ textNote.label }}</a>
      </li>
      <li>
        <p v-html="artwork.tirage" v-if="artwork.tirage"></p>
        <p v-html="artwork.collaborators" v-if="artwork.collaborators"></p>
        <p v-html="artwork.tirage_photo"></p>
        <p v-html="artwork.collaborators_design"></p>
        <p v-html="artwork.domain_description_mst"></p>
        <p v-html="artwork.creation_stage"></p>
        <p v-html="artwork.dimensions"></p>
        <p v-html="artwork.dimensions_additional"></p>
        <p v-html="artwork.inscriptions"></p>
        <p v-html="artwork.comments"></p>
      </li>
      <li>
        <p v-html="artwork.acquisition"></p>
        <p v-if="artwork.inventory">Inv.: {{ artwork.inventory }}</p>
        <p v-if="artwork.deposit_number">
          <span v-translate>N° de dépôt</span>
          : {{ artwork.deposit_number }}
        </p>
        <p v-if="artwork.localisation_if_deposit">{{ artwork.localisation_if_deposit }}</p>
      </li>
      <li v-hide-if-empty>
        <p v-html="artwork.collection"></p>
      </li>
      <li v-if="image">
        <p class="copyright" v-html="image.copyright"></p>
        <p v-if="image.photographer">Photo : {{ image.photographer }}</p>
        <p v-html="image.legend"></p>
      </li>
      <li v-if="artwork.hanging" class="details">
        <span v-translate>Oeuvre exposée au</span>
        {{ artwork.hanging }}
      </li>
      <router-link
        v-if="sorted.length === 1"
        :to="{ name: 'artwork', params: { id: ids[0] } }"
        class="more-details pull-right"
      >
        <span v-translate>Voir le détail de l’œuvre citée</span>
        <i class="icon-See more-details-icon"></i>
      </router-link>

      <router-link
        v-if="sorted.length > 1"
        :to="{ name: 'Artworks', query: { ids: ids.join(',') } }"
        class="more-details pull-right"
      >
        <span v-translate>Voir le détail de toutes les œuvres citées</span>
        <i class="icon-See more-details-icon"></i>
      </router-link>
    </ul>

    <div class="art col-md-6" v-if="artwork && image">
      <ArtworkImage :image="image" version="400" v-if="image" />
    </div>
    <div class="art-pagination" v-if="artworks.length > 1">
      <a href class="prev" @click.stop.prevent="prevArtwork()">
        <i class="icon-PREVIOUS"></i>
      </a>
      <a href class="next" @click.stop.prevent="nextArtwork()">
        <i class="icon-Next"></i>
      </a>
      <div class="counter">{{ index + 1 }}/{{ artworks.length }}</div>
    </div>
  </div>
</template>
<script>
import Artwork from 'api/artwork';
import ArtworkImage from './ArtworkImage.vue';
import VueScrollTo from 'vue-scrollto';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Preview',
  components: {
    ArtworkImage,
  },
  mounted() {
    this.$root.$off('openPreview', this.open);
    this.$root.$on('openPreview', this.open);
  },
  destroyed() {
    this.$root.$off('openPreview', this.open);
  },
  computed: {
    ...mapState('textNotes', ['previews', 'textNote']),
    ...mapGetters('textNotes', ['previewByElementId']),
    previousSize() {
      return this.previews.length;
    },
    sorted() {
      return this.artworks.sort((a, b) => {
        var indexA = this.ids.indexOf(a._id);
        var indexB = this.ids.indexOf(b._id);

        if (indexA < indexB) {
          return -1;
        } else if (indexA > indexB) {
          return 1;
        } else {
          return 0;
        }
      });
    },
    currentPreviewIndex() {
      return this.previews.indexOf(this.preview);
    },
    artwork() {
      return this.sorted[this.index];
    },
    image() {
      if (!this.artwork.medias) {
        return null;
      }

      return this.artwork.medias.filter((i) => i.type === 'image')[0];
    },
    styles() {
      return this.preview.position;
    },
    ids() {
      return this.preview.ids.split(',');
    },
  },
  methods: {
    scrollTo(elementId) {
      VueScrollTo.scrollTo(`#${elementId}`, {
        container: '.scrollbar',
        offset: -350,
        force: true,
        x: false,
        y: true,
      });
    },
    async open(id, pagination = false) {
      if (!this.opened || pagination) {
        const preview = this.previewByElementId(id);
        const { artworks } = await Artwork.fetch({ ids: preview.ids, size: 10000 });
        this.preview = preview;
        this.artworks = artworks;
        this.scrollTo(this.preview.elementId);
        this.$root.$emit('previewOpened', preview);
      }

      this.opened = pagination || !this.opened;
      this.index = 0;
    },

    close() {
      this.opened = false;
      this.$root.$emit('previewOpened', {});
    },

    openCollection() {
      if (this.ids.length === 1) {
        this.$router.push({ name: 'artwork', params: { id: this.ids[0] } });
      } else {
        this.$router.push({ name: 'Artworks', query: { ids: this.ids.join(',') } });
      }
    },

    nextArtwork() {
      if (this.index === this.artworks.length - 1) {
        return;
      }
      this.index += 1;
    },

    prevArtwork() {
      if (this.index === 0) return;
      this.index -= 1;
    },

    prevPreview() {
      const index = this.currentPreviewIndex;
      if (index >= 1) {
        const preview = this.previews[index - 1];
        this.open(preview.elementId, true);
      }
    },

    nextPreview() {
      const index = this.currentPreviewIndex;
      if (index < this.previews.length - 1) {
        const preview = this.previews[index + 1];
        this.open(preview.elementId, true);
      }
    },
  },
  watch: {
    textNote(newValue, oldValue) {
      this.close();
    },
  },
  data() {
    return {
      artworks: [],
      opened: false,
      index: 0,
      preview: {},
    };
  },
};
</script>
<style lang="less" scoped>
.art {
  background-color: transparent;
}
</style>
