import { facets } from 'config';
import keyToLabel from 'lib/keyToLabel';

import {
  AGGREGATION_COLLECTION,
  AGGREGATION_TERMS,
  AGGREGATION_OBJECT,
  UPDATE_TOTAL,
  UPDATE_RANGE,
  START_FETCH_TERMS,
  END_FETCH_TERMS,
} from '../mutation-types';

const unwrap = ({
  sort_by,
  sort_dir,
  with_text_note,
  checkbox,
  checkbox_pos,
  years_combined,
  term,
  numerical_value,
}) => ({
  sort_by,
  sort_dir,
  with_text_note,
  checkbox,
  checkbox_pos,
  years_combined,
  term,
  numerical_value: numerical_value ? true : false,
});

export default {
  [AGGREGATION_COLLECTION](state, { aggregations, endpoint }) {
    state.aggregations = Object.entries(aggregations).reduce((acc, pair) => {
      const [key, agg] = pair;
      const config = unwrap(facets[endpoint][key] || {});
      if (agg.pos || config.checkbox || agg.group) {
        return { ...acc, [key]: Object.assign(agg, config) };
      } else {
        return acc;
      }
    }, {});
  },
  [UPDATE_TOTAL](state, data) {
    Object.entries(state.aggregations).map((pair) => {
      const [key, agg] = pair;
      if (data.aggregations[key]) {
        state.aggregations[key].total = data.aggregations[key].total || 0;

        if (agg.checkbox) {
          state.aggregations[key].terms = data.aggregations[key].terms;
        }
      }
    });
  },
  [UPDATE_RANGE](state, data) {
    Object.entries(state.aggregations).map((pair) => {
      const [key, _] = pair;
      if (data.aggregations[key]) {
        state.aggregations[key].numerical_values = data.aggregations[key].numerical_values;
      }
    });
  },
  [AGGREGATION_TERMS](state, data) {
    state.terms = data.map((term) => {
      term.label = keyToLabel(term.key);
      return term;
    });
  },
  [AGGREGATION_OBJECT](state, data) {
    state.aggregation = data;
  },

  [START_FETCH_TERMS](state) {
    state.loading = true;
  },

  [END_FETCH_TERMS](state) {
    state.loading = false;
  },
};
