<template>
  <div class="empty-container">
    <div class="col-md-3"></div>
    <div class="empty-box col-md-5 col-sm-12 col-xs-12">
      <div class="empty-header">
        <div class="empty-sign">Ø</div>
        <span v-translate>Oups, pas de réponse !!??</span>
      </div>
      <div class="empty-content">
        <div class="empty-content-header">
          {{ emptyHeader }}
        </div>
        <ul>
          <li v-for="(item, index) in emptyList" :key="index">{{ item[lang] }}</li>
        </ul>
      </div>
    </div>
    <div class="col-md-4"></div>
  </div>
</template>
<script>
import { interfaceStrings } from 'config';

export default {
  name: 'NoResults',
  computed: {
    lang() {
      return this.$language.current;
    },
    emptyHeader() {
      return interfaceStrings.empty.header[this.lang];
    },
    emptyList() {
      return interfaceStrings.empty.list;
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.empty-container {
  display: flex;
  margin-top: 50px;
}

.empty-box {
  z-index: 1;
  padding: 4rem;
  background-color: white;
  box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.25);
}

.empty-header {
  display: flex;
  align-items: center;
  font-size: 30px;
  color: #757575;
  border-bottom: 1px solid #e5e5e5;
  span {
    z-index: 999;
  }
}

.empty-sign {
  font-size: 100px;
  color: #e5e5e5;
  position: relative;
  left: 45px;
  z-index: 998;
}

.empty-content {
  padding: 2rem;
  ul {
    margin: 20px 0;
    padding: 0 20px;
    li {
      margin: 10px 0;
    }
  }
}

.empty-content-header {
  font-size: 18px;
  font-weight: 600;
}
</style>
