<template>
  <div class="details-ellipsis">
    <span ref="artMosaic">
      <i class="fa fa-ellipsis-h"></i>
    </span>
    <ArtworkGridTooltip :refs="$refs" :artwork="artwork" />
  </div>
</template>
<script>
import ArtworkGridTooltip from './ArtworkGridTooltip.vue';

export default {
  name: 'ArtworkGridDetailsEllipsis',
  props: {
    refs: Object,
    artwork: Object,
  },
  components: {
    ArtworkGridTooltip,
  },
};
</script>
