<template>
  <div :class="classObjct">
    <div class="loader-icon"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'Loader',
  computed: {
    ...mapState('local', ['activeRequests']),
    classObjct() {
      return {
        show: this.activeRequests > 0,
        loader: true,
      };
    },
  },
};
</script>
