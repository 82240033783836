<template>
  <form @submit.prevent="submit">
    <div class="modal-header">
      <div class="row w-100">
        <div class="col-md-8">
          <h3 class="modal-title" v-translate>Mon compte</h3>
        </div>
        <div class="col-md-4">
          <div class="float-right">
            <a href class="btn btn-round btn-round-white modal-close" @click.prevent="close" v-translate>Annuler</a>
            <button class="btn btn-round btn-round-white" type="submit" v-translate>Valider</button>
          </div>
        </div>
      </div>
    </div>

    <perfect-scrollbar class="scroller scroll-always-visible">
      <div class="modal-body">
        <section class>
          <div class="row w-100 form-group" v-if="true">
            <label for="email" class="col-sm-2 col-form-label" v-translate>Login (mail)</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" v-model="currentUser.email" disabled="true" id="email" />
            </div>
          </div>
          <div class="row w-100 form-group" v-if="true">
            <label for="name" class="col-sm-2 col-form-label" v-translate>Nom d'utilisateur</label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                name="name"
                :value="currentUser.name"
                v-on:input="name = $event.target.value"
              />
            </div>
          </div>
        </section>

        <section class="light">
          <div class="col-sm-12">
            <div class="row">
              <p v-translate>Nom(s) de rédacteur pour notice rédactionnelle</p>

              <div class="row w-100" v-for="(alias, index) in aliasNames" :key="index">
                <div class="offset-sm-2 col-sm-8 input-group form-group">
                  <p class="form-control" disabled="true" v-html="alias"></p>
                  <span class="input-group-append">
                    <a href="#" @click.prevent="removeAlias(index)">
                      <i class="icon-trash"></i>
                    </a>
                  </span>
                </div>
              </div>

              <div class="row w-100" v-if="!aliasNamesPresent">
                <div class="offset-sm-2 col-sm-8 input-group">
                  <p class="form-control" v-translate disabled="true">Pas de nom de rédacteur saisi</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="dark">
          <div class="row w-100" v-if="true">
            <label for="name" class="col-sm-2 col-form-label" v-translate>Ajouter nom</label>
            <div class="col-sm-8">
              <input
                v-model="newAlias"
                prevent-arrow-keys
                type="text"
                class="form-control"
                name="alias_name"
                :placeholder="titles.alias"
              />
            </div>
            <div class="col-sm-2">
              <a class="btn btn-round btn-round-white pull-right" @click="addAlias" v-translate>Ajouter</a>
            </div>
          </div>
        </section>
      </div>
    </perfect-scrollbar>
  </form>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'MenuBarAccountModal',
  computed: {
    ...mapState('auth', ['currentUser']),
    aliasNamesPresent() {
      return this.aliasNames.length > 0;
    },
    titles() {
      return {
        alias: this.$gettext('Saisir ici un nom de rédacteur pour les notices rédactionnelles'),
      };
    },
  },
  methods: {
    addAlias() {
      if (!this.newAlias) {
        return;
      }
      this.aliasNames.push(this.newAlias);
      this.newAlias = '';
    },
    removeAlias(index) {
      this.aliasNames.splice(index, 1);
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'accountEdit');
    },
    submit() {
      const params = {
        name: this.name,
        alias_names: this.aliasNames,
      };

      this.$store.dispatch('auth/update', params);
      this.close();
    },
  },
  mounted() {
    this.aliasNames = this.currentUser.alias_names.slice();
    this.name = this.currentUser.name.slice();
  },
  data() {
    return {
      newAlias: '',
      aliasNames: [],
      name: '',
    };
  },
};
</script>
