<template>
  <perfect-scrollbar
    ref="ps"
    id="artworks-list"
    class="scrollbar scroll-always-visible"
    :options="scrollOptions"
    v-on:ps-scroll-y="onScroll"
  >
    <div class="artwork-list">
      <ArtworksActionsBar v-show="!showTextNote" :reachStart="reachStart" />

      <transition name="fade">
        <div class="art-items" v-show="!showTextNote">
          <div ref="page" :data-page="page" class="page" :key="page" v-for="(artworks, page) in artworkPages">
            <component
              :id="'artwork-' + id"
              class="step7"
              :is="previousLayout"
              :key="id"
              :artwork="artwork"
              v-for="(artwork, id) in artworks"
            />
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div class="container" v-if="showTextNote">
          <TextNote :textNote="textNote" :isSingleArtwork="false" />
        </div>
      </transition>

      <transition name="fade">
        <Pagination
          v-model="page"
          :clickHandler="updatePage"
          :total="filteredCount"
          :perPage="perPage"
          :showEditor="true"
          :showFirst="true"
          containerClass="art-navigation artworks"
          v-show="!showTextNote"
        />
      </transition>
    </div>
  </perfect-scrollbar>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ArtworkGrid from './ArtworkGrid.vue';
import ArtworkMosaic from './ArtworkMosaic.vue';
import ArtworkBox from './ArtworkBox.vue';
import ArtworksActionsBar from './ArtworksActionsBar.vue';
import Pagination from './Pagination.vue';
import TextNote from './TextNote.vue';
import { pageSize } from 'config';
import VueScrollTo from 'vue-scrollto';
import FilterBuilder from 'lib/filterBuilder';

export default {
  name: 'artworks',
  components: {
    ArtworkGrid,
    ArtworkMosaic,
    ArtworkBox,
    ArtworksActionsBar,
    Pagination,
    TextNote,
  },
  created() {
    this.previousLayout = this.layoutComponent();
  },
  mounted() {
    if (this.artworkId) {
      const elementId = `#artwork-${this.artworkId}`;
      VueScrollTo.scrollTo(elementId, {
        container: '#artworks-list',
        force: true,
        x: false,
        y: true,
      });
    }
    this.$store.commit('artworks/assign', { name: 'artworkId', data: null });
  },
  computed: {
    ...mapGetters('artworks', ['artworkPages', 'filteredCount', 'textNote']),
    ...mapGetters('params', ['page', 'layout', 'locationFilters']),
    ...mapState('route', ['query']),
    ...mapState('artworks', ['artworkId']),
    ...mapGetters('infinite', ['direction', 'start', 'end']),
    showTextNote() {
      //TODO improv boolean casting
      const showTextNote = [true, 'true'].indexOf(this.query.show_text_note) >= 0 ? true : false;
      return this.textNote && showTextNote;
    },
    perPage() {
      return pageSize[this.layout] || pageSize.grid;
    },
    pageCount() {
      return Math.ceil(parseInt(this.filteredCount, 10) / parseInt(this.perPage, 10));
    },
  },
  methods: {
    updatePage(page) {
      this.$store.commit('infinite/INIT');
      this.directPageLoad = true;
      const query = this.$route.query;

      this.$router.push({
        query: { ...query, page },
      });
    },
    layoutComponent() {
      switch (this.layout) {
        case 'mosaic':
          return 'ArtworkMosaic';
        case 'box':
          return 'ArtworkBox';
        case 'grid':
          return 'ArtworkGrid';
        default:
          return 'ArtworkGrid';
      }
    },
    mostlyVisible(element) {
      const { lastScrollTop, reach, containerHeight } = this.$refs.ps.ps;
      var elTop = element.offsetTop;
      var elHeight = element.offsetHeight;
      var elBottom = elTop + elHeight;

      return elBottom - elHeight * 0.7 > lastScrollTop && elTop < lastScrollTop + 0.9 * containerHeight;
    },
    onScroll(e) {
      if (this.showTextNote) {
        return;
      }
      const { containerHeight, lastScrollTop, scrollbarYTop, reach } = this.$refs.ps.ps;
      this.reachStart = scrollbarYTop;

      if (Object.keys(this.artworkPages).length === 0 || this.directPageLoad) {
        this.directPageLoad = false;
        return;
      }

      if (reach.y === 'start' && this.page != 1 && this.start != 1) {
        const page = this.page > 1 ? this.page - 1 : this.page;
        const start = this.start || 1;
        this.$store.commit('infinite/LOAD', {
          direction: -1,
          start: start > 1 ? this.start - 1 : page,
          end: this.end ? this.end : this.page,
        });
        const query = this.$route.query;
        const filters = FilterBuilder.toURLParam(this.locationFilters);

        this.$router.push({
          query: { ...query, page: this.start },
          params: { silent: false, filters },
        });
        return;
      }
      if (reach.y === 'end' && this.page != this.pageCount) {
        if (this.end != this.pageCount) {
          this.$store.commit('infinite/LOAD', {
            direction: 1,
            end: this.end ? this.end + 1 : this.page + 1,
            start: this.start ? this.start : this.page,
          });
          const query = this.$route.query;
          const filters = FilterBuilder.toURLParam(this.locationFilters);

          this.$router.push({
            query: { ...query, page: this.end },
            params: { silent: false, filters },
          });
          return;
        }
      }

      if (Math.abs(lastScrollTop - this.lastScrollPos) > containerHeight * 0.1) {
        this.lastScrollPos = lastScrollTop;
        this.$refs.page.forEach((element) => {
          const page = parseInt(element.dataset.page);

          if (this.page != page && this.mostlyVisible(element)) {
            const query = this.$route.query;
            const filters = FilterBuilder.toURLParam(this.locationFilters);

            this.$router.push({
              query: { ...query, page },
              params: { silent: true, filters },
            });
            return;
          }
        });
      }
    },
  },
  data() {
    return {
      reachStart: 0,
      lastScrollPos: 0,
      directPageLoad: false,
      previousLayout: null,
      scrollOptions: {
        suppressScrollX: true,
      },
    };
  },
  watch: {
    showTextNote(to, from) {
      if (to !== from) {
        this.$refs.ps.$el.scrollTop = 0;
      }
    },
    'query.page': function (newValue) {
      if (newValue === 1) {
        this.$store.commit('infinite/INIT');
        this.directPageLoad = true;
      }
    },
    artworkPages(to, from) {
      if (this.direction === 0) {
        this.previousLayout = this.layoutComponent();
        this.$refs.ps.$el.scrollTop = 0;
        this.$refs.ps.ps.update();
      }
    },
  },
};
</script>
