<template>
  <a v-hide-on-mobile href @click.stop.prevent="showModal" v-b-tooltip.hover.left :title="title">
    <i :class="'icon-' + icon"></i>
  </a>
</template>
<script>
import { mapActions } from 'vuex';
import { smdeInitialState } from '../store/artworks/state.js';

export default {
  name: 'TextNoteLink',
  props: {
    title: String,
    icon: String,
    resource: Object,
    artworkEl: Object,
    textNote: Object,
    type: String,
  },
  methods: {
    ...mapActions('textNotes', ['setEditTextNote', 'setNewTextNote']),
    showModal() {
      if (this.textNote) {
        this.setEditTextNote({ textNote: this.textNote, resource: this.resource });
        this.$root.$emit('bv::show::modal', 'contentForm');
      } else {
        this.setNewTextNote({ resource: this.resource, type: this.type, artworkEl: this.artworkEl });
        this.$store.commit('artworks/assign', { name: 'smde', data: smdeInitialState });
        this.$root.$emit('bv::show::modal', 'parametersForm');
      }
    },
  },
};
</script>
