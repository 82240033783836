export default {
  updateExtract(state, { id, note }) {
    if (state.elements) {
      state.elements.map((e) => {
        if (e.text_notes) {
          Object.entries(e.text_notes).map(([key, shortcuts]) => {
            const shorts = shortcuts.find((s) => s.id === id);
            if (shorts) {
              Object.assign(shorts, note);
            }
          });
        }
      });
    }

    if (state.artwork && state.artwork.text_notes) {
      Object.entries(state.artwork.text_notes).map(([key, shortcuts]) => {
        const shorts = shortcuts.find((s) => s.id === id);
        if (shorts) {
          Object.assign(shorts, note);
        }
      });
    }
  },
  createExtract(state, { note, type, isElement, references }) {
    const { element_id } = note;

    if (isElement && state.elements) {
      const id = element_id || state.artwork._id;
      const element = state.elements.find((e) => e.id === id);
      if (element.text_notes && element.text_notes[type] && element.text_notes[type].length >= 0) {
        element.text_notes[type] = [...element.text_notes[type], note];
      } else {
        element.text_notes || (element.text_notes = {});
        element.text_notes[type] = [note];
      }
    } else if (
      state.artwork.text_notes &&
      state.artwork.text_notes[type] &&
      state.artwork.text_notes[type].length >= 0
    ) {
      state.artwork.text_notes[type] = [...state.artwork.text_notes[type].filter((s) => s.id !== note.id), note];
    } else {
      state.artwork.text_notes || (state.artwork.text_notes = {});
      state.artwork.text_notes[type] = [];
      state.artwork.text_notes[type] = [...state.artwork.text_notes[type].filter((s) => s.id !== note.id), note];
    }

    if (state.elements.length) {
      const artworkId = references[0].id;
      const element = state.elements.find((e) => e.id === artworkId);
      if (element) {
        if (element.text_notes && element.text_notes[type] && element.text_notes[type].length >= 0) {
          element.text_notes[type] = [...element.text_notes[type].filter((s) => s.id !== note.id), note];
        } else {
          element.text_notes || (element.text_notes = {});
          element.text_notes[type] = [note];
        }
      }
    }
  },
  removeExtract(state, { shortcut, textNoteId, artworkId }) {
    Object.entries(state.artwork.text_notes).map(([key, _]) => {
      state.artwork.text_notes[key] = [...state.artwork.text_notes[key].filter((s) => s.id !== textNoteId)];
    });

    if (state.elements && state.elements.length && artworkId) {
      const element = state.elements.find((e) => e.id === artworkId);
      Object.entries(element.text_notes).map(([key, _]) => {
        element.text_notes[key] = [...element.text_notes[key].filter((s) => s.id !== textNoteId)];
      });
    }
  },
  assign(state, { name, data }) {
    state[name] = data;
  },
  append(state, { name, data }) {
    state[name] = { ...state[name], ...data };
  },
  prepand(state, { name, data }) {
    state[name] = { ...data, ...state[name] };
  },
  loaded(state) {
    state.loading = false;
  },
  updateTextNotes(state, { record, id }) {
    state.artwork = Object.assign({}, record);
  },
  updateElements(state, { elements }) {
    const updatedElements = state.elements.map((e) => {
      const loadedElement = elements.find((ee) => ee._id === e._id);
      if (loadedElement) {
        e = loadedElement;
      }

      return e;
    });

    state.elements = updatedElements;
  },
};
