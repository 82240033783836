import { mapGetters } from 'vuex';

export default {
  methods: {
    $can(action, subject) {
      if (!this.isAuthenticated) {
        return false;
      }
      const { abilities } = this.currentUser;
      return abilities[subject][action];
    },
  },
  computed: {
    ...mapGetters('auth', ['currentUser', 'isAuthenticated']),
  },
};
