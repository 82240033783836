import { uas_for_title_artwork_TextNote, uas_for_title_movement_TextNote } from 'config';

const textNoteTitle = function (resource, type = 'artwork') {
  let uas = [];

  switch (type) {
    case 'movement': {
      uas = uas_for_title_movement_TextNote;
      break;
    }
    case 'artwork': {
      uas = uas_for_title_artwork_TextNote;
      break;
    }
    case 'artist': {
      uas = ['authors_notice'];
      break;
    }
  }

  return uas
    .reduce((memo, ua) => {
      if (resource[ua]) {
        memo += `${resource[ua]} `;
      }
      return memo;
    }, '')
    .slice(0, -1);
};

export default textNoteTitle;
