<template>
  <div class="favs" v-hide-on-mobile>
    <a v-if="!isAuthenticated" v-b-tooltip.hover.left :title="titles.favorites">
      <i class="icon-favoris_on"></i>
    </a>

    <b-dropdown
      v-if="isAuthenticated"
      @hide="hide"
      variant="link"
      right
      menu-class="dropdown-menu top-tools-dropdown"
      no-caret
      v-b-tooltip.hover.left
      :title="activeCartName"
    >
      <template slot="button-content">
        <i class="icon-favoris_on bar-favoris_on" :class="starClassObject" :disabled="tooltipDisabled"></i>
      </template>
      <ul>
        <b-dropdown-item @click="goToActive">
          <translate>Afficher la sélection en cours:</translate>
          <div v-if="activeCart" class="cart-name">{{ activeCartName }} [{{ activeCartSize }}]</div>
        </b-dropdown-item>
        <div class="big-separator"></div>
        <li class="dropdown-submenu has-children" :class="{ expanded: isCartListOpen }">
          <a @click.prevent="toggleShowCarts" class="toggle-dropdown">
            <translate>Activer une autre sélection</translate>
          </a>
          <ul v-if="isCartListOpen">
            <li
              v-for="cart in currentPageFavs"
              :key="cart.id"
              :class="{ 'no-bullet': isCurrentCart(cart.id) }"
              class="cart-item"
              @click.prevent="isCurrentCart(cart.id) ? goToActive() : setActiveCart(cart)"
            >
              <a v-if="!isCurrentCart(cart.id)">{{ cart.name }} [{{ cart.es_count }}]</a>

              <a v-if="isCurrentCart(cart.id)">
                <i class="icon-favoris_on pull-left star-full"></i>
                {{ activeCartName }} [{{ activeCartSize }}]
              </a>
            </li>
            <MenuBarFavoritesPagination v-if="paginationPresent" />
          </ul>
        </li>
        <div class="big-separator"></div>
        <li class="dropdown-btn">
          <a @click.stop.prevent="setupNewCart" class="btn btn-round btn-round-white" v-if="!isNewCartOpen">
            <translate>Créer une nouvelle sélection</translate>
          </a>
          <form @submit.prevent="createCart" class="input-group" v-if="isNewCartOpen">
            <input v-focus v-model="cartName" class="form-control" type="text" :placeholder="titles.create" />
            <span class="input-group-append">
              <button class="btn btn-outline-secondary" type="submit" v-translate>OK</button>
            </span>
          </form>
          <div class="input-group" v-if="createCartError">
            <translate>Ce nom de dossier de sélection existe déjà. Choisissez un autre nom.</translate>
          </div>
        </li>
        <div class="big-separator"></div>
        <li class="dropdown-btn">
          <a @click.stop.prevent="goToFavorites" class="btn btn-round btn-round-white">
            <translate>Gérer toutes les sélections</translate>
          </a>
        </li>
      </ul>
    </b-dropdown>
    <div class="count">
      <span v-if="activeCart">
        [
        <span>{{ activeCartSize }}</span
        >]
      </span>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import MenuBarFavoritesPagination from './MenuBarFavoritesPagination.vue';

export default {
  name: 'MenuBarFavorites',
  components: { MenuBarFavoritesPagination },
  computed: {
    ...mapGetters('carts', ['all', 'activeCart']),
    ...mapState('auth', ['isAuthenticated', 'currentUser']),
    ...mapState('carts', ['currentPage', 'numPerPage', 'pulseCartId', 'selectedCartId', 'createCartError']),
    starClassObject() {
      return {
        'pulseAnimationOut star-full': this.pulseCartId,
        'star-black': this.$router.history.current.name === 'carts' && !this.pulseCartId,
      };
    },
    currentPageFavs() {
      return this.all.slice((this.currentPage - 1) * this.numPerPage, this.numPerPage * this.currentPage);
    },
    activeCartSize() {
      if (this.activeCart) return this.activeCart.es_count;

      return 0;
    },
    paginationPresent() {
      return this.all.length > this.numPerPage;
    },
    activeCartName() {
      if (this.activeCart) {
        this.tooltipDisabled = false;
        return this.activeCart.name;
      } else {
        this.tooltipDisabled = true;
        return null;
      }
    },
    titles() {
      return {
        favorites: this.$gettext('Fonctions de sélection disponibles uniquement pour utilisateur connecté'),
        create: this.$gettext('Créer une nouvelle sélection'),
      };
    },
  },
  methods: {
    ...mapMutations('carts', ['assign']),
    toggleShowCarts() {
      this.isCartListOpen = !this.isCartListOpen;
    },
    goToFavorites() {
      this.$router.push({ name: 'carts' });
    },
    goToActive() {
      const query = this.$route.query;
      this.$router.push({ name: 'Artworks', query: { ...query, favorites: this.activeCart.id, page: 1 } });
    },
    setupNewCart() {
      this.cartName = '';
      this.isNewCartOpen = true;
      this.assign({ name: 'selectedCartId', data: null });
    },
    async createCart() {
      await this.$store.dispatch('carts/create', { name: this.cartName });
      if (!this.createCartError) this.isNewCartOpen = false;
    },
    setActiveCart(cart) {
      this.$store.dispatch('carts/setActive', cart);
      this.cartName = '';
    },
    isCurrentCart(cartId) {
      if (this.activeCart) return this.activeCart.id == cartId;

      return false;
    },
    hide() {
      this.isNewCartOpen = false;
    },
  },
  data() {
    return {
      isCartListOpen: true,
      isNewCartOpen: false,
      cartName: '',
      tooltipDisabled: true,
    };
  },
};
</script>
