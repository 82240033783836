import { text_notes_categories } from 'config';
import buster from 'lib/buster';
import defaultSort from './defaultSort';

function chunkArray(myArray, chunk_size) {
  let index = 0;
  let arrayLength = myArray.length;
  let tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    const myChunk = myArray.slice(index, index + chunk_size);
    tempArray.push(myChunk);
  }

  return tempArray;
}

function buildTerm(filter, api = false) {
  let terms = filter.term;

  if (api === false) {
    switch (filter.type) {
      case 'before':
        terms = filter.term.map((t) => {
          const [_, tt] = t.split('__');
          return `__${tt || _}`;
        });
        break;
      case 'after':
        terms = filter.term.map((t) => {
          const [tt, _] = t.split('__');
          return `${tt || _}__`;
        });
        break;
      case 'range':
        terms = [filter.term.slice().join('__')];
        break;
      default:
        terms = filter.term;
    }
  }
  const operator = filter.operator == 'and' ? '' : '!';
  const term = `${filter.aggregation}:${operator}${terms.join('||')}`;
  if (filter.type === 'category_query') {
    //TODO update api
    return api ? `${terms.join('||')}:${operator}${filter.aggregation}` : `category_query:${term}`;
  } else if (filter.type === 'query') {
    return api ? `${operator}${terms.join('||')}`.replace('!', ' -') : `${term}`;
  } else if (filter.type === 'checkbox') {
    return api ? term : `checkbox:${term}`;
  } else {
    return term;
  }
}

const toQueryParam = (filters, api = false) => {
  return filters.reduce((result, current) => {
    const term = buildTerm(current, api);

    if (result.length === 0) {
      return `${term}`;
    } else {
      if (result.includes(term)) {
        return result;
      }
      return [result, `${term}`].join(',,');
    }
  }, '');
};

const toURLParam = (filters) => {
  const params = toQueryParam(filters);
  const splited = params.split(',,').reduce((result, current) => {
    const regexp = /(checkbox|category_query):([^:]+)/g;
    const match = current.match(regexp);

    if (match) {
      result = [...result, match[0], current.split(`${match[0]}:`)[1]];
    } else {
      const [agg, ...rest] = current.split(':');
      result = [...result, agg, rest.join(':')];
    }
    return result;
  }, []);
  return splited;
};

const fromURLParam = (filters) => {
  let filtersStr = filters;

  if (filtersStr) {
    const filterArray = typeof filtersStr === 'object' ? filtersStr : filters.split('/');
    const filterPairs = chunkArray(filterArray, 2);
    filtersStr = filterPairs.map((pair) => pair.join(':')).join(',,');
  }

  return fromParam(filtersStr);
};

const toApiParam = (params) => {
  const filters = params.filters || [];
  const terms = filters.filter((f) => /term|checkbox|after|before|range/.test(f.type));
  const categoryQuery = filters.filter((f) => f.type === 'category_query');
  const query = filters.filter((f) => f.type === 'query');
  const { endpoint = 'artworks', sort } = params;

  let apiParams = {};

  const isSearch = [
    params.ids,
    params.movement_id,
    params.favorites,
    params.filters.filter((f) => f.type !== 'checkbox').toString(),
  ].some((e) => Boolean(e));

  if (params.sort) {
    apiParams.sort = params.sort;
  } else {
    apiParams.sort = defaultSort(endpoint, isSearch);
  }

  if (params.from) {
    apiParams.from = params.from;
  }

  if (params.size) {
    apiParams.size = params.size;
  }

  if (params.term_size) {
    apiParams.term_size = params.term_size;
  }

  if (terms.length) {
    apiParams.filters = toQueryParam(terms, true);
  }

  if (categoryQuery.length) {
    apiParams.category_query = toQueryParam(categoryQuery, true);
  }

  if (query && query.length) {
    apiParams.q = toQueryParam(query, true);
  }

  if (params.ids) {
    apiParams.ids = params.ids;
  }

  if (params.favorites) {
    apiParams.displayed_cart_id = params.favorites;
  }

  if (params.movement_id) {
    apiParams.movement_id = params.movement_id;
  }

  if (endpoint) {
    if (apiParams.sort === 'random') {
      apiParams.buster = buster;
    }
  }

  if (text_notes_categories && text_notes_categories.length) {
    const categories = text_notes_categories.filter((c) => c.display).map((c) => c.id);
    apiParams.text_notes_categories = categories.join(',');
  }

  return apiParams;
};

const fromParam = (filters) => {
  if (!filters || filters === '') return [];

  return filters.split(',,').reduce((result, current) => {
    const [noType, categoryType] = current.split(/category_query:|checkbox:/).reverse();
    const [_, suffix] = noType.split(':!');
    const operator = suffix ? 'and_not' : 'and';
    const [aggregation, ...rest] = suffix ? noType.split(':!') : noType.split(':');
    const term = rest.join(':');
    let type = null;

    if (typeof categoryType === 'string' && /checkbox:/.test(current)) {
      type = 'checkbox';
    } else if (typeof categoryType === 'string' && /category_query:/.test(current)) {
      type = 'category_query';
    }

    const [afterOrRange, beforeOrRange] = term.split('__');

    if (afterOrRange.length > 0 && beforeOrRange && beforeOrRange.length > 0) {
      type = 'range';
    } else if (afterOrRange.length > 0 && typeof beforeOrRange === 'string') {
      type = 'after';
    } else if (typeof beforeOrRange === 'string' && beforeOrRange.length > 0) {
      type = 'before';
    }

    result.push({
      aggregation,
      operator,
      term: term.split('||'),
      type: type ? type : aggregation === 'query' ? 'query' : 'term',
    });
    return result;
  }, []);
};

export default {
  toURLParam,
  toQueryParam,
  fromParam,
  fromURLParam,
  toApiParam,
};
