import { HTTP, NO_CACHE_HEADER } from '../util/index';
import { CancelToken } from 'axios';
import * as qs from 'qs';
import pick from 'lodash.pick';

const SELECT_ALL = ['q', 'filters', 'category_query', 'sort', 'ids', 'movement_id', 'displayed_cart_id'];

let cancel;
export default {
  async getActive() {
    const { data } = await HTTP.get(`/carts/active`);
    return data;
  },
  async getAll() {
    const { data } = await HTTP.get(`/carts`);
    return data;
  },
  async create(cart) {
    const { data } = await HTTP.post(`/carts`, cart);
    return data;
  },
  async update(cartId, payload) {
    const { data } = await HTTP.put(`/carts/${cartId}`, payload);
    return data;
  },
  async delete(cartId) {
    const { data } = await HTTP.delete(`/carts/${cartId}`);
    return data;
  },
  async send(cartId, params) {
    const { data } = await HTTP.post(`/carts/${cartId}/send`, params);
    return data;
  },
  async addArtwork(artworkId, activeCart) {
    const payload = { artwork_id: artworkId };
    const { data } = await HTTP.post(`/carts/${activeCart.id}/add_artwork`, payload);
    return data;
  },
  async removeArtwork(artworkId, activeCart) {
    const payload = { artwork_id: artworkId };
    const { data } = await HTTP.post(`/carts/${activeCart.id}/remove_artwork`, payload);
    return data;
  },
  async selectAll(params, activeCart) {
    const { data } = await HTTP.post(`/carts/${activeCart.id}/select_all`, pick(params, SELECT_ALL));
    return data;
  },
  async summary(cartId, { type }) {
    const { data } = await HTTP.get(`/carts/${cartId}/summary`, { params: { type } }, NO_CACHE_HEADER);
    return data;
  },
  async unselectAll(params, activeCart) {
    const { data } = await HTTP.post(`/carts/${activeCart.id}/unselect_all`, pick(params, SELECT_ALL));
    return data;
  },
  async updateCollaborators(params) {
    const { users, id } = params;
    const { data } = await HTTP.patch(`/carts/${id}/collaborators`, { users });
    return data;
  },
  async unselectAllSStatus(params, endpoint = 'artworks') {
    if (cancel != undefined) {
      cancel('Only one request allowed!');
    }

    const { data } = await HTTP.get(endpoint, {
      params,
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    });

    const { filteredCount } = data;
    return { filteredCount };
  },
};
