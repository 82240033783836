'use strict';

const values = [100, 200, 400, 600, 800, 1000];

const getClosestValues = (a, x) => {
  let lo = -1,
    hi = a.length;
  while (hi - lo > 1) {
    let mid = Math.round((lo + hi) / 2);
    if (a[mid] <= x) {
      lo = mid;
    } else {
      hi = mid;
    }
  }
  if (a[lo] === x) {
    hi = lo;
  }
  return [a[lo], a[hi]];
};

export default function (image) {
  if (!image) {
    return null;
  }

  const boxWidth = Math.max(image.box_width, image.box_height);

  if (boxWidth === null || typeof boxWidth === 'undefined') {
    return null;
  }

  return getClosestValues(values, boxWidth);
}
