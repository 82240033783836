export const formInitialState = {
  id: null,
  content: '',
  cartId: null,
  note_properties: {
    label: '',
    doc: 'NoticeCreeDansNavigart_',
    imageUrl: '',
    gcoll2_number: '',
    date: '',
    owner: '',
    references: [{ id: '' }],
    author: '',
    externa: '',
    type: 'artwork',
    title: '',
    notquotedlinks_external: [],
    notquotedlinks_internal: [],
    quotedlinks_external: [],
    quotedlinks_internal: [],
    filter: [],
    artwork_ids: [],
    category: '',
  },
};

export default {
  list: [],
  previews: [],
  notquotedLinkId: null,
  form: {},
  resource: {},
  textNote: null,
};
