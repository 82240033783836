<template>
  <a @click.stop.prevent="sort" href="" :class="{ true: '', false: sortDir }[sortBy !== sortValue]">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'filter-sort',
  props: {
    sortValue: {
      type: String,
    },
    onSort: {
      type: Function,
      default: () => {},
    },
    termOrder: {
      type: String,
    },
  },
  computed: {
    sortBy() {
      const [sortBy] = this.termOrder.split(':');
      return sortBy;
    },
    sortDir() {
      const [_, sortDir] = this.termOrder.split(':');
      return sortDir;
    },
  },
  methods: {
    sort() {
      let sortDir = this.sortDir,
        sortBy = this.sortBy;
      if (this.sortBy === this.sortValue) {
        sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
      } else {
        sortBy = this.sortValue;
        sortDir = 'asc';
      }

      this.onSort(sortBy, sortDir);
    },
  },
};
</script>

<style scoped></style>
