<template>
  <v-runtime-template :template="content" class="content"></v-runtime-template>
</template>
<script>
import marked from 'lib/markdown';
import VRuntimeTemplate from 'v-runtime-template';
export default {
  name: 'Markdown',
  props: {
    input: String,
    options: {
      defualt: {},
      type: Object,
    },
  },
  components: {
    VRuntimeTemplate,
  },
  computed: {
    content() {
      return `<div>${marked(this.input || '', this.options)}</div>`;
    },
  },
};
</script>
<style lang="less">
.content {
  overflow: auto;
}
</style>
>
