<template>
  <p v-if="legend">
    <span>{{ legend }}</span>
  </p>
</template>
<script>
export default {
  name: 'ArtworkMediaLegend',
  props: {
    artwork: {
      type: Object,
      require: true,
    },
    header: {
      type: Boolean,
    },
  },
  computed: {
    medias() {
      if (!this.artwork.medias) {
        return [];
      }

      return this.artwork.medias;
    },
    videos() {
      return this.medias.filter((i) => i.type === 'video' && i.header_aggregate === this.header);
    },
    videosLegend() {
      return this.videos.length ? `${this.videos.length} video` : null;
    },
    audio() {
      return this.medias.filter((i) => i.type === 'audio' && i.header_aggregate === this.header);
    },
    audioLegend() {
      return this.audio.length ? `${this.audio.length} son` : null;
    },
    images() {
      const images = this.medias.filter((i) => i.type === 'image');
      if (typeof this.header === 'boolean') {
        return images.filter((i) => i.header_aggregate === this.header);
      }
      return images;
    },
    imagesLegend() {
      if (this.images.length) {
        const otherMedia = this.audioLegend || this.videosLegend;
        const imagesSize = otherMedia ? this.images.length : this.images.length - 1;
        if (otherMedia) {
          return imagesSize == 1 ? `${imagesSize} image` : `${imagesSize} images`;
        }
        if (imagesSize) {
          return this.images.length === 2 ? `${imagesSize} autre image` : `${imagesSize} autres images`;
        }
        return null;
      }
      return null;
    },
    legend() {
      return [this.videosLegend, this.audioLegend, this.imagesLegend].filter(Boolean).join(' et ');
    },
  },
};
</script>
