<template>
  <div class="mb-4">
    <h3 v-if="showLabel" class="mb-4">{{ label }}</h3>
    <vue-slider
      class="mx-2"
      :marks="marks"
      v-model="valueRange"
      v-on:drag-end="onDragEnd"
      v-on:change="onRangeChange"
      :railStyle="railStyle"
      :enable-cross="false"
      :tooltip="'none'"
      :dotSize="[20, 40]"
      :process="false"
      :height="1"
      :min="roundMin"
      :max="roundMax"
    >
      <template v-slot:dot="{ value, focus, index }">
        <div class="slider-dot" :class="{ selected: isActive(index, value) }"></div>
        <div class="arrow arrow-down"></div>
        <div class="circle" :class="{ selected: isActive(index, value) }"></div>
        <div class="arrow arrow-up"></div>
      </template>
    </vue-slider>

    <div class="search range-search d-flex justify-content-center align-items-center pt-5">
      <span v-translate class="px-2">de</span>
      <input class="form-control col col-md-3 text-center" type="text" @keyup="onInputChange" v-model="minRange" />
      <span v-translate class="px-2">à</span>
      <input class="form-control col col-md-3 text-center" type="text" @keyup="onInputChange" v-model="maxRange" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { debounce } from 'debounce';

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
  name: 'FilterRange',
  props: ['aggregation', 'min', 'max', 'showLabel', 'group'],
  components: {
    VueSlider,
  },
  mounted() {
    const [min, max] = this.numericalRange(this.aggregation);

    let valueMin = this.roundMin;
    let valueMax = this.roundMax;

    if (min && max) {
      valueMin = min;
      valueMax = max;
    }

    this.maxRange = valueMax;
    this.minRange = valueMin;
    this.valueRange = [valueMin, valueMax];

    const interval = (this.roundMax - this.roundMin) / 20;
    const base = [...new Array(21)];
    this.marks = {};
    base.map((b, i) => {
      const key = this.roundMin + i * interval;
      this.marks[key] = {
        label: this.group ? this.$options.filters.number(key) : key,
      };
    });
  },
  methods: {
    onInputChange: debounce(function () {
      if (
        (this.minRange !== this.valueRange[0] ||
        this.maxRange !== this.valueRange[1]) &&
        this.maxRange >= this.roundMin &&
        this.maxRange <= this.roundMax &&
        this.minRange <= this.roundMax &&
        this.minRange >= this.roundMin
      ) {
        this.valueRange = [this.minRange, this.maxRange];
        this.onDragEnd();
      }
    }, 300),
    onRangeChange: debounce(function () {
      this.minRange = this.valueRange[0];
      this.maxRange = this.valueRange[1];
    }, 300),
    isActive(index, value) {
      return (index === 0 && value !== this.roundMin) || (index === 1 && value !== this.roundMax);
    },
    onDragEnd() {
      this.$emit('setGroup', {
        key: this.aggregation,
        min: this.valueRange[0],
        max: this.valueRange[1],
      });
    },
  },
  computed: {
    ...mapGetters('aggregations', ['aggregations']),
    ...mapGetters('params', ['numericalRange']),
    ...mapGetters('artworks', ['totalCount']),
    lang() {
      return this.$language.current;
    },
    label() {
      return this.aggregations[this.aggregation]['displayed_as'][this.lang];
    },
    roundMax() {
      return this.max;
    },
    roundMin() {
      return this.min;
    },
  },
  data() {
    return {
      valueRange: [],
      minRange: 0,
      maxRange: 0,
      marks: {},
      localFiltered: null,
      railStyle: {
        'background-color': '#000000',
      },
    };
  },
};
</script>
<style scoped lang="less">
.slider-dot {
  background-color: #dfdfdf;
  width: 18px;
  height: 40px;
  border-radius: 10px;
  border: 3px solid #ffffff;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 6.65px 0.35px rgba(0, 0, 0, 0.5);
  opacity: 0.8;
  cursor: pointer;
  box-sizing: border-box !important;

  &.selected {
    background-color: #f1cc72;
    opacity: 1;
  }
}

.arrow {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  z-index: 1;
  position: absolute;
  left: 5px;
  cursor: pointer;
}

.arrow-up {
  border-bottom: 4px solid rgba(255, 255, 255, 1);
  bottom: 7px;
}

.arrow-down {
  border-top: 4px solid rgba(255, 255, 255, 1);
  top: 7px;
}

.circle {
  width: 5px;
  height: 5px;
  z-index: 1;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 100%;
  box-shadow: 0px 0px 6.65px 0.35px rgba(0, 0, 0, 0.5);
  top: 17px;
  left: 7px;
  cursor: pointer;
  &.selected {
    background-color: #000000;
  }
}

.range-search {
  input[type='text'] {
    .BlackFont(14px);
    &:focus {
      border-color: @filter-content-search-input-hover;
    }
  }
}

.counter {
  .BlackFont(30px);
  color: @filter-content-counter-total-color;
  .filtered {
    color: @filter-content-counter-filtered-color;
  }
}
</style>

<style lang="less">
.vue-slider-dot-focus,
.vue-slider-dot:hover {
  .slider-dot {
    background-color: #f1cc72;
    opacity: 0.8 !important;
  }
  .circle,
  .slider-dot {
    transform: scale(1.5, 1.5);
  }

  .circle {
    background-color: #ffffff !important;
  }

  .arrow-down,
  .arrow-up {
    transform: scale(1.5, 1.5);
  }
  .arrow-down {
    top: 2px;
  }
  .arrow-up {
    bottom: 2px;
  }
}

.vue-slider-mark:first-child .vue-slider-mark-step,
.vue-slider-mark:last-child .vue-slider-mark-step {
  display: block !important;
  height: 20px;
  top: -10px !important;
  background-color: #000000 !important;
  opacity: 1 !important;
}

.vue-slider-mark-step {
  height: 8px;
  top: -4px !important;
  background-color: #000000 !important;
  opacity: 0.2 !important;
  border-radius: 0 !important;
}

.vue-slider-mark-label {
  display: none;
}

.vue-slider-mark:first-of-type,
.vue-slider-mark:last-of-type {
  .vue-slider-mark-label {
    display: block;
    margin-top: 20px;
    color: #c6c6c6;
  }
}
</style>
