const valueByPath = function (obj, path) {
  let fields = path.split('.');
  let result = obj,
    i,
    len;

  for (i = 0, len = fields.length; i < len; i++) {
    result = result[fields[i]];
  }

  return result;
};

export default valueByPath;
