<template>
  <div class="row col-md-6 pl-4 pr-2 pl-md-3 pr-md-0">
    <div class="col col-md-9 top-search" id="step2" :class="{ 'col-9': anyFilterApplied }">
      <AppliedFilters :editing="editing" :hoverRemoveAll="hoverRemoveAll" v-on:toggleEditMode="toggleEditMode" />
      <div class="search-input-wrap">
        <input
          class="search-input form-control form-control-sm"
          type="text"
          :placeholder="queryPlaceholder()"
          @keyup.enter="submit"
          @focus="focused = true"
          @blur="focused = false"
          v-model="query"
          v-focus="focused"
        />
        <div class="search-actions">
          <a href @click.prevent="submit" v-b-tooltip.right :title="titles.search">
            <i class="fa fa-search"></i>
          </a>
          <a href v-if="query" @click.prevent="reset" v-b-tooltip.right :title="titles.reset">
            <i class="fa fa-times-circle"></i>
          </a>
        </div>
      </div>
      <Autocomplete :query="query" />
    </div>
    <div class="col-3 col-md-2 align-items-start align-self-end pl-2 d-flex flex-column" v-if="anyFilterApplied">
      <span class="pr-2">
        <a
          href=""
          class="button btn-editing"
          :class="{ active: editing }"
          v-b-tooltip.right
          :title="toggleTooltipText"
          @click.stop.prevent="toggleEditMode"
        >
          <i :class="{ 'icon-fold': editing, 'icon-unfold': !editing }"></i>
        </a>
      </span>
      <span class="remove-all-link">
        <a
          href
          class="button"
          @click.stop.prevent="removeAll()"
          @mouseover="hoverRemoveAll = true"
          @mouseleave="hoverRemoveAll = null"
          v-translate
          >tout effacer</a
        >
      </span>
    </div>
  </div>
</template>

<script>
import { interfaceStrings } from 'config';

import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'debounce';

import AppliedFilters from './AppliedFilters.vue';
import Autocomplete from './Autocomplete.vue';
import FilterBuilder from 'lib/filterBuilder';

export default {
  name: 'SearchBar',
  components: {
    AppliedFilters,
    Autocomplete,
  },
  methods: {
    ...mapActions('autocomplete', ['search']),
    submit() {
      if (this.query.length > 2) {
        const filters = [{ term: [this.query], aggregation: 'query', type: 'query', operator: 'and' }];

        const query = this.$route.query;

        this.$router.push({
          query: { ...query, page: 1 },
          params: { filters: FilterBuilder.toURLParam(this.locationFilters.concat(filters)) },
        });
      }
    },
    reset() {
      this.$store.commit('autocomplete/UPDATE_QUERY', '');
      this.search({ search: '' });
    },
    removeAll() {
      const filters = this.locationFilters;
      const checkboxes = filters.filter((f) => f.type === 'checkbox');

      this.$router.push({ query: {}, params: { filters: FilterBuilder.toURLParam(checkboxes) } });
    },
    onChange: debounce(function () {
      if (this.query.length > 2 && this.focused) {
        this.search({ search: this.query });
      } else if (this.query.length === 0 && this.focused) {
        this.search({ search: '' });
      }
    }, 500),
    queryPlaceholder() {
      return this.anyFilterApplied
        ? interfaceStrings.search.placeholder.multiple[this.$language.current]
        : interfaceStrings.search.placeholder.single[this.$language.current];
    },
    toggleEditMode() {
      this.$root.$emit('bv::hide::tooltip');
      if (this.editing === false) {
        this.editing = true;
      } else {
        this.editing = false;
      }
    },
  },
  computed: {
    ...mapGetters('aggregations', ['aggregation']),
    ...mapGetters('params', ['locationFilters', 'anyFilterApplied']),
    query: {
      get() {
        return this.$store.state.autocomplete.query;
      },
      set(value) {
        this.$store.commit('autocomplete/UPDATE_QUERY', value);
      },
    },
    toggleTooltipText() {
      if (this.editing == true) {
        return this.$gettext('Titrage résumé des critères');
      } else {
        return this.$gettext('Tous les critères détaillés en modification');
      }
    },
    titles() {
      return {
        search: this.$gettext('Rechercher'),
        reset: this.$gettext('Supprimer le contenu'),
      };
    },
  },
  watch: {
    query(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$nextTick(() => {
          this.$forceUpdate();
          this.onChange();
        });
      }
    },
    locationFilters(to, from) {
      this.$nextTick(() => {
        this.editing = false;
        this.focused = true;
      });
    },
    aggregation(newValue, oldValue) {
      if (!newValue) {
        this.$nextTick(() => {
          this.$forceUpdate();
          this.onChange();
          this.focused = true;
        });
      } else {
        this.focused = false;
        this.$store.commit('autocomplete/RESET_AUTOCOMPLETE');
      }
    },
  },
  data() {
    return {
      focused: false,
      editing: false,
      hoverRemoveAll: null,
    };
  },
};
</script>

<style lang="less" scoped>
.remove-all-link {
  a.button {
    border-radius: 20px;
    line-height: 1.2;
    &:hover {
      color: white;
      background-color: @searchbar-editing-link-hover-color;
    }
  }
}

.button.btn-editing {
  border: 2px solid @searchbar-editing-link-color;
  padding: 0px 5px;
  margin: 5px 0px;
  border-radius: 20px;
  background-color: transparent;
  &:hover {
    color: @searchbar-editing-link-hover-color !important;
    border-color: @searchbar-editing-link-hover-color !important;
    background-color: transparent !important;
    i {
      color: @searchbar-editing-link-hover-color;
    }
  }

  &.active {
    border-color: @searchbar-editing-link-color !important;
    background-color: @searchbar-editing-link-color !important;
    i {
      color: white;
    }

    &:hover {
      background-color: @searchbar-editing-link-hover-color !important;
      border-color: @searchbar-editing-link-hover-color !important;
    }
  }
  i {
    line-height: 1.2;
    font-size: 18px;
    vertical-align: middle;
    color: @searchbar-editing-link-color;
  }
}
</style>
