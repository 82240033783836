import { HTTP, NO_CACHE_HEADER } from '../util';

export default {
  async get(id, highlight) {
    const params = {};

    if (highlight) {
      params.highlight = highlight;
    }

    let { data } = await HTTP.get(`/notes/${id}`, { params }, NO_CACHE_HEADER);
    return data;
  },
  async filter(params) {
    const { data } = await HTTP.get(`/notes/filter/${params.name}`);

    return data;
  },

  async publish({ id }) {
    const { data } = await HTTP.post(`/notes/artwork_notes/publish/${id}`);
    return data;
  },

  async cancelPublish({ id }) {
    const { data } = await HTTP.post(`/notes/artwork_notes/cancel_publish/${id}`);
    return data;
  },

  async cancelUnpublish({ id }) {
    const { data } = await HTTP.post(`/notes/artwork_notes/cancel_unpublish/${id}`);
    return data;
  },

  async unpublish({ id }) {
    const { data } = await HTTP.post(`/notes/artwork_notes/unpublish/${id}`);
    return data;
  },

  async query() {
    const { data } = await HTTP.get('/notes/artwork_notes/query', NO_CACHE_HEADER);
    return data;
  },

  async save({ note, artwork_id }) {
    const { data } = await HTTP.post('/notes/artwork_notes', { note, artwork_id });
    return data;
  },

  async update({ note, artwork_id }) {
    const { data } = await HTTP.put('/notes/artwork_notes', { note, artwork_id });
    return data;
  },

  async delete({ id, artworkId }) {
    const { data } = await HTTP.delete(`/notes/${id}`, { params: { artwork_id: artworkId } });
    return data;
  },
};
