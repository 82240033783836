<template>
  <a
    href
    class="add-favourite"
    @click.prevent.stop="toggleFavorite"
    v-b-tooltip.viewport
    :title="tooltipText"
    tooltip-placement="placement || 'left'"
    @mouseover="hideTooltips()"
  >
    <i :class="iconClass"></i>
  </a>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import toastr from 'mixins/toastrMixin.js';

export default {
  name: 'FavoriteLink',
  props: {
    artworkId: {
      type: String,
      required: true,
    },
    placement: {
      type: String,
    },
  },
  mixins: [toastr],
  computed: {
    ...mapGetters('carts', ['activeCart']),
    ...mapState('auth', ['currentUser']),
    isStarred() {
      if (!this.activeCart) return false;

      return this.activeCart.artwork_ids.includes(this.artworkId);
    },
    tooltipText() {
      if (this.isStarred) return this.$gettext('Désélectionner du dossier de sélection en cours');

      return this.$gettext('Sélectionner dans le dossier de sélection en cours');
    },
    iconClass() {
      return {
        'icon-favoris_on starred': this.isStarred,
        'icon-favoris_off': !this.isStarred,
      };
    },
  },
  methods: {
    hideTooltips() {
      const { artMosaic } = this.$parent.$refs;

      if (artMosaic) {
        this.$root.$emit('bv::hide::tooltip');
      }
    },
    toggleFavorite() {
      //this.hideTooltips();

      if (!this.currentUser.hasOwnProperty('id')) {
        return this.toastr('noAuth');
      }
      if (!this.activeCart) {
        return this.toastr('noCarts');
      }

      if (this.isStarred) {
        this.$store.dispatch('carts/removeArtwork', this.artworkId);
      } else {
        this.$store.dispatch('carts/addArtwork', this.artworkId);
      }

      this.$root.$emit('bv::hide::tooltip');
    },
  },
};
</script>
<style lang="less">
a.add-favourite .starred i,
a.add-favourite:hover,
a.add-favourite .starred {
  color: @artwork-sidebar-icon-color;
}
</style>
