import {
  TEXT_NOTES_SET_LIST,
  TEXT_NOTES_UPDATE_ELEMENT,
  TEXT_NOTES_UPDATE_STATUS,
  TEXT_NOTES_SET_EDIT,
  TEXT_NOTES_SET_NEW,
  TEXT_NOTES_SET_PROPERTIES,
  TEXT_NOTES_SET_CONTENT,
  ADD_PREVIEW,
} from '../mutation-types';
import { partialStrings } from 'config';
import textNoteTitle from '../../util/textNoteTitle';

const BASE_DOC = `NoticeCreeDansNavigart_`;

const unwrapProperties = ({
  label,
  doc,
  image_url,
  gcoll2_number,
  date,
  owner,
  references,
  author,
  external,
  type,
  title,
  notquotedlinks_external,
  notquotedlinks_internal,
  quotedlinks_external,
  quotedlinks_internal,
  filter,
  artwork_ids,
  category,
}) => ({
  label: label || partialStrings.text_notes[`${type}Label`],
  doc: doc || BASE_DOC,
  image_url: image_url || '',
  gcoll2_number: gcoll2_number || '',
  date: date || '',
  owner: owner || '',
  references: references || [{ id: '' }],
  author: author || '',
  external: external || null,
  type: type || 'artwork',
  title: title || '',
  notquotedlinks_external: notquotedlinks_external || [],
  notquotedlinks_internal: notquotedlinks_internal || [],
  quotedlinks_external: quotedlinks_external || [],
  quotedlinks_internal: quotedlinks_internal || [],
  filter: filter || [],
  artwork_ids: artwork_ids || [],
  category: category || null,
});

const doc = function (type) {
  switch (type) {
    case 'artwork': {
      return `${BASE_DOC}oeuvres_`;
    }
    case 'cart': {
      return `${BASE_DOC}selection_`;
    }
    case 'movement': {
      return `${BASE_DOC}mouvement_`;
    }
    default: {
      return BASE_DOC;
    }
  }
};

const author = function (user) {
  const { name, email, alias_names } = user;
  if (alias_names && alias_names[0]) {
    return alias_names[0];
  }
  return name || email.split('@')[0];
};

const reference = function (resource, type, element) {
  const { type: refType } = resource;
  let reference = { id: resource.id || '', ua_name: type, ref_type: refType };
  switch (refType) {
    case 'nonseparable': {
      reference.ua_name = element ? 'element' : 'header_artwork';
      if (element) {
        reference.element_id = element.id;
      }
    }
    case 'separable': {
      reference.ua_name = element ? 'element' : 'header_artwork';
      if (element) {
        reference.id = element.id;
      }
    }
  }

  return reference;
};

export default {
  assign(state, { name, data }) {
    state[name] = data;
  },
  [TEXT_NOTES_SET_LIST](state, payload) {
    state.list = payload;
  },
  [TEXT_NOTES_UPDATE_ELEMENT](state, payload) {
    state.list = [...state.list.filter((item) => item.id != payload.id), payload];
  },
  [TEXT_NOTES_UPDATE_STATUS](state, payload) {
    const textNote = state.list.find((item) => item.id === payload.id);
    if (textNote) {
      textNote.status = payload.status;
    }
  },
  [TEXT_NOTES_SET_PROPERTIES](state, payload) {
    state.form.note_properties = payload;
  },
  [TEXT_NOTES_SET_CONTENT](state, payload) {
    state.form.content = payload;
  },
  [ADD_PREVIEW](state, payload) {
    state.previews = [...state.previews, payload];
  },
  [TEXT_NOTES_SET_NEW](state, { resource, type, artworkEl, user }) {
    const properties = {
      type,
      owner: user.email,
      author: author(user),
      doc: doc(type),
    };

    state.resource = artworkEl || resource;

    state.form.note_properties = unwrapProperties(
      Object.assign(properties, {
        title: artworkEl ? textNoteTitle(artworkEl, type) : textNoteTitle(resource, type),
      })
    );

    if (type === 'artwork') {
      const { type: refType } = resource;
      state.form.note_properties = unwrapProperties(
        Object.assign(properties, {
          artwork_ids: refType === 'separable' ? resource.related : [],
          references: [reference(resource, type, artworkEl)],
        })
      );
    } else if (type === 'movement') {
      state.form.note_properties = unwrapProperties(
        Object.assign(properties, {
          references: [{ id: resource._id, ua_name: 'movement' }],
        })
      );
    } else if (type === 'cart') {
      state.form.note_properties = unwrapProperties(
        Object.assign(properties, {
          title: resource.name,
          references: [{ id: resource.id }],
        })
      );
      state.form.cart_id = resource.id;
    }

    state.form.artwork_type = resource.type;
    state.form.content = '';
    state.form.id = null;
  },
  [TEXT_NOTES_SET_EDIT](state, { textNote, resource }) {
    const isInternal = textNote.internal;
    const { type } = resource || {};

    state.resource = resource;
    state.form.note_properties = unwrapProperties(textNote.note_properties);

    if (isInternal) {
      state.form.id = textNote.id;
    } else {
      state.form.note_properties.doc = state.form.note_properties.external = textNote.id;

      const { references: [{ id, ua_name, ref_type } = {}] = [] } = state.form.note_properties;

      if (
        typeof state.form.note_properties.artworkIds !== 'undefined' &&
        ua_name === 'element' &&
        ref_type === 'separable'
      ) {
        state.form.properties.artworkIds = undefined;
      }

      if (state.form.note_properties.type === 'cart') {
        state.form.cart_id = id;
      }
    }

    state.form.content = textNote.content;
    state.form.cart_id = state.form.cart_id ?? textNote.cart_id;
    state.form.artwork_type = type || state.form.note_properties.type;
  },
};
