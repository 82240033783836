export default {
  page: 1,
  filters: [],
  term_order: 'count:desc',
  term_page: 1,
  layout: 'grid',
  cart_id: null,
  favorites: false,
  ids: '',
  movement_id: null,
  silent: false,
  sort: null,
};
