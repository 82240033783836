<template>
  <div class="filter-bar" id="step3">
    <nav class="scroll filters" ref="filters">
      <a
        :key="topic.key"
        v-for="topic in orderedTopics"
        href="#"
        class="filter"
        @click.stop.prevent="openFilter(topic.key)"
        v-bind:class="{ active: isActive(topic) }"
      >
        <span class="filter-name">{{ topic.displayed_as[lang] }}</span>
        <span class="count" v-if="!topic.group">[{{ topic.total | number }}]</span>
      </a>
    </nav>

    <div class="filters-actions" v-if="visibleTopics.length">
      <div class="filters-dropdown">
        <b-dropdown id="dropdown-1" right no-caret @shown="updateScroll">
          <template slot="button-content">
            <i class="icon-close-filter"></i>
          </template>
          <perfect-scrollbar ref="ps" class="scrollbar scroll-always-visible" :options="scrollOptions">
            <div class="filters-dropdown-additional" v-show="visibleTopics.length">
              <li>
                <p class="b-dropdown-text">
                  <translate>Filtres non affichés</translate>
                </p>
              </li>
              <b-dropdown-item
                :key="topic.key"
                v-for="topic in visibleTopics"
                href="#"
                @click.stop.prevent="openFilter(topic.key)"
                v-bind:class="{ active: isActive(topic) }"
              >
                <span class="filter-name ellipsis">{{ topic.displayed_as[lang] }}</span>
                <span class="count" v-if="!topic.group">[{{ topic.total | number }}]</span>
              </b-dropdown-item>
            </div>
          </perfect-scrollbar>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import scrollLeft from 'lib/scrollLeft';

export default {
  name: 'filter-bar',
  mounted() {
    window.addEventListener('resize', this.updateWidth);
  },
  beforeMount() {
    window.removeEventListener('resize', this.updateWidth);
  },
  computed: {
    ...mapGetters('aggregations', ['topics', 'aggregation']),
    orderedTopics() {
      let ordered = this.topics;
      ordered = ordered.sort((t1, t2) => (t1.pos > t2.pos ? 1 : -1));
      this.visibleTopics = ordered.slice(this.visibleTopicsCount);
      return ordered;
    },
    blockSize() {
      return Math.round(this.windowWidth / this.filterSize) * this.filterSize;
    },
    lang() {
      return this.$language.current;
    },
    visibleTopicsCount() {
      return Math.floor((this.windowWidth - this.controlSize) / this.filterSize);
    },
  },
  methods: {
    ...mapActions('aggregations', ['toggleAggregation']),
    updateScroll() {
      this.$refs.ps.ps.update();
    },
    updateWidth(event) {
      this.windowWidth = window.innerWidth;
      this.position = 0;
    },
    openFilter(term) {
      if (this.aggregation === term) {
        this.toggleAggregation(null);
      } else {
        this.toggleAggregation(term);
      }
    },
    isActive(topic) {
      return this.aggregation === topic.key;
    },
  },
  data() {
    return {
      position: 0,
      controlSize: 42,
      filterSize: 200,
      windowWidth: window.innerWidth,
      visibleTopics: [],
      scrollOptions: {
        wheelPropagation: true,
        suppressScrollX: true,
      },
    };
  },
  watch: {
    aggregation(newVal) {
      if (newVal) {
        const topicIndex = this.orderedTopics.findIndex((t) => t.key === newVal);
        if (topicIndex > -1) {
          this.position = Math.floor((topicIndex * this.filterSize) / this.blockSize) * this.blockSize;
          scrollLeft(this.$refs.filters, this.position, 2000);
        }
      } else {
        this.position = 0;
        scrollLeft(this.$refs.filters, this.position, 2000);
      }
    },
    position(newVal, oldVal) {
      let startIdx = newVal / this.filterSize;
      let endIdx = startIdx + this.visibleTopicsCount;

      if (endIdx > this.topics.length) {
        startIdx = this.topics.length - this.visibleTopicsCount;
        endIdx = this.topics.length;
      }

      const visible = this.orderedTopics.slice(startIdx, endIdx);
      this.visibleTopics = this.orderedTopics.filter((t) => !visible.includes(t));
    },
  },
};
</script>

<style scoped lang="less">
.filter-bar {
  display: flex;
  min-width: 0px;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scroll::-webkit-scrollbar {
  display: none;
}

nav {
  a {
    flex: 0 0 auto;
  }
  a:last-child {
    margin-right: 100px;
  }
}
</style>

<style lang="less">
.filters-dropdown {
  background-color: @filter-scroll-background;
  height: 40px;

  .dropdown {
    height: 100%;
  }

  button {
    background-color: @filter-scroll-background;
    padding: 0 0.75rem;
    border: none;

    i {
      color: @filters-dropdown-icon-color;
    }

    &:hover {
      background-color: @filter-scroll-background;

      i {
        color: @filters-dropdown-icon-hover;
      }
    }

    &:focus {
      background-color: transparent;
    }
  }

  .show {
    border: none;

    button {
      background-color: @white;

      i {
        color: @filters-dropdown-icon-active;

        &:before {
          content: '\e93f';
        }
      }
    }

    .dropdown-menu {
      top: -2px !important;
      box-shadow: -10px 10px 10px -5px rgba(0, 0, 0, 0.5);

      @media (max-width: 767px) {
        transform: none !important;
        top: 37px !important;
        right: 0;
        left: auto !important;
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        transform: none !important;
        top: 37px !important;
        right: 0;
        left: auto !important;
        -ms-overflow-style: none;
      }
    }
  }

  .dropdown-menu {
    max-height: 70vh;
    overflow: hidden;
    max-width: 100vw;

    .scrollbar {
      max-height: 70vh;
    }
  }

  li {
    border-bottom: 1px @filters-dropdown-item-border solid;
    text-transform: uppercase;
    color: @black;

    &:nth-child(odd) {
      background-color: @white;
    }

    &:nth-child(even) {
      background-color: @filters-dropdown-item-even;
    }

    &:first-child {
      border-bottom: 2px @filters-dropdown-header-border solid;

      .b-dropdown-text {
        padding: 0.25rem 0.75rem;
      }
    }

    &.active {
      color: @white;
      background-color: @filters-dropdown-item-hover;
    }
  }

  .b-dropdown-text {
    width: 350px;
    font-weight: 800;
    height: 50px;
    line-height: 50px;
    margin: 0;
  }

  .dropdown-item {
    height: 50px;
    display: flex !important;
    flex: 0 0 auto;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0.75rem;

    .filter-name {
      font-weight: 600;
    }
  }

  .filters-dropdown-additional {
    padding: 0 10px 10px 10px;
    .dropdown-item:hover {
      background-color: @filters-dropdown-item-hover;
    }
  }

  .filters-dropdown-hidden {
    background-color: @filters-dropdown-item-even;
    margin-top: 10px;

    &:first-child {
      border-bottom: 2px @filters-dropdown-header-border solid;

      .b-dropdown-text {
        padding: 0.25rem 0.75rem;
      }
    }

    &.with-margin {
      margin-top: 20px;
    }

    a {
      cursor: default;
    }
  }
}
</style>
