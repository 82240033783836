<template>
  <div class="row actions-bar-container">
    <ul class="actions-bar" ref="actionBar" :style="leftPosition">
      <li class="layouts" id="step4">
        <a
          href
          class="grid-1"
          v-b-tooltip.hover.left
          :title="titles.mosaic"
          :class="{ active: isMosaic }"
          @click.prevent="switchLayout('mosaic')"
        >
          <i class="icon-mosaic"></i>
        </a>
        <a
          href
          class="grid-2"
          v-b-tooltip.hover.left
          :title="titles.box"
          :class="{ active: isBox }"
          @click.prevent="switchLayout('box')"
        >
          <i class="icon-box"></i>
        </a>
        <a
          v-hide-on-mobile
          href
          class="grid-2"
          v-b-tooltip.hover.left
          :title="titles.grid"
          :class="{ active: isGrid }"
          @click.prevent="switchLayout('grid')"
        >
          <i class="icon-grid"></i>
        </a>
      </li>
      <li v-hide-on-mobile>
        <PrintLink id="print-artworks" link="artworks" :title="titles.print"></PrintLink>
      </li>
      <li v-if="isPrivate" class="d-none d-md-block">
        <ExportXlsxButton />
      </li>
      <li v-if="isAuthenticated" v-hide-on-mobile>
        <a href @click.prevent="$refs.favoriteAll.show()" v-b-tooltip :title="selectAllTooltip">
          <i v-if="unselectAllStatus" class="icon-favoris_on star-full"></i>
          <i v-else class="icon-favoris_on"></i>
        </a>
      </li>
      <li>
        <b-dropdown :text="sortLabel(currentSort)">
          <b-dropdown-item @click="updateSort(key, sort)" :key="key" v-for="(sort, key) in sortList">{{
            sortLabel(sort)
          }}</b-dropdown-item>
        </b-dropdown>
      </li>
      <li v-show="checkboxes.length">
        <FilterCheckboxes />
      </li>
    </ul>

    <div>
      <div v-show="!open" class="toggle-button">
        <a href="" @click.stop.prevent="toggle"> >> </a>
      </div>
    </div>

    <b-modal hide-header hide-footer lazy body-class="p-0" ref="favoriteAll">
      <div class="modal-body">
        <span>{{ favoriteAllMessage }}</span>
        <div class="select-all-moda-buttons">
          <button class="btn btn-defualt" @click="submit" v-translate>Oui</button>
          <button class="btn btn-defualt" @click="$refs.favoriteAll.hide()" v-translate>Non</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { sort, publicationAccessParameters } from 'config';
import { mapGetters, mapState } from 'vuex';
import PrintLink from './PrintLink.vue';
import ExportXlsxButton from './ExportXlsxButton.vue';
import FilterCheckboxes from './FilterCheckboxes.vue';
import defaultSort from 'lib/defaultSort';

export default {
  name: 'ArtworksActionsBar',
  components: { PrintLink, ExportXlsxButton, FilterCheckboxes },
  props: ['reachStart'],
  mounted() {
    if (!this.autoopen) {
      this.open = false;
    }
  },
  computed: {
    ...mapGetters('artworks', ['filteredCount', 'totalCount']),
    ...mapGetters('aggregations', ['checkboxes']),
    ...mapGetters('carts', ['activeCart', 'unselectAllStatus']),
    ...mapGetters('params', ['layout', 'page', 'sort', 'artworkApi']),
    ...mapState('auth', ['isAuthenticated']),
    favoriteAllMessage() {
      let str;
      if (this.unselectAllStatus) {
        str = this.$gettext(
          `Vous avez demandé la désélection de toutes les %{count} oeuvres de la recherche actuelle. Est-ce bien ce que vous voulez faire ?`
        );
      } else {
        str = this.$gettext(
          `Vous avez demandé la sélection de toutes les %{count} oeuvres de la recherche actuelle. Est-ce bien ce que vous voulez faire ?`
        );
      }
      const interpolated = this.$gettextInterpolate(str, { count: this.filteredCount });
      return interpolated;
    },
    titles() {
      return {
        mosaic: this.$gettext('Vue mosaïque'),
        box: this.$gettext('Vue diapo'),
        grid: this.$gettext('Vue développée'),
        print: this.$gettext('Imprimer toutes les oeuvres'),
      };
    },
    sortList() {
      return sort['artworks'];
    },
    isPrivate() {
      return publicationAccessParameters.private;
    },
    lang() {
      return this.$language.current;
    },
    currentSort() {
      const { endpoint = 'artworks' } = this.$router.currentRoute.meta;
      const isSearch = this.artworkApi.filters;
      return this.sortList[this.sort || defaultSort(endpoint, isSearch)];
    },
    selectAllTooltip() {
      return this.unselectAllStatus ? this.$gettext('Tout désélectionner') : this.$gettext('Tout sélectionner');
    },
    isGrid() {
      return this.layout === 'grid';
    },
    isMosaic() {
      return this.layout === 'mosaic';
    },
    isBox() {
      return this.layout === 'box';
    },
    autoopen() {
      if (this.$isMobile()) {
        return false;
      }
      return this.page == 1;
    },
    leftPosition() {
      let left = 0;
      if (!this.open) {
        left = -1000;
      }

      return {
        transform: `translateX(${left}px)`,
      };
    },
  },
  methods: {
    async submit() {
      if (this.unselectAllStatus) {
        await this.$store.dispatch('carts/unselectAll');
      } else {
        await this.$store.dispatch('carts/selectAll');
      }

      this.$nextTick(() => {
        this.$refs.favoriteAll.hide();
      });
    },
    sortLabel(sort) {
      return sort ? sort.label[this.lang] : '';
    },
    updateSort(key, sort) {
      const query = this.$route.query;

      this.$router.push({
        query: { ...query, page: 1, sort: key },
      });
    },
    switchLayout(layout) {
      const query = this.$route.query;

      this.$router.push({
        query: { ...query, page: 1, layout },
      });
    },
    toggle() {
      this.open = !this.open;
    },
  },
  watch: {
    reachStart(to, from) {
      if (to > 0) {
        this.open = false;
      } else if (this.autoopen) {
        this.open = true;
      }
    },
  },
  data() {
    return {
      open: true,
    };
  },
};
</script>

<style lang="less" scoped>
.actions-bar-container {
  min-height: 72px;
}

.toggle-button {
  width: 47px;
  height: 47px;
  background-color: #f5f5f5;
  justify-content: center;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 1;
  padding: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

ul.actions-bar {
  background-color: #f5f5f5;
  display: flex;
  list-style: none;
  justify-content: space-between;
  margin: 0 0 0px 15px;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  position: fixed;
  z-index: 2;

  transform: translateZ(0);
  transition: transform 0.5s;
  backface-visibility: hidden;
  perspective: 1000;

  li {
    padding: 10px;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      border-right: 1px solid @silver;
    }

    &.layouts {
      a:not(:first-child) {
        margin-left: 10px;
      }
    }

    .active i {
      color: @golden-sand;
    }

    i {
      font-size: 24px;
      line-height: 24px;
    }
  }
}
</style>

<style lang="less">
ul.actions-bar {
  li {
    i {
      font-size: 24px !important;
      line-height: 24px !important;
    }
  }

  .show {
    > .btn-secondary.dropdown-toggle {
      background-color: @white;
    }
  }

  .dropdown-toggle {
    width: 190px;
    background-color: @white;
    border: none;
    text-align: left;
    font-size: 14px;
    padding: 0 0.75rem;

    &:after {
      position: absolute;
      top: 8px;
      right: 10px;
    }
  }

  .dropdown-menu {
    width: 190px;
    padding: 0;
    margin: 0;
    border: none;
    font-size: 14px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
    li {
      padding: 0;
    }
    .dropdown-item {
      position: relative;
      height: 50px;
      padding-left: 0.75rem;
      line-height: 50px;

      &:before {
        content: '';
        position: absolute;
        top: 0px;
        right: 10px;
        bottom: 0px;
        left: 10px;
        border-top: 1px solid @alto;
      }

      &:active {
        background-color: @golden-sand;
      }
    }
  }
}
</style>
