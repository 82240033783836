<template>
  <div class="help" v-if="show">
    <b-dropdown
      variant="link"
      right
      menu-class="dropdown-menu top-tools-dropdown"
      no-caret
      v-b-tooltip.hover.left
      :title="titles.menu"
    >
      <template slot="button-content">
        <i class="icon-faq"></i>
      </template>
      <ul>
        <b-dropdown-item v-if="showHelp" @click="start"><translate>Aide</translate></b-dropdown-item>
        <b-dropdown-item v-if="showAbout" v-b-modal.about><translate>A propos</translate></b-dropdown-item>
      </ul>
    </b-dropdown>

    <b-modal id="about" hide-header hide-footer lazy body-class="p-0" modal-class="object-share">
      <MenuBarAbout />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '../router';
import MenuBarAbout from './MenuBarAbout.vue';

export default {
  name: 'MenuBarUserHelp',
  components: { MenuBarAbout },
  props: {
    showHelp: {
      type: Boolean,
      default: true,
    },
    showAbout: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('params', ['layout']),
    titles() {
      return {
        menu: this.$gettext("Cliquer pour accéder aux fonctions d'aide et d'informations sur la publication"),
      };
    },
  },
  methods: {
    start() {
      const steps = Object.assign([], baseSteps);
      if (this.layout !== 'mosaic') steps.splice(5, 0, favoriteStep);

      this.$intro()
        .setOptions({
          showStepNumbers: true,
          nextLabel: 'Suivant &rarr;',
          prevLabel: '&larr; Précédent',
          skipLabel: 'Fermer',
          doneLabel: "Fin de l'aide",
        })
        .addSteps(steps)
        .start();
    },
  },
  data() {
    return {
      show: router.history.current.name !== 'Cart',
    };
  },
};

const baseSteps = [
  {
    element: '#step1',
    intro:
      "<h2>Compteur du nombre d'oeuvres</h2>" +
      '<ul>' +
      "<li>ce compteur indique le nombre total d'oeuvres</li>" +
      "<li>et pour chaque recherche, le nombre d'œuvres affichées dans les résultats</li>" +
      '</ul>',
    position: 'right',
  },
  {
    element: '#step2',
    intro:
      '<h2>Barre de recherche générale</h2>' +
      '<ul>' +
      "<li>en tapant les premières lettres d'un mot, les filtres vous indiqueront les mots trouvés avec ces lettres</li>" +
      '<li>en cliquant sur un mot dans les filtres affichés, vous lancez la rechercher</li>' +
      "<li>si pour un mot tapé, il n'y a pas de marquage de réponses dans les filtres, vous pouvez tout de même lancer la recherche en texte intégral, en faisant entrée." +
      'Vous pouvez aussi faire une troncature avec *</li>' +
      '</ul>',
    position: 'right',
  },
  {
    element: '#step3',
    intro:
      '<h2>Filtres</h2>' +
      '<ul>' +
      '<li>en cliquant sur tous les filtres ou un filtre particulier, vous verrez les contenus de la base par catégorie</li>' +
      '<li>en cliquant sur un terme, vous lancez la recherche</li>' +
      '<li>avec un filtre particulier, vous pouvez aussi trier et rechercher dans le filtre</li>' +
      "<li>mais n'oubliez pas qu'en tapant dans la barre de recherche générale, les lettres tapées ouvrent automatiquement tous les filtres</li>" +
      '<li>lorsque vous avez effectué une recherche, ces filtres vous donnent toutes les valeurs et comptages des résultats affichés</li>' +
      '</ul>' +
      'Vous pouvez alors les utiliser pour compléter votre recherche',
    position: 'bottom',
  },
  {
    element: '#step4',
    intro:
      "<h2>Modes d'affichage des résultats d'une recherche</h2>" +
      "Les deux premiers pictogrammes à droite vous permettent de choisir votre mode d'affichage :" +
      '<ul>' +
      '<li>vue mosaique de petites images</li>' +
      "<li>vue d'une grille complète</li>" +
      '</ul>' +
      "Ordre d'affichage des résultats :" +
      '<ul>' +
      "<li>lorsque vous arrivez dans Navigart3, l'ordre d'affichage de toute la base est tiré au hasard. Cet affichage sera celui que vous aurez à chaque étape de nouvelle recherche. Si vous voulez faire un nouveau tirage au hasard, faites F5.</li>" +
      "<li>lorsque vous faites une recherche, l'ordre d'affichage des résultats est l'ordre alphabétique des artistes puis des dates de création des œuvres.</li>" +
      '</ul>',
    position: 'left',
  },
  {
    element: '.step7',
    intro:
      '<h2>Affichage plein écran</h2>' +
      "En cliquant sur une fiche, vous ouvrez l'affichage plein écran, avec les informations complètes sur l'œuvre",
    position: 'right',
  },
  /*{
      element: '#step6',
      intro: '<h2>Sauvegarde</h2>' +
              '<ul>' +
                '<li>en cliquant sur ce pictogramme, vous sauvegardez la liste des œuvres affichées à ce moment (soit un résultat de recherche, soit le panier)</li>' +
                '<li>cette sauvegarde se fait dans un fichier "technique" sur votre ordinateur</li>' +
                '<li>ne pas le modifier</li>' +
                '<li>pour afficher son contenu ultérieurement, glisser/déposer le fichier dans la barre de recherche générale</li>' +
              '</ul>',
      position: 'left'
    },*/
  {
    element: '.art-navigation',
    intro:
      '<h2>Ascenseur et tourne page</h2>' +
      "N'oubliez pas que vous pouvez avoir plus d’œuvres en dessous et sur les pages suivantes ! <br/>" +
      "Pour cela, utilisez l'ascenseur vertical et le tourne pages en bas à droite <br/>" +
      'Les flèches clavier fonctionnent aussi ...',
    position: 'auto',
    tooltipClass: 'introjs-tooltip-right',
  },
];

const favoriteStep = {
  element: '.add-favourite',
  intro:
    '<h2>Panier</h2>' +
    '<ul>' +
    '<li>vous pouvez sélectionner chaque œuvre en cliquant sur le pictogramme "étoile" de chaque fiche</li>' +
    '<li>quand la fiche est sélectionnée, le pictogramme est plus foncé</li>' +
    '<li>pour désélectionner une fiche, cliquer à nouveau sur le pictogramme</li>' +
    '<li> pour voir les œuvres sélectionnées dans le panier, cliquer sur le pictogramme "étoile" du menu en haut à droite</li>' +
    '</ul>',
  position: 'right',
};
</script>
