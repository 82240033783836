export const AGGREGATION_COLLECTION = 'AGGREGATION_COLLECTION';
export const AGGREGATION_TERMS = 'AGGREGATION_TERMS';
export const AGGREGATION_OBJECT = 'AGGREGATION_OBJECT';
export const UPDATE_TOTAL = 'UPDATE_TOTAL';
export const UPDATE_RANGE = 'UPDATE_RANGE';
export const START_FETCH_TERMS = 'START_FETCH_TERMS';
export const END_FETCH_TERMS = 'END_FETCH_TERMS';
export const RESET_PAGE = 'RESET_PAGE';
export const SET_TERM_ORDER = 'SET_TERM_ORDER';
export const RESET_TERM_ORDER = 'RESET_TERM_ORDER';
export const SET_TERM_PAGE = 'SET_TERM_PAGE';
export const RESET_TERM_PAGE = 'RESET_TERM_PAGE';
export const AUTOCOMPLETE_SEARCH = 'AUTOCOMPLETE_SEARCH';
export const RESET_AUTOCOMPLETE = 'RESET_AUTOCOMPLETE';
export const APPEND_AUTOCOMPLETE_GROUPS = 'APPEND_AUTOCOMPLETE_GROUPS';
export const APPEND_AUTOCOMPLETE_AGGREGATIONS = 'APPEND_AUTOCOMPLETE_AGGREGATIONS';
export const SET_FULLTEXT_CHECK = 'SET_FULLTEXT_CHECK';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_CLEAR_ERRORS = 'AUTH_CLEAR_ERRORS';
export const UPDATE_QUERY = 'UPDATE_QUERY';
export const INIT = 'INIT';
export const UPDATE_SUMMARY = 'UPDATE_SUMMARY';
export const UPDATE_TITLE = 'UPDATE_TITLE';
export const UPDATE_NAMES = 'UPDATE_NAMES';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const INCREMENT_ACTIVE_REQUESTS = 'INCREMENT_ACTIVE_REQUESTS';
export const DECREMENT_ACTIVE_REQUESTS = 'DECREMENT_ACTIVE_REQUESTS';
export const UPDATE_NOTIFY_GRANTED = 'UPDATE_NOTIFY_GRANTED';
export const UPDATE_COLUMN_WIDTH = 'UPDATE_COLUMN_WIDTH';
export const UPDATE_PREFERENCES = 'UPDATE_PREFERENCES';
export const CARTS_SET_ALL = 'CARTS_SET_ALL';
export const CARTS_SET_UNSELECT_ALL_STATUS = 'CARTS_SET_UNSELECT_ALL_STATUS';
export const CARTS_SET_ACTIVE = 'CARTS_SET_ACTIVE';
export const CARTS_ADD_CART = 'CARTS_ADD_CART';
export const CARTS_UPDATE_CART = 'CARTS_UPDATE_CART';
export const CARTS_UPDATE_TEXT_NOTE = 'CARTS_UPDATE_TEXT_NOTE';
export const CARTS_REMOVE_CART = 'CARTS_REMOVE_CART';
export const CARTS_RESET = 'CARTS_RESET';
export const TEXT_NOTES_SET_LIST = 'TEXT_NOTES_SET_LIST';
export const TEXT_NOTES_UPDATE_ELEMENT = 'TEXT_NOTES_UPDATE_ELEMENT';
export const TEXT_NOTES_UPDATE_STATUS = 'TEXT_NOTES_UPDATE_STATUS';
export const TEXT_NOTES_SET_NEW = 'TEXT_NOTES_SET_NEW';
export const TEXT_NOTES_SET_EDIT = 'TEXT_NOTES_SET_EDIT';
export const TEXT_NOTES_SET_PROPERTIES = 'TEXT_NOTES_SET_PROPERTIES';
export const TEXT_NOTES_SET_CONTENT = 'TEXT_NOTES_SET_CONTENT';
export const ADD_PREVIEW = 'ADD_PREVIEW';
export const LOAD = 'LOAD';
export const RESET_DIRECTION = 'RESET_DIRECTION';
