import * as mutations from '../mutation-types';

import Artwork from 'api/artwork';

export default {
  async fetchTerms({ commit, state, rootState, rootGetters }, aggregation = null) {
    try {
      commit(mutations.START_FETCH_TERMS);

      let params = rootGetters['params/aggregationsApi']([], aggregation);

      const { endpoint = 'artworks' } = rootState.route.meta;
      const data = await Artwork.fetch(params, endpoint);

      const AGGREGATION_KEY = rootState.aggregations.aggregation || aggregation;
      commit(mutations.AGGREGATION_TERMS, data.aggregations[AGGREGATION_KEY].terms);

      commit(mutations.UPDATE_RANGE, { aggregations: data.aggregations });
      commit(mutations.UPDATE_TOTAL, { aggregations: data.aggregations });
      commit(mutations.END_FETCH_TERMS);
    } catch (e) {
      console.log(e);
      //console.error(e);
    }
  },
  toggleAggregation({ commit, state, rootState }, aggregation) {
    let sortDir, sortBy;

    if (aggregation) {
      sortDir = state.aggregations[aggregation].sort_dir;
      sortBy = state.aggregations[aggregation].sort_by;
    }

    commit(`params/${mutations.RESET_TERM_PAGE}`, null, { root: true });
    commit(mutations.AGGREGATION_TERMS, []);
    commit(`params/${mutations.RESET_TERM_ORDER}`, { sortDir, sortBy }, { root: true });
    commit(mutations.AGGREGATION_OBJECT, aggregation);
  },
};
