<template>
  <div class="col-sm-12">
    <div class="row flex-column">
      <p>{{ header }}</p>
      <div v-for="(link, index) in links" :key="index">
        <div class="form-group row">
          <label :for="'link_' + type + '_' + index + '_title'" class="col-sm-2 col-form-label" v-translate
            >Libellé</label
          >
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              v-model="links[index].title"
              :id="'link_' + type + '_' + index + '_title'"
            />
          </div>
        </div>
        <div class="form-group row">
          <label :for="'link_' + type + '_' + index + '_href'" class="col-sm-2 col-form-label">{{ idsLabel }}</label>
          <div class="col-sm-10 input-group">
            <input
              type="text"
              class="form-control"
              format-href
              v-model="links[index].ids"
              :id="'link_' + type + '_' + index + '_href'"
            />
            <span class="input-group-append">
              <a class="icon-trash" @click.stop.prevent="removeLink(index)"></a>
            </span>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label :for="'link_' + type + '_title'" class="col-sm-2 col-form-label" v-translate>Libellé</label>
        <div class="col-sm-10">
          <input type="text" class="form-control" v-model="item.title" :id="'link_' + type + '_title'" />
        </div>
      </div>
      <div class="form-group row">
        <label :for="'link_' + type + '_href'" class="col-sm-2 col-form-label">{{ idsLabel }}</label>
        <div class="col-sm-10">
          <input type="text" class="form-control" format-href v-model="item.ids" :id="'link_' + type + '_href'" />
        </div>
      </div>
      <a class="btn btn-round btn-round-white align-self-end" @click.stop.prevent="addLink()">{{ addButtonLabel }}</a>
    </div>
  </div>
</template>
<script>
import appUrl from '../lib/appUrl';
import { shareSingleWorkPrefix, shareMultipleWorksPrefix } from 'config';

export default {
  props: {
    type: String,
    links: Array,
  },
  computed: {
    idsLabel() {
      const label = this.type === 'internal' ? this.$gettext('Id') : this.$gettext('URL');
      return label;
    },
    addButtonLabel() {
      return this.type === 'internal' ? this.$gettext('Valider lien oeuvre') : this.$gettext('Valider lien externe');
    },
    header() {
      return this.type === 'internal'
        ? this.$gettext('Liens oeuvre complémentaires')
        : this.$gettext('Liens externes complémentaires');
    },
  },
  methods: {
    addLink() {
      if (this.item.title === '' || this.item.ids === '') {
        return;
      }

      this.item.href = this.type == 'internal' ? this.parseContentInternalLinks(this.item.ids) : this.item.ids;
      this.$emit('addLink', this.item, this.type);
      this.item = {
        href: '',
        title: '',
        ids: '',
      };
    },
    removeLink(index) {
      this.$emit('removeLink', index, this.type);
    },
    parseContentInternalLinks(content) {
      const re = new RegExp('(' + appUrl + ')#/(.*[/|?ids=])([0-9,]+)', 'g');
      if (re.test(content)) {
        return content.replace(re, (whole, apphref, prefix, ids) => {
          if (prefix === shareSingleWorkPrefix) {
            return '{shareBaseUrl}{shareSingleWorkPrefix}' + ids;
          } else {
            return '{shareBaseUrl}{shareMultipleWorksPrefix}' + ids;
          }
        });
      }

      const prefix = /,/.test(content) ? 'shareMultipleWorksPrefix' : 'shareSingleWorkPrefix';
      return `{shareBaseUrl}{${prefix}}` + content;
    },
    getIds(content) {
      const re = new RegExp(
        '({shareBaseUrl}{shareMultipleWorksPrefix}|{shareBaseUrl}{shareSingleWorkPrefix})([0-9,]+)',
        'ig'
      );
      if (re.test(content)) {
        return content.replace(re, (whole, urlpattern, ids) => {
          return ids;
        });
      }
      return content;
    },
  },
  data() {
    return {
      item: { title: '', href: '', ids: '' },
    };
  },
  created() {
    this.links.map((l) => (l.ids = this.getIds(l.href)));
  },
};
</script>
