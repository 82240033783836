import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations('artworks', { assignArtworks: 'assign' }),
    share(artwork) {
      this.assignArtworks({ name: 'shareArtwork', data: artwork });
      this.$root.$emit('bv::show::modal', 'shareModal');
    },
  },
};
