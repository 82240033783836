import { sort } from 'config';

const endpointSort = {
  artworks: sort['artworks'],
  movements: sort['movements'],
};

export default function (endopint = 'artworks', search = false) {
  const sorts = Object.keys(endpointSort[endopint]).reduce(
    (prev, key) => [...prev, { key, ...endpointSort[endopint][key] }],
    []
  );

  const sort = search ? sorts.find((s) => s.search_default) : sorts.find((s) => s.home_default);

  return sort.key;
}
