<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import ArtworkImage from './ArtworkImage.vue';
import FavoriteLink from './FavoriteLink.vue';
import TooltipEllipses from './TooltipEllipses.vue';
import closestValue from 'lib/closestValue';
import { HideIfEmpty } from 'directives/HideIfEmpty';
import FilterBuilder from 'lib/filterBuilder';
import template from '../../views/partials/artworks/box.html';

export default {
  name: 'artwork-box',
  props: {
    artwork: {
      type: Object,
      required: true,
    },
  },
  template,
  directives: {
    HideIfEmpty,
  },
  components: {
    ArtworkImage,
    FavoriteLink,
    TooltipEllipses,
  },
  computed: {
    ...mapGetters('params', ['filters']),
    ...mapGetters('carts', ['activeCart']),
    ...mapState('auth', ['currentUser']),
    ...mapGetters('artworks', ['copyright']),
    slug() {
      return this.artwork.slug;
    },
    filtersQuery() {
      return FilterBuilder.toQueryParam(this.filters);
    },
    isStarred() {
      if (!this.activeCart) return false;

      return this.activeCart.artwork_ids.includes(this.artwork.id);
    },
    mediaImage() {
      if (!this.artwork.medias) {
        return [];
      }

      return this.artwork.medias.filter((i) => i.type === 'image');
    },
    mediaVideoAudio() {
      if (!this.artwork.medias) {
        return [];
      }

      return this.artwork.medias.filter((i) => i.type !== 'image');
    },
    details() {
      return `${this.artwork.title_list}, ${this.artwork.date_creation}`;
    },
    fullCopyright() {
      return this.copyright(this.artwork.id);
    },
    mediaVersion() {
      return closestValue(this.mediaImage[0])[1];
    },
    thumbIconClass() {
      return {
        'fa-music': this.artwork.medias.filter((m) => m.type === 'audio' && m.header_aggregate === false).length > 0,
        'fa-video-camera':
          this.artwork.medias.filter((m) => m.type === 'video' && m.header_aggregate === false).length > 0,
      };
    },
  },
  methods: {
    ...mapMutations('artworks', ['assign']),
  },
};
</script>

<style lang="less">
.box-item {
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0px 15px 10px 15px;
  color: @default-link-hover-color;

  .copyright {
    width: 220px;
    font-size: 10px;
    line-height: 10px;
    color: @grid-item-copyright-color;
    padding: 5px 10px;
  }

  .box-image-content {
    border: 3px solid @mercury;
    width: 220px;
    background-color: @grid-item-left-background;
    position: relative;
    display: flex;
    flex-direction: column;

    .work-type {
      color: @grid-item-work-type-color;
      font-size: 12px;
      line-height: 12px;
      min-height: 20px;
      padding: 10px 10px 8px 10px;
      max-width: 95%;
    }

    .box-item-image {
      height: 200px;
      padding: 0 10px;
      margin: 0;
      display: flex;

      img {
        vertical-align: middle;
        width: 100%;
        height: 100%;
        object-fit: contain;
        -moz-appearance: checkbox-container;
      }

      .media-icon {
        height: 100px;
      }
    }

    .add-favourite {
      position: absolute;
      top: -1px;
      right: 5px;
    }

    .no-image-content {
      background-color: #000;
      min-width: 100px;
      position: relative;
      flex-grow: 1;

      .not-available {
        background-image: url(../../img/not-available-bg.png);
        background-size: auto;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
          font-size: 3rem;
        }
      }

      .media-icon {
        height: 200px;
        position: relative;
        top: 75px;
      }
    }
  }

  .author {
    .BoldFont(14px);
    padding: 20px 10px 10px 10px;
    border-bottom: solid 1px #e3e3e3;
    line-height: 14px;
    text-align: center;
  }

  .title {
    .RegularFont(14px);
    padding: 10px;
    line-height: 1;
    text-align: center;
  }
}

.box-starred .box-image-content {
  border: 3px solid @chalky;
}
</style>
