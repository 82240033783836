<template>
  <div class="autocomplete" v-show="!aggregation && isOpen">
    <div v-if="aggregationsSet.length">
      <div class="section-header" v-translate>Réponses dans les filtres</div>
      <AutocompleteAggregationItem
        v-on:loadMore="loadMore"
        :index="index"
        :isLast="aggregationsSet.length === index + 1"
        :agg="agg"
        v-for="(agg, index) in aggregationsSet"
        :key="index"
      />
    </div>

    <div v-if="groupsSet.length">
      <div class="section-header" v-translate>
        Réponses complémentaires par le texte intégral dans les rubriques non filtres
      </div>
      <div class="autocomplete-group" v-for="(group, index) in orderedGroups" :key="group.category">
        <div class="list-header" @click="applyCategoryQuery(group.category, `${query}*`)">
          <div v-b-tooltip.top :title="categoryTooltip" class="w-100">
            <translate>Mots dans la rubrique</translate>
            <strong>"{{ group.category_label.fr }}"</strong>
          </div>
        </div>
        <ul>
          <li
            v-html="item.highlight"
            @click.stop.prevent="applyCategoryQuery(group.category, item.suggestion)"
            :class="{ odd: !(index % 2) }"
            v-for="(item, index) in group.items"
            :key="index"
          ></li>
        </ul>
        <AutocompletePagination
          v-if="group.items.length < group.total_count"
          :total="group.total_count"
          :clickHandler="loadMore"
          :perPage="grupsSize"
          :term="group.category"
          type="groups"
        />
        <div class="empty-line" v-else-if="orderedGroups.length === index + 1"></div>
      </div>
    </div>

    <div class="bottom-section" v-if="totalCount >= 2 && !fullTextCheck">
      <div class="search-bar-fulltext" @click="applyQuery()">
        <translate
          >Cliquez ici pour avoir la recherche en texte intégral de tous les mots commençant par les lettres tapées
          (troncature avec *)</translate
        >
        <translate
          >(Vous pouvez aussi faire cette recherche en filtrant par rubrique; pour cela, cliquez sur la ligne
          correspondante ci-dessus)</translate
        >
      </div>
    </div>

    <div class="bottom-section" v-if="fullTextCheck && aggregationsItems.length === 0">
      <div class="search-bar-fulltext" v-if="fullTextCheck === 1" @click="applyQuery()">
        <translate>Ah! il y a des réponses avec des mots commençant par les lettres tapées.</translate>
        <translate>Cliquez ici pour lancer la recherche "mot=lettres-tapées*" (troncature avec *)</translate>
      </div>

      <div class="search-bar-fulltext" v-if="fullTextCheck === 2">
        <translate>Hum! il n'y a pas de réponse avec des mots commençant par les lettres tapées.</translate>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { autocompletion } from 'config';

import FilterBuilder from 'lib/filterBuilder';

import AutocompletePagination from './AutocompletePagination.vue';
import AutocompleteAggregationItem from './AutocompleteAggregationItem.vue';

const grupsSize = autocompletion.popup.identified_fields.number_of_values;

export default {
  name: 'autocomplete',
  components: {
    AutocompleteAggregationItem,
    AutocompletePagination,
  },
  computed: {
    ...mapGetters('params', ['locationFilters']),
    ...mapGetters('aggregations', ['aggregation']),
    ...mapGetters('autocomplete', [
      'groupsSet',
      'groupsItems',
      'aggregationsSet',
      'aggregationsItems',
      'fullTextCheck',
    ]),
    orderedGroups() {
      return this.groupsSet.sort((a, b) => {
        return a.pos > b.pos ? 1 : a.pos < b.pos ? -1 : 0;
      });
    },
    totalCount() {
      return this.groupsItems.length + this.aggregationsItems.length;
    },
    isOpen() {
      return this.query && this.query.length > 2;
    },
    openFilterTooltip() {
      return this.$gettext(
        'Accès au filtre complet <br> avec des fonctionnalités supplémentaires <br> (tri, choix de plusieurs valeurs, etc.)'
      );
    },
    categoryTooltip() {
      return this.$gettext(
        'Cliquez ici pour rechercher, dans cette rubrique, tous les mots "commençant par" les lettres tapées en barre de recherche.'
      );
    },
  },
  props: {
    query: String,
  },
  data() {
    return {
      grupsSize: grupsSize,
    };
  },
  methods: {
    ...mapActions('aggregations', ['toggleAggregation']),
    ...mapActions('autocomplete', ['search']),
    highlight(term) {
      return term.label;
    },
    openFilter(key) {
      this.toggleAggregation(key);
    },
    applyCategoryQuery(aggregation, term) {
      const filters = [{ term: [term], aggregation, type: 'category_query', operator: 'and' }];

      const query = this.$route.query;

      this.$router.push({
        query: { ...query, page: 1 },
        params: { filters: FilterBuilder.toURLParam(this.locationFilters.concat(filters)) },
      });
    },
    applyQuery() {
      const filters = [{ term: [`${this.query}*`], aggregation: 'query', type: 'query' }];

      const query = this.$route.query;

      this.$router.push({
        query: { ...query, page: 1 },
        params: { filters: FilterBuilder.toURLParam(this.locationFilters.concat(filters)) },
      });
    },
    loadMore(options) {
      this.search(Object.assign({}, options, { search: this.query }));
    },
  },
};
</script>

<style lang="less">
.section-header {
  font-size: 18px;
  color: white;
  background-color: #4d4d4d;
  padding: 16px 20px;
  display: flex;
  align-items: center;
}

.autocomplete {
  display: block;
  position: absolute;
  width: 100%;
  z-index: 998;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 85vh;
  box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.5);
  background-color: @white;

  .autocomplete-group {
    padding: 0 20px;
  }

  .list-header {
    border-bottom: 2px solid #757575;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    padding: 0 0 0 5px;
    cursor: pointer;

    .icons {
      display: flex;
    }
  }

  .filter-name {
    text-transform: uppercase;
    color: black;
    font-weight: 600;
    font-size: 16px;
  }

  .filter-count {
    color: #ebcc89;
    font-weight: 900;
    font-size: 14px;
    margin-left: 15px;
  }

  .filter-name-count {
    display: flex;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      cursor: pointer;
      margin: 0;
      border-bottom: 1px solid #d6d6d6;
      height: 30px;
      line-height: 30px;
      padding-left: 10px;
      padding-right: 10px;

      .term-name {
        max-width: 90%;
      }

      &.odd {
        background-color: @filter-item-odd-background;
      }
      b {
        .BlackFont(13px);
      }
    }
  }
}

.empty-line {
  height: 30px;
}

.search-bar-fulltext {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  color: white;
  font-weight: 300;
  border: 1px solid #ebcc89;
  padding: 1rem;
  min-height: 110px;
  text-align: center;
}

.bottom-section {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #4d4d4d;
  padding: 2rem;
  min-height: 145px;
}
</style>
