<template>
  <div class="md-editor">
    <div class="md-header btn-toolbar d-flex justify-content-between">
      <button
        type="button"
        class="btn btn-default btn-sm"
        title="Bold"
        tabindex="-1"
        data-provider="bootstrap-markdown"
        data-handler="bootstrap-markdown-cmdBold"
        data-hotkey="Ctrl+B"
        @click.prevent="markdownBtnAction('toggleBold')"
      >
        <span class="fa fa-bold"></span>
      </button>
      <button
        type="button"
        class="btn btn-default btn-sm"
        title="Italic"
        tabindex="-1"
        data-provider="bootstrap-markdown"
        data-handler="bootstrap-markdown-cmdItalic"
        data-hotkey="Ctrl+I"
        @click.prevent="markdownBtnAction('toggleItalic')"
      >
        <span class="fa fa-italic"></span>
      </button>
      <button
        type="button"
        class="btn btn-default btn-sm"
        title="Heading"
        tabindex="-1"
        data-provider="bootstrap-markdown"
        data-handler="bootstrap-markdown-cmdHeading"
        data-hotkey="Ctrl+H"
        @click.prevent="markdownBtnAction('toggleHeadingSmaller')"
      >
        <span class="fa fa-font"></span>
      </button>
      <button
        type="button"
        class="btn btn-default btn-sm"
        title="URL/Link"
        tabindex="-1"
        data-provider="bootstrap-markdown"
        data-handler="bootstrap-markdown-cmdUrl"
        data-hotkey="Ctrl+L"
        @click.prevent="markdownBtnAction('drawLink')"
      >
        <span class="fa fa-link"></span>
      </button>
      <button
        type="button"
        class="btn btn-default btn-sm"
        title="Image"
        tabindex="-1"
        data-provider="bootstrap-markdown"
        data-handler="bootstrap-markdown-cmdImage"
        data-hotkey="Ctrl+G"
        @click.prevent="markdownBtnAction('drawImage')"
      >
        <span class="fa fa-picture-o"></span>
      </button>
      <button
        type="button"
        class="btn btn-default btn-sm"
        title="Unordered List"
        tabindex="-1"
        data-provider="bootstrap-markdown"
        data-handler="bootstrap-markdown-cmdList"
        data-hotkey="Ctrl+U"
        @click.prevent="markdownBtnAction('toggleUnorderedList')"
      >
        <span class="fa fa-list"></span>
      </button>
      <button
        type="button"
        class="btn btn-default btn-sm"
        title="Ordered List"
        tabindex="-1"
        data-provider="bootstrap-markdown"
        data-handler="bootstrap-markdown-cmdListO"
        data-hotkey="Ctrl+O"
        @click.prevent="markdownBtnAction('toggleOrderedList')"
      >
        <span class="fa fa-list-ol"></span>
      </button>
      <button
        type="button"
        class="btn btn-sm show-artworks-btn btn-default btn-sm"
        title="Show artworks"
        tabindex="-1"
        data-provider="bootstrap-markdown"
        data-handler="bootstrap-markdown-cmdShowArtworks"
        data-hotkey
        @click.stop="openLinksModal"
      >
        Lien œuvre(s)
      </button>
      <button type="button" class="btn btn-sm help-btn btn-default btn-sm" title="Help" @click.prevent="openHelp">
        Aide
      </button>
    </div>
  </div>
</template>

<script>
import appUrl from 'lib/appUrl.js';
import { mapMutations } from 'vuex';

export default {
  name: 'TextNoteContentFormMarkdownButtons',
  props: {
    simplemde: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapMutations('artworks', ['assign']),
    markdownBtnAction(action) {
      this.simplemde[action]();
    },
    openHelp() {
      return window.open(`${appUrl}help`, '_blank');
    },
    openLinksModal() {
      let smde = {
        content: this.simplemde.value(),
        cursorStart: this.simplemde.codemirror.getCursor('start'),
        cursorEnd: this.simplemde.codemirror.getCursor('end'),
        link: null,
        selectedText: this.simplemde.codemirror.getSelection(),
      };

      this.assign({ name: 'smde', data: smde });
      return this.$root.$emit('bv::show::modal', 'linksModal');
    },
  },
};
</script>

<style lang="less" scoped>
.md-header {
  display: block;
  padding: 6px 4px;
  background-color: @black;
}
.md-header {
  width: 407px;
  margin: 0 26px 0 10px;
  position: fixed;
  z-index: 2;
  .btn {
    background-color: @black;
    font-weight: bold;
    color: @white;
    border: 0;
  }
}
</style>
