import {
  AUTH_SUCCESS,
  AUTH_FAILURE,
  AUTH_USER,
  AUTH_LOGOUT,
  AUTH_CLEAR_ERRORS,
  UPDATE_NAMES,
  UPDATE_PROFILE,
} from '../mutation-types';

export default {
  [AUTH_SUCCESS](state, data) {
    state.isAuthenticated = true;
    state.token = data.token;
    state.errors = [];
  },
  [AUTH_FAILURE](state, errors) {
    state.isAuthenticated = false;
    state.token = '';
    state.currentUser = {};
    state.errors = errors;
  },
  [AUTH_USER](state, currentUser) {
    state.isAuthenticated = true;
    state.currentUser = currentUser;
  },
  [UPDATE_NAMES](state, user) {
    state.currentUser.name = user.name;
    state.currentUser.alias_names = user.alias_names;
  },
  [UPDATE_PROFILE](state, user) {
    state.currentUser.profile = user.profile;
  },
  [AUTH_LOGOUT](state) {
    state.isAuthenticated = false;
    state.currentUser = {};
    state.token = '';
  },
  [AUTH_CLEAR_ERRORS](state) {
    state.errors = [];
  },
};
