<template>
  <div
    :disabled.sync="disabled"
    class="ellipsis"
    v-b-tooltip.html.viewport
    :title="titleValue"
    v-html="value"
    ref="ellipsis"
  ></div>
</template>
<script>
export default {
  name: 'TooltipEllipses',
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    titleValue() {
      if (this.disabled) {
        return null;
      } else {
        return this.value;
      }
    },
  },
  methods: {
    textWidth(text) {
      var tag = document.createElement('div');
      tag.style.position = 'absolute';
      tag.style.left = '-99vw';
      tag.style.whiteSpace = 'nowrap';
      tag.innerHTML = text;

      document.body.appendChild(tag);

      var result = tag.clientWidth;

      document.body.removeChild(tag);

      return result;
    },
  },
  data() {
    return {
      disabled: true,
    };
  },
  mounted() {
    if (
      /*this.textWidth(this.value) > this.$el.offsetWidth || */ this.$refs.ellipsis.offsetWidth <
      this.$refs.ellipsis.scrollWidth
    ) {
      this.disabled = false;
    }
  },
  updated() {
    if (
      /*this.textWidth(this.value) > this.$el.offsetWidth || */ this.$refs.ellipsis.offsetWidth <
      this.$refs.ellipsis.scrollWidth
    ) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  },
};
</script>
