<template>
  <div class="header" v-if="externalMenu && externalMenu.url && externalMenu.position === 'top'">
    <ExternalHeader />
  </div>
</template>
<script>
import { externalMenu } from 'config';
import ExternalHeader from './ExternalHeader.vue';

export default {
  name: 'TopHeader',
  components: {
    ExternalHeader,
  },
  data() {
    return {
      externalMenu: externalMenu || {},
    };
  },
};
</script>
