/*jshint scripturl:true*/
'use strict';

import marked from 'markedjs-extra/lib/marked_extra.js';

import appUrl from './appUrl';
import supplantLnik from './supplantLink';

const link = function (href, title, text) {
  href = supplantLnik(href);

  if (this.options.sanitize) {
    var prot;
    try {
      prot = decodeURIComponent(unescape(href))
        .replace(/[^\w:]/g, '')
        .toLowerCase();
    } catch (e) {
      return '';
    }
    if (prot.indexOf('javascript:') === 0) {
      return '';
    }
  }
  var out = '<a href="' + href + '"';
  if (title) {
    out += ' title="' + title + '"';
  }

  if (href.indexOf(appUrl) === -1) {
    out += ' class="underline" target="_blank"';
  } else {
    if (this.options.targetBlank) {
      out += 'class="more preview-link" target="_blank"';
    } else {
      out += ' v-preview class="more preview-link"';
    }
  }
  out += '>' + text + '</a>';
  return out;
};

const renderer = new marked.Renderer();
renderer.link = link;

marked.setOptions({ renderer, breaks: false });

export default marked;
